import { Checkbox, Col, Form, Row, Space } from "antd"
import CheckboxCustom from "src/components/CheckBoxCustom"
import Instruction from "./Instruction"
import { requireOutOfFormItem } from "src/lib/requireOutOfFormItem"
import FlInput from "src/components/FloatingLabel/Input"
import { FormItemErrorStyled } from "../../1.PatentRegistration/style"

const FieldPractice = ({
  form,
  checkbox,
  setCheckbox,
  dataSource,
  setDataSource,
}) => {
  return (
    <div className="declaration-shadow-box p-24">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="d-flex-start mb-8">
            <div style={{ width: "100%" }}>
              <div className="text-center fw-600 title-box title-header-color">
                LĨNH VỰC HÀNH NGHỀ ĐĂNG KÝ DỰ KIỂM TRA
              </div>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <CheckboxCustom
            style={{ width: "100%" }}
            defaultValue={checkbox?.SubjectCate}
            checkSingleValue
            onChangeCustom={e => {
              setCheckbox({ ...checkbox, SubjectCate: e })
              form.setFieldValue("GroupNumber", null)
              requireOutOfFormItem(form, [
                {
                  condition: !!e === false,
                  name: "SubjectCate",
                },
              ])
            }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Checkbox value={1}>
                Sáng chế, kiểu dáng công nghiệp, thiết kế bố trí
              </Checkbox>
              <Checkbox value={2}>
                Nhãn hiệu, chỉ dẫn địa lý, tên thương mại, chống cạnh tranh
                không lành mạnh, bí mật kinh doanh
              </Checkbox>
            </Space>
          </CheckboxCustom>

          {checkbox?.SubjectCate === undefined && (
            <Form.Item
              name="GroupNumber"
              className="input-bottom pt-0"
              style={{ marginBottom: "-32px" }}
              rules={[
                { required: true, message: "Thông tin không được để trống" },
              ]}
            >
              {/* <FlInput
                    borderBottomOnly
                    className="input-border-bottom-only "
                    isRequired
                  /> */}
            </Form.Item>
          )}
          <FormItemErrorStyled name="SubjectCate" />
        </Col>
        <Col span={22}>
          {!!checkbox?.SubjectCate && (
            <Instruction
              dataSource={dataSource}
              setDataSource={setDataSource}
              checkbox={checkbox}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default FieldPractice
