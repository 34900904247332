import {
  Checkbox,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tooltip,
  Upload,
  message,
} from "antd"
import { Document, Page, pdfjs } from "react-pdf"
import _ from "lodash"
import { useEffect, useState } from "react"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import { useSelector } from "react-redux"
import CheckboxCustom from "src/components/CheckBoxCustom"
import { RedStar } from "src/components/FloatingLabel/styled"
import CB1 from "src/components/Modal/CB1"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import SpinCustom from "src/components/Spin"
import SvgIcon from "src/components/SvgIcon"
import TableCustom from "src/components/Table/CustomTable"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey, normFile } from "src/lib/utils"
import {
  checkFilebeforeUpload,
  checkIsAddChildDocument,
  checkIsRequiredDocument,
  checkIsRequiredUpfileDocument,
  defaultValueOfCheckboxOriginal,
  nestDocumentForm,
  onDocumentLoadSuccess,
  removeUploadFileDoc,
  typeFileAccept,
} from "../functions/utils"
import ModalDocType from "../../1.PatentRegistration/components/modal/ModalDocType"
import ModalUpdateDocType from "../../1.PatentRegistration/components/modal/ModalUpdateDocType"
import { LoadingOutlined } from "@ant-design/icons"
import styled from "styled-components"

export const sort = items => {
  return _.orderBy(
    items,
    ["Level", "SortOrder", "NumericalOrder"],
    ["asc", "asc", "asc"],
  )
}

// Đặt workerSrc để sử dụng pdfjs trong dự án của bạn
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
const DocumentsStyle = styled.div`
  .load-file-spin {
  }
  .box-spin {
  }
`
const Documents = ({
  form,
  dataDefalt,
  setDataDefalt,
  setDeleteFileID,
  infoFile,
  setInfoFile,
  checkbox,
  setCheckbox,
  DocumentMultiLevel = {},
}) => {
  const [numPagesLoading, setNumPagesLoading] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const [file, setFile] = useState(null)
  const { listSystemCate } = useSelector(state => state.appGlobal)
  const [openModalDocType, setOpenModalDocType] = useState(false)
  const [openModalUpdateDocType, setOpenModalUpdateDocType] = useState(false)
  const [listTitle, setListTitle] = useState()
  const [listTitleExpand, setListTitleExpand] = useState([])
  const [loading, setLoading] = useState(false)

  const sharedOnCell = (record, index) => {
    if (!!(!!record?.children?.length || !!record.IsAddChild)) {
      return {
        colSpan: 0,
      }
    }
    return {}
  }
  const columns = [
    {
      title: "Tên tài liệu",
      dataIndex: "DocName",
      key: "DocName",
      width: 400,
      onCell: (record, index) => ({
        colSpan: !!(!!record?.children?.length || !!record?.IsAddChild) ? 7 : 1,
      }),
      render: (value, record) => (
        <div>
          <Tooltip title={<div>{value}</div>}>
            <div
              className={`max-line1 d-flex-start ${
                !!(!!record?.children?.length || !!record?.IsAddChild)
                  ? "fw-600 title-header-color"
                  : ""
              }`}
            >
              {value}
              {checkIsRequiredDocument(record, checkbox) && (
                <RedStar> *</RedStar>
              )}
              {checkIsAddChildDocument(record) && (
                <div
                  className="ml-8"
                  onClick={() => {
                    setOpenModalDocType({
                      ...record,
                      canAddChildren:
                        DocumentMultiLevel.Code === record.Code &&
                        DocumentMultiLevel.LevelMax > record.Level,
                      Code: record.Code,
                    })
                  }}
                >
                  <SvgIcon name="add-file-blue" />
                </div>
              )}
            </div>
          </Tooltip>
          {!!record?.IsOriginal && (
            <>
              <CheckboxCustom
                style={{ width: "100%" }}
                checkSingleValue
                defaultValue={defaultValueOfCheckboxOriginal(record, checkbox)}
                onChangeCustom={value => {
                  setCheckbox(pre => ({
                    ...pre,
                    [`IsOriginalSubmitted${record?.DocID}`]: value,
                  }))
                }}
              >
                <Space direction="vertical">
                  {!!(record?.IsOriginal === 1 || record?.IsOriginal === 3) && (
                    <Checkbox key={0} value={0}>
                      Bản gốc sẽ nộp sau
                    </Checkbox>
                  )}
                  {!!(record?.IsOriginal === 2 || record?.IsOriginal === 3) && (
                    <div
                      className="d-flex justify-start"
                      style={{ alignItems: "start" }}
                    >
                      <Checkbox key={1} value={1}>
                        Bản gốc đã nộp theo đơn số{" "}
                      </Checkbox>
                    </div>
                  )}
                </Space>
              </CheckboxCustom>
              {!!checkbox[`IsOriginalSubmitted${record?.DocID}`] && (
                <Form.Item
                  name={`OriginalNumber${record?.DocID}`}
                  className=" ml-8"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống",
                    },
                  ]}
                >
                  <Input
                    className="input-border-bottom-only"
                    placeholder="Nhập số "
                  />
                </Form.Item>
              )}
            </>
          )}
          {/* Phần dưới sẽ comment và đổi thành cấu hình trong hệ thống thôi */}
          {/* Phần số bản sao đầu tiền nếu không nhập tay(Hỏi chị tiệp) thì không phải làm gì */}
          {/* Nếu phải nhập tay thì điền trường cũ */}
          {!record?.children?.length &&
            record?.Code === "CHUNG_MINH_QUYEN_UU_TIEN" && (
              <>
                <CheckboxCustom
                  style={{ width: "100%" }}
                  checkSingleValue
                  defaultValue={
                    checkbox[`IsFirstCopy${record?.DocID}`] === 0
                      ? 0
                      : !!checkbox[`IsFirstCopy${record?.DocID}`]
                      ? 1
                      : undefined
                  }
                  onChangeCustom={value => {
                    setCheckbox(pre => ({
                      ...pre,
                      [`IsFirstCopy${record?.DocID}`]: value,
                    }))
                  }}
                >
                  <Space direction="vertical">
                    <Checkbox value={0}>
                      Giấy chuyển nhượng quyền ưu tiên nếu thụ hưởng từ người
                      khác (Bản sao)
                    </Checkbox>
                    <div
                      className="d-flex justify-start"
                      style={{ alignItems: "start" }}
                    >
                      <Checkbox value={1}>
                        Bản sao đơn đầu tiên gồm...bản (Bản sao)
                      </Checkbox>
                    </div>
                  </Space>
                </CheckboxCustom>
                {!!checkbox[`IsFirstCopy${record?.DocID}`] && (
                  <Form.Item
                    name={`FirstCopyNumber${record?.DocID}`}
                    className=" ml-8"
                    rules={[
                      {
                        required: true,
                        message: "Thông tin không được để trống",
                      },
                    ]}
                  >
                    <Input
                      className="input-border-bottom-only"
                      placeholder="Nhập số bản"
                    />
                  </Form.Item>
                )}
              </>
            )}
        </div>
      ),
    },
    {
      title: "Số trang",
      dataIndex: "NumberPage",
      key: "NumberPage",
      width: 150,
      onCell: sharedOnCell,
      render: (value, record) => (
        <div className="text-center">
          <Form.Item shouldUpdate noStyle form={form}>
            {({ getFieldValue }) => {
              let dataDocPage = getFieldValue(`${record?.DocID}`)
              if (dataDocPage?.length) {
                const totalnumber =
                  dataDocPage?.reduce((total, currentValue) => {
                    if (!!currentValue?.ObjectFileID)
                      return total + currentValue?.NumberPageFile
                    else
                      return (
                        total +
                        (infoFile?.find(item => item?.uid === currentValue?.uid)
                          ?.NumberPage || 0)
                      )
                  }, 0) || 0
                return (
                  <div className="d-flex-center">
                    <div>
                      Tổng số trang: <b>{totalnumber}</b>
                    </div>
                    <Spin
                      className="ml-8"
                      wrapperClassName="load-file-spin"
                      fullscreen
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 18,
                          }}
                          spin
                        />
                      }
                      spinning={!!numPagesLoading?.[record?.DocID]}
                    />
                  </div>
                )
              }
            }}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Số bản",
      dataIndex: "NumberCopies",
      key: "NumberCopies",
      width: 70,
      onCell: sharedOnCell,
      render: (value, record) => (
        <div className="text-center">
          <Form.Item shouldUpdate noStyle form={form}>
            {({ getFieldValue }) => {
              let totalDocRecord = getFieldValue(`${record?.DocID}`)
              if (totalDocRecord?.length) {
                return totalDocRecord?.length
              }
            }}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Ngôn ngữ",
      dataIndex: "Language",
      key: "Language",
      onCell: sharedOnCell,
      width: 150,
      render: (value, record) => (
        <Select
          style={{ width: "100%" }}
          className="input-border-bottom-only "
          placeholder="Chọn"
          value={+value || undefined}
          onChange={value => {
            setDataDefalt(pre => ({
              ...pre,
              Data: pre?.Data?.map(item => {
                if (item?.DocID === record?.DocID) {
                  return {
                    ...item,
                    Language: value,
                  }
                } else {
                  return item
                }
              }),
            }))
          }}
        >
          {getListComboByKey(SYSTEM_KEY?.NGON_NGU, listSystemCate)?.map(
            item => (
              <Select.Option value={item?.ID} key={item?.ID}>
                {item?.Description}
              </Select.Option>
            ),
          )}
        </Select>
      ),
    },
    {
      title: "Đính kèm tệp",
      dataIndex: "file",
      key: "file",
      onCell: sharedOnCell,
      width: 200,
      render: (value, record, index) => (
        <Form.Item
          name={`${record?.DocID}`}
          getValueFromEvent={normFile}
          valuePropName="fileList"
          rules={[
            {
              required: checkIsRequiredUpfileDocument(record, checkbox),
              message: "Thông tin không được để trống",
            },
          ]}
        >
          <Upload.Dragger
            maxCount={record?.MaxFile ? +record?.MaxFile : 0}
            accept={typeFileAccept(record)}
            id={`upload_${record?.DocID}`}
            style={{ display: "none" }}
            multiple={!!((record?.MaxFile ? +record?.MaxFile : 2) > 1)}
            beforeUpload={async (file, fileList) =>
              await checkFilebeforeUpload(
                file,
                record,
                setLoading,
                setFile,
                form,
                setNumPagesLoading,
              )
            }
            onRemove={file =>
              removeUploadFileDoc(file, record, setDeleteFileID, setDataDefalt)
            }
            className="pointer"
          >
            <div
              className="d-flex-center"
              style={{ height: "8px", whiteSpace: "nowrap" }}
            >
              <div className="mr-8">
                <SvgIcon name="cloud" />
              </div>
              <div>
                <span>
                  <span style={{ color: "#154398" }}>Chọn File</span>
                </span>
              </div>
            </div>
          </Upload.Dragger>
        </Form.Item>
      ),
    },
    {
      title: "Ghi chú",
      dataIndex: "Description",
      key: "Description",
      align: "center",
      width: 120,
      onCell: sharedOnCell,
      render: (value, record) =>
        !!value && (
          <div
            className="text-center"
            style={{ color: "#0958d9" }}
            onClick={() => {
              CB1({
                title: `${value || ""}`,
                icon: "warning-usb",
                okText: "Đồng ý",
                disableCancel: "none",
                onOk: async close => {
                  close()
                },
              })
            }}
          >
            Xem
          </div>
        ),
    },
    {
      title: "Thao tác",
      dataIndex: "Describe",
      onCell: sharedOnCell,
      width: 100,
      render: (value, record) => (
        <div>
          <Space size={12} className="d-flex-center">
            {/* <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const listFile = getFieldValue(`${record?.DocID}`)
                console.log("listFile", listFile)
                return (
                  <ButtonCircle
                    title={
                      !!(
                        (record?.MaxFile ? +record?.MaxFile : 0) === 1 &&
                        listFile?.length &&
                        listFile[0]?.ObjectFileID
                      )
                        ? "Tệp đính kèm bị giới hạn là 1. Hãy xóa tệp đang đính kèm để tiếp tục tải lên tệp khác"
                        : "Tải file lên"
                    }
                    iconName="cloud-upload"
                    enable={
                      !(
                        (record?.MaxFile ? +record?.MaxFile : 0) === 1 &&
                        listFile?.length &&
                        listFile[0]?.ObjectFileID
                      )
                    }
                    onClick={() => handleUpload(`upload_${record?.DocID}`)}
                  />
                )
              }}
            </Form.Item> */}

            <ButtonCircle
              title={"Tải file lên"}
              iconName="cloud-upload"
              onClick={() => handleUpload(`upload_${record?.DocID}`)}
            />
            {record?.IsAdd && (
              <>
                <ButtonCircle
                  title="Sửa"
                  iconName="edit-green"
                  onClick={() => {
                    setOpenModalUpdateDocType(record)
                  }}
                />
                <ButtonCircle
                  title="Xóa"
                  iconName="bin"
                  onClick={() => {
                    CB1({
                      title: `Bạn có chắc chắn muốn xoá tài liệu này không?`,
                      icon: "warning-usb",
                      okText: "Đồng ý",
                      onOk: async close => {
                        deleteDoc(record)
                        close()
                      },
                    })
                  }}
                />
              </>
            )}
          </Space>
        </div>
      ),
    },
  ]
  const handleUpload = id => {
    const uploadElement = document.getElementById(id)
    if (uploadElement) {
      uploadElement.click()
    }
  }
  const deleteDoc = record => {
    setDataDefalt(pre => ({
      Data: pre?.Data?.filter(item => !(item?.DocID === record?.DocID)),
    }))
  }

  useEffect(() => {
    if (dataDefalt?.Data) {
      setListTitle({
        Data: nestDocumentForm(sort(dataDefalt?.Data), 0, "ParentID"),
      })
      setListTitleExpand(pre =>
        sort(dataDefalt?.Data)?.map(item => item?.DocID),
      )
    }
  }, [dataDefalt])

  return (
    <SpinCustom spinning={loading}>
      <DocumentsStyle className="doc-box p-16">
        <Form form={form}>
          <TableCustom
            columns={columns}
            isPrimary
            dataSource={listTitle?.Data}
            scroll={{ x: "1000px" }}
            expandable={{
              indentSize: 20,
            }}
            defaultExpandAllRows={true}
            expandedRowKeys={listTitleExpand}
            onExpand={(value, record) => {
              if (!!value && !listTitleExpand?.includes(record?.DocID))
                setListTitleExpand(pre => [...pre, record?.DocID])
              else if (!value && listTitleExpand?.includes(record?.DocID))
                setListTitleExpand(pre =>
                  pre?.filter(item => item !== record?.DocID),
                )
            }}
            pagination={false}
            rowKey="DocID"
          />
          <div className="d-flex justify-content-flex-end mt-8">
            <Button
              btnType="primary"
              onClick={() => {
                setOpenModalDocType({
                  DocID: 0,
                  DocName: "Tài liệu gốc",
                  Fee: 0,
                  IsAddChild: false,
                  IsRequired: true,
                  Level: 0,
                  ParentID: -1,
                  children: dataDefalt?.Data,
                })
              }}
            >
              Thêm tài liệu
            </Button>
          </div>
          {!!openModalDocType && (
            <>
              <ModalDocType
                open={openModalDocType}
                onCancel={() => setOpenModalDocType(false)}
                setDataDefalt={setDataDefalt}
              />
            </>
          )}
          {!!openModalUpdateDocType && (
            <>
              <ModalUpdateDocType
                open={openModalUpdateDocType}
                onCancel={() => setOpenModalUpdateDocType(false)}
                setDataDefalt={setDataDefalt}
              />
            </>
          )}
          {/* {file?.fileUrl && ( */}
          <div style={{ display: "none" }}>
            <Document
              file={file?.fileUrl}
              onLoadError={err => {
                message.error(`Tài liệu ${file?.fileUp?.name || "pdf"} bị lỗi!`)
                //Lấy ra danh sách file đã upload
                let listFileUploaded = form.getFieldValue()
                //Bỏ file lỗi trong form
                form.setFieldsValue({
                  [file?.record?.DocID]: listFileUploaded[
                    file?.record?.DocID
                  ]?.filter(item => item?.uid !== file?.fileUp?.uid),
                })
                setNumPagesLoading(pre => ({
                  ...pre,
                  [file?.record?.DocID]: false,
                }))
              }}
              onLoadSuccess={e => {
                //Lấy ra danh sách file đã upload
                let listFileUploaded = form.getFieldValue() || []
                // lấy ra loại tài liệu
                let fileUploadedType = listFileUploaded[file?.record?.DocID]
                // lấy ra index tài liệu
                let fileUploadedIndex = fileUploadedType?.findIndex(
                  item => item?.uid === file?.fileUp?.uid,
                )
                fileUploadedType[fileUploadedIndex] = {
                  ...fileUploadedType[fileUploadedIndex],
                  NumberPage: e?.numPages || 0,
                }
                //Cập nhật số trang
                form.setFieldsValue({
                  [file?.record?.DocID]: fileUploadedType,
                })
                onDocumentLoadSuccess(
                  setNumPagesLoading,
                  setDataDefalt,
                  setInfoFile,
                  file,
                  e,
                )
              }}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
          {/* )} */}
        </Form>
      </DocumentsStyle>
    </SpinCustom>
  )
}

export default Documents
