import {
  apiUploadFile,
  apiUploadFileList,
  apiUploadListFileDossierSeaWeed,
  apiUploadFileBase64,
  apiInsertTextIntoPDF,
  apiUploadBienLai,
  apiUploadChunkingFile,
} from "./urls"
import http from "../index"

const uploadFile = body => http.post(apiUploadFile, body)
const uploadFileList = body => http.post(apiUploadFileList, body)
const uploadListFileDossierSeaWeed = body =>
  http.post(apiUploadListFileDossierSeaWeed, body)
const uploadFileBase64 = body => http.post(apiUploadFileBase64, body)
const insertTextIntoPDF = body => http.post(apiInsertTextIntoPDF, body)
const uploadBienLai = body => http.post(apiUploadBienLai, body)
const uploadChunkingFile = body => http.post(apiUploadChunkingFile, body)

const FileService = {
  uploadFileList,
  uploadFile,
  uploadListFileDossierSeaWeed,
  uploadFileBase64,
  insertTextIntoPDF,
  uploadBienLai,
  uploadChunkingFile,
}
export default FileService
