import html2pdf from "html2pdf.js"
import React, { useContext, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { StoreContext } from "src/lib/store"
import ROUTER from "src/router"

const PrintPdfFromHtml = () => {
  const { pdfFromHtmlStore } = useContext(StoreContext)
  const [filePdfFromHtml, setFilePdfFromHtml] = pdfFromHtmlStore
  const divRef = useRef(null)
  const nav = useNavigate()
  const handlePrint = useReactToPrint({
    content: () => divRef.current,
    // onBeforeGetContent: () => {
    // // Phóng to cửa sổ in trước khi hiển thị
    // const originalStyle = document.body.style.overflow
    // document.body.style.overflow = "auto"
    // window.print()
    // document.body.style.overflow = originalStyle
    // },
  })
  const dowloadPdfFromHtml = namePdf => {
    let element = document.getElementById("dowload-receipt-id")
    let opt = {
      // margin: [15, 0, 15, 0],
      filename: namePdf || `phiếu báo thu.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        // dpi: 192,
        scale: 4,
        // letterRendering: true,
        // useCORS: true,
      },

      jsPDF: {
        unit: "mm",
        format: "letter",
        orientation: "portrait",
      },
      // jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }

    // New Promise-based usage:
    html2pdf().from(element).set(opt).save()
  }
  useEffect(() => {
    // isDowload: có tải về luôn không,
    // htmlReceipt: html của pdf,
    // namePdf: tên file pdf
    if (!!filePdfFromHtml?.htmlReceipt) {
      // if (!!filePdfFromHtml?.isPreview) {
      //   nav(ROUTER.PREVIEW_PDF, {
      //     state: {
      //       htmlPdf: filePdfFromHtml?.htmlReceipt,
      //     },
      //   })
      // } else
      if (!!filePdfFromHtml?.isDowload)
        dowloadPdfFromHtml(filePdfFromHtml?.namePdf)
      else handlePrint()
    }
  }, [filePdfFromHtml])
  return (
    <div style={{ display: "none" }}>
      <div
        id="dowload-receipt-id"
        ref={divRef}
        dangerouslySetInnerHTML={{
          __html: filePdfFromHtml?.htmlReceipt,
        }}
        style={
          {
            // background: "#ccc",
            // width: "210mm" /* chiều rộng */,
            // height: "297mm" /* chiều cao */,
            // backgroundColor: "white" /* màu nền */,
            // margin: "0 auto" /* căn giữa */,
          }
        }
      ></div>
    </div>
  )
}

export default PrintPdfFromHtml
