import { Form, Input, Space } from "antd"
import React from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"

const ModalDocType = ({ open, onCancel, setDataDefalt }) => {
  const [formModalDocType] = Form.useForm()
  const handle = async () => {
    const value = await formModalDocType.validateFields()
    setDataDefalt(pre => ({
      ...pre,
      Data: [
        ...pre?.Data,
        {
          ...open,
          DocID: Number(`10${open?.DocID}${open?.children?.length || 0}`),
          DocName: value?.DocName,
          ParentID: open?.DocID,
          Level: (open?.Level || 0) + 1,
          SortOrder: open?.children?.length,
          DossierCateID: open?.DossierCateID,
          DossierCateDocID: open?.DossierCateDocID,
          Fee: open?.Fee,
          IsRequired: open?.IsRequired,
          IsAdd: true,
          IsAddChild: open?.canAddChildren,
        },
      ],
    }))
    onCancel()
  }
  return (
    <CustomModal
      title={"Thêm tài liệu"}
      width="50vw"
      footer={
        <Space className="d-flex-end">
          <Button btnType="primary" onClick={() => handle()}>
            Lưu
          </Button>
        </Space>
      }
      open={open}
      // onOk={handleOk}
      onCancel={onCancel}
    >
      <Form form={formModalDocType}>
        <div>
          <b>Tên tài liệu</b>
        </div>
        <Form.Item
          name="DocName"
          rules={[{ required: true, message: "Vui lòng nhập tên tài liệu" }]}
        >
          <Input placeholder="Tên tài liệu" />
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default ModalDocType
