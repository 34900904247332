export const apitinhPhiBoSungXacLapQuyen = "Fee/TinhPhiBoSungXacLapQuyen"
export const apiGetInforToCalFee = "Fee/GetInforToCalFee"
export const apiGetInforDossierInWipo = "Fee/GetInforDossierInWipo"
export const apiTinhPhiBoSungDonVBBH = "Fee/TinhPhiBoSungDonVBBH"
export const apiGetListPhiBS = "Fee/GetListPhiBS"
export const apiGetListLePhiBS = "Fee/GetListLePhiBS"
// export const apiTinhPhi = "Fee/TinhPhi"
export const apiTinhPhi = "Fee/TinhFee"
export const apiFeeList = "Fee/GetListFee"
//Tinh phi
export const apiTinhFee = "Fee/TinhFee"

export const apiGetListFeeNew = "Fee/GetListFeeNew"
