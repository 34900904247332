import dayjs from "dayjs"
import { convertStringDateToDayjs, formatDateToYYYYMMDD } from "./utils"

import { valiableUndefineOfForm_1 } from "../data/index"
///=====================Convert từ api getInfor sang===============================
export const ownerWithView = values => {
  if (values) {
    return {
      OwnerFullName: values?.FullName,
      OwnerAddress: values?.Address,
      OwnerPhone: values?.Phone,
      OwnerNumberCCCD: values?.NumberCCCD,
      OwnerEmail: values?.Email,
      OwnerCountry: !!values?.Country ? +values?.Country : undefined,
      OwnerTypeSubmitter: !!values?.TypeSubmitter
        ? +values?.TypeSubmitter
        : undefined,
      OwnerProvinceID: !!values?.ProvinceID ? +values?.ProvinceID : undefined,
      PracticingCertificateNumber: !!values?.PracticingCertificateNumber
        ? values?.PracticingCertificateNumber
        : undefined,
      BusinessCode: !!values?.BusinessCode ? values?.BusinessCode : undefined,
      OwnerCode: !!values?.Code ? values?.Code : undefined,

      NamePracticingCertificate: !!values?.NamePracticingCertificate
        ? values?.NamePracticingCertificate
        : undefined,
      // IsRequestPatentCopy: values?.OwnerIsRequestPatentCopy, //Yêu cầu phó bản bằng sáng chế của chủ đơn khác

      IsMasterProfile: true, //Phân biệt chủ đơn và chủ đơn khác
      IsAuthor: !!values?.IsAuthor ? values?.IsAuthor : undefined, //Đồng thời là tác giả
    }
  }
  return {}
}
export const convertOwnerOther = values => {
  if (values?.length)
    return values?.map((item, idx) => ({
      idOwner: idx + 2,
      OrtherOwnerFullName: item?.FullName,
      OrtherOwnerTypeSubmitter: !!item?.TypeSubmitter
        ? +item?.TypeSubmitter
        : undefined,
      OrtherOwnerAddress: item?.Address,
      OrtherOwnerCountry: !!item?.Country ? +item?.Country : undefined,
      OrtherOwnerProvinceID: !!item?.ProvinceID ? +item?.ProvinceID : undefined,
      OrtherOwnerPhone: item?.Phone,
      OrtherOwnerNumberCCCD: item?.NumberCCCD,
      OrtherOwnerEmail: item?.Email,
      OrtherOwnerIsAuthor: !!item?.IsAuthor,
      IsMasterProfile: item?.IsMasterProfile,
      OrtherOwnerIsRequestPatentCopy: item?.IsRequestPatentCopy,
    }))
  else return []
}
export const convertAuthorOther = values => {
  if (values?.length)
    return values?.map((item, idx) => ({
      id: idx + 2,
      OrtherAuthorFullName: item?.FullName,
      OrtherAuthorNumberCCCD: item?.NumberCCCD,
      OrtherAuthorAddress: item?.Address,
      OrtherAuthorNationality: !!item?.Nationality
        ? +item?.Nationality
        : undefined,
      OrtherAuthorPhone: item?.Phone,
      OrtherAuthorEmail: item?.Email,
    }))
  else return []
}
export const authorWithView = values => {
  if (values) {
    return {
      AuthorFullName: values?.FullName,
      AuthorNationality: !!values?.Nationality
        ? +values?.Nationality
        : undefined,
      AuthorAddress: values?.Address,
      AuthorPhone: values?.Phone,
      AuthorEmail: values?.Email,
      AuthorDiff: values?.IsMasterProfile,
    }
  }
}
export const convertOwnerWithAuthor = (ownerAuthor, form, ownersOrther) => {
  const author = form.getFieldValue()
  let data = []
  if (!!ownerAuthor) {
    data.push({
      idOwner: -2,
      noEdit: true,
      OrtherAuthorFullName: author?.OwnerFullName,
      OrtherAuthorAddress: author?.OwnerAddress,
      OrtherAuthorNationality: author?.OwnerCountry,
      OrtherAuthorNumberCCCD: author?.OwnerNumberCCCD,
      OrtherAuthorPhone: author?.OwnerPhone,
      OrtherAuthorFax: author?.OwnerFax,
      OrtherAuthorEmail: author?.OwnerEmail,
    })
  }
  ownersOrther?.forEach(item => {
    if (!!item?.OrtherOwnerIsAuthor)
      data.push({
        idOwner: item?.idOwner,
        noEdit: true,
        OrtherAuthorFullName: item?.OrtherOwnerFullName,
        OrtherAuthorAddress: item?.OrtherOwnerAddress,
        OrtherAuthorNationality: item?.OrtherOwnerCountry,
        OrtherAuthorNumberCCCD: item?.OrtherOwnerNumberCCCD,
        OrtherAuthorPhone: item?.OrtherOwnerPhone,
        OrtherAuthorFax: item?.OrtherOwnerFax,
        OrtherAuthorEmail: item?.OrtherOwnerEmail,
      })
  })
  return data
}
export const applicationOwnerRepresentativeWithView = values => {
  // if (values) {
  return {
    RepresentFullName: values?.FullName,
    RepresentAddress: values?.Address,
    RepresentPhone: values?.Phone,
    RepresentEmail: values?.Email,
    RepresentPosition: values?.Position,
    ApplicationOwnerRepresentativeType:
      values?.ApplicationOwnerRepresentativeType,

    Code: values?.Code || undefined, //Mã đại diện
  }
  // }
}
export const priorityWithView = values => {
  if (values?.length)
    return values?.map((item, idx) => ({
      // id: idx,
      key: idx,
      number: item?.NumberUnit,
      date: item?.DateApplication ? dayjs(item?.DateApplication) : undefined,
      country: item?.ApplicantCountry,
    }))
  else return []
}

export const profileOriginWithView = dataRes => {
  let ListAmendmentsDiff = []
  let ProfileOrigin = {
    ProfileOrigin6: false,
    ProfileOrigin7: false,
    ProfileOrigin8: false,
  }
  dataRes?.ProfileOrigin?.forEach(item => {
    ProfileOrigin = { ...ProfileOrigin, [`ProfileOrigin${item}`]: true }
  })

  if (!!dataRes?.IsUpdate) {
    ProfileOrigin = {
      ...ProfileOrigin,
      ProfileOrigin5: !!dataRes?.IsUpdate,
    }
  }
  if (dataRes?.Modification?.length) {
    dataRes?.Modification?.forEach(item => {
      if (item?.SelectModifications === 1) {
        ProfileOrigin = {
          ...ProfileOrigin,
          ProfileOrigin6: true,
          ContentModifications6: item?.ContentModifications,
        }
      }
      if (item?.SelectModifications === 2) {
        ProfileOrigin = {
          ...ProfileOrigin,
          ProfileOrigin7: true,
          ContentModifications7: item?.ContentModifications,
        }
      }
      if (item?.SelectModifications === 3) {
        ProfileOrigin = {
          ...ProfileOrigin,
          ProfileOrigin8: true,
        }
        ListAmendmentsDiff = [
          ...ListAmendmentsDiff,
          {
            AmendmentsDiffName: item?.NameModifications,
            AmendmentsDiffContent: item?.ContentModifications,
          },
        ]
      }
    })
  }
  return { ListAmendmentsDiff, ProfileOrigin }
}

export const fileListWithView = ({
  Doc,
  setCheckbox,
  haveFirstCopy = false,
  haveOriginalNumber = false,
  formFile,
}) => {
  let result = {}
  let fileList = {}
  // dataDefaltDoc?.Data?.forEach(item => {
  let OriginalNumber = {}
  let FirstCopyNumber = {}
  Doc?.forEach(itemDoc => {
    let checkBoxAdd = {}
    if (!!haveFirstCopy)
      checkBoxAdd = {
        ...checkBoxAdd,
        [`IsFirstCopy${itemDoc?.DocID}`]:
          itemDoc?.IsFirstCopy === true
            ? 1
            : itemDoc?.IsFirstCopy === false
            ? 0
            : undefined,
      }
    if (!!haveOriginalNumber)
      checkBoxAdd = {
        ...checkBoxAdd,
        [`IsOriginalSubmitted${itemDoc?.DocID}`]:
          itemDoc?.IsOriginalSubmitted === true
            ? 1
            : itemDoc?.IsOriginalSubmitted === false
            ? 0
            : undefined,
      }
    setCheckbox(pre => ({ ...pre, ...checkBoxAdd }))

    if (!!itemDoc?.OriginalNumber && haveOriginalNumber) {
      OriginalNumber = {
        ...OriginalNumber,
        [`OriginalNumber${itemDoc?.DocumentID}`]: itemDoc?.OriginalNumber,
      }
    }
    if (!!itemDoc?.FirstCopyNumber && haveFirstCopy) {
      FirstCopyNumber = {
        ...FirstCopyNumber,
        [`FirstCopyNumber${itemDoc?.DocID}`]: itemDoc?.FirstCopyNumber,
      }
    }

    if (itemDoc?.ListFiles?.length) {
      // fileList = {
      //   ...fileList,
      //   [itemDoc?.DocumentID]: itemDoc?.ListFiles?.map(itemFile => ({
      //     uid: `uid${itemFile?.ObjectFileID}`,
      //     ObjectFileID: itemFile?.ObjectFileID,
      //     url: itemFile?.FileUrl,
      //     name: itemFile?.FileName,
      //     NumberPageFile: itemFile?.NumberPageFile,
      //   })),
      // }
      fileList[itemDoc?.DocumentID] = itemDoc?.ListFiles?.map(itemFile => ({
        uid: `uid${itemFile?.ObjectFileID}`,
        ObjectFileID: itemFile?.ObjectFileID,
        url: itemFile?.FileUrl,
        name: itemFile?.FileName,
        NumberPageFile: itemFile?.NumberPageFile,
      }))
    }
  })

  if (!!fileList)
    result = { ...fileList, ...OriginalNumber, ...FirstCopyNumber }
  else result = {}

  formFile.setFieldsValue(result)
  return result
}
//===================================================================

///=================Convert từ form để truyền lên==================
export const convertDoc = (values, setDossierLocal = () => {}) => {
  let document = values?.Document || []
  if (document?.length) {
    setDossierLocal(pre => {
      if (!!pre)
        return {
          ...pre,
          Document: pre?.Document?.length
            ? pre?.Document?.map((item, idx) => {
                let docFind = document?.find(
                  itemdocument => itemdocument?.DocumentID === item?.DocumentID,
                )
                if (docFind)
                  return {
                    ...item,
                    ...docFind,
                    DocName: docFind?.DocName,
                    ID: docFind?.ID,
                  }
                else return item
              })
            : [],
        }
      return {}
    })
    return document?.map((item, idx) => ({
      ...item,
      DocName: item?.Name,
      DocID: item?.DocumentID,
      IsAdd: item?.Type === 2,
    }))
  } else return []
}

export const convertOwner = (
  values,
  checkbox,
  ownersOrther,
  ownerAuthor = false,
) => {
  let ApplicationOwner = [
    {
      IsMasterProfile: true, //Phân biệt chủ đơn và chủ đơn khác
      FullName: values?.OwnerFullName,
      Address: values?.OwnerAddress,
      Phone: values?.OwnerPhone,
      NumberCCCD: values?.OwnerNumberCCCD,
      Email: values?.OwnerEmail,
      Country: !!values?.OwnerCountry ? values?.OwnerCountry : undefined,
      TypeSubmitter: values?.OwnerTypeSubmitter,
      ProvinceID: !!values?.OwnerProvinceID
        ? values?.OwnerProvinceID
        : undefined,
      IsAuthor: !!ownerAuthor, //Đồng thời là tác giả
      PracticingCertificateNumber: !!values?.PracticingCertificateNumber
        ? values?.PracticingCertificateNumber
        : undefined,
      NamePracticingCertificate: !!values?.NamePracticingCertificate
        ? values?.NamePracticingCertificate
        : undefined,
      InspectionCardNumber: !!values?.InspectionCardNumber
        ? values?.InspectionCardNumber
        : undefined,
      BusinessCode: !!values?.BusinessCode ? values?.BusinessCode : undefined,
      Code: !!values?.OwnerCode ? values?.OwnerCode : undefined,
      TypeRequestPerson: !!checkbox?.TypeRequestPerson
        ? checkbox?.TypeRequestPerson
        : undefined,
    },
  ]
  if (!!checkbox?.OrtherOwner && !!ownersOrther?.length) {
    let ortherOwner = []
    ortherOwner = ownersOrther?.map(item => ({
      FullName: item?.OrtherOwnerFullName,
      Address: item?.OrtherOwnerAddress,
      Phone: item?.OrtherOwnerPhone,
      NumberCCCD: item?.OrtherOwnerNumberCCCD,
      Email: item?.OrtherOwnerEmail,
      Country: !!item?.OrtherOwnerCountry
        ? item?.OrtherOwnerCountry
        : undefined,
      TypeSubmitter: item?.OrtherOwnerTypeSubmitter,
      ProvinceID: !!item?.OrtherOwnerProvinceID
        ? item?.OrtherOwnerProvinceID
        : undefined,
      IsRequestPatentCopy: item?.OrtherOwnerIsRequestPatentCopy, //Yêu cầu phó bản bằng sáng chế của chủ đơn khác
      IsAuthor: !!item?.OrtherOwnerIsAuthor, //Đồng thời là tác giả))
      IsMasterProfile: false, //Phân biệt chủ đơn và chủ đơn khác
    }))

    return [...ApplicationOwner, ...ortherOwner]
  }
  return [...ApplicationOwner]
}
export const convertApplicationOwnerRepresentative = (values, checkbox) => {
  if (!!checkbox?.ApplicationOwnerRepresentativeType) {
    let ApplicationOwnerRepresentative = [
      {
        FullName: values?.RepresentFullName,
        Address: values?.RepresentAddress,
        Phone: values?.RepresentPhone,
        Email: values?.RepresentEmail,
        Country: !!values?.RepresentCountry
          ? values?.RepresentCountry
          : undefined,
        TypeSubmitter: values?.RepresentTypeSubmitter,
        ProvinceID: !!values?.RepresentProvinceID
          ? values?.RepresentProvinceID
          : undefined,
        Code: values?.Code || undefined, //Mã đại diện
        Position: values?.RepresentPosition,
        ApplicationOwnerRepresentativeType:
          checkbox?.ApplicationOwnerRepresentativeType,
      },
    ]
    return ApplicationOwnerRepresentative
  }
  return []
}
export const convertAuthor = (values, checkbox, authorsOrther) => {
  let Author = [
    {
      FullName: values?.AuthorFullName,
      Address: values?.AuthorAddress,
      Nationality: !!values?.AuthorNationality
        ? values?.AuthorNationality
        : undefined,
      Phone: values?.AuthorPhone,
      Email: values?.AuthorEmail,
      TypeSubmitter: values?.AuthorTypeSubmitter,
      IsMasterProfile: true, //Phân biệt chủ đơn và chủ đơn khác
    },
  ]
  if (!!authorsOrther?.length) {
    let orTherAuthor = []
    orTherAuthor = authorsOrther?.map(item => ({
      FullName: item?.OrtherAuthorFullName,
      NumberCCCD: item?.OrtherAuthorNumberCCCD,
      Address: item?.OrtherAuthorAddress,
      Nationality: !!item?.OrtherAuthorNationality
        ? item?.OrtherAuthorNationality
        : null,
      Phone: item?.OrtherAuthorPhone,
      Email: item?.OrtherAuthorEmail,
      TypeSubmitter: item?.OrtherAuthorTypeSubmitter,
      IsMasterProfile: false, //Phân biệt chủ đơn và chủ đơn khác
    }))
    return [...Author, ...orTherAuthor]
  }
  return Author
}
export const convertPriority = dataPrio => {
  let notErr = true
  dataPrio?.forEach(item => {
    if (!item?.number || !item?.country) {
      notErr = false
    }
  })

  if (!!notErr) {
    const data = dataPrio?.map(item => ({
      SortOrder: +item?.key,
      NumberUnit: item?.number,
      DateApplication: item?.date ? item?.date?.format() : undefined,
      ApplicantCountry: item?.country,
    }))
    return data
  } else return false
}
export const convertDocument = async (
  values,
  dataDefaltDoc,
  dossierDetail,
  isEdit,
  checkbox,
) => {
  let Document = []
  for (var key in values) {
    let docid = key
    if (values[docid]?.length) {
      for (let i = 0; i < values[docid]?.length; i++) {
        let file = values[docid]?.[i]
        if (!file?.error) {
          // let linkDoc = await upFile(file?.originFileObj)
          let docItem = await dataDefaltDoc?.Data?.find(
            itemdataDefaltDoc => `${itemdataDefaltDoc?.DocID}` === `${docid}`,
          )
          if (
            file?.originFileObj ||
            file?.ObjectFileID ||
            docItem?.canAddChildren
          ) {
            let fileUp = file ? file?.originFileObj : undefined
            if (!!fileUp) fileUp[`NumberPage`] = file?.NumberPage
            Document.push({
              File: fileUp,
              DocName: docItem?.DocName,
              ParentID: docItem?.ParentID,
              ID: docItem?.ID,
              Level: docItem?.Level,
              Language: docItem?.Language ? docItem?.Language : "Tiếng Việt",
              IsOriginalSubmitted: !!checkbox[`IsOriginalSubmitted${docid}`],
              OriginalNumber: values?.[`OriginalNumber${docid}`],
              IsFirstCopy: !!checkbox[`IsFirstCopy${docid}`],
              FirstCopyNumber: values?.[`FirstCopyNumber${docid}`],
              DocumentID: docItem?.DocID,
              Type: docItem?.IsAdd ? 2 : 1,
              // NumberCopies: NumberCopiesOld + (docItem?.FileLoad?.length || 0),
              // SampleNumber: 0,
              // Form:  0,
              // : docItem?.DossierCateDocID,
            })
          }
        }
      }
    }
  }
  return Document
}
export const convertProfileOrigin = checkbox => {
  let listPro = []
  if (checkbox?.ProfileOrigin1) {
    listPro.push(1)
  }
  if (checkbox?.ProfileOrigin2) {
    listPro.push(2)
  }
  if (checkbox?.ProfileOrigin3) {
    listPro.push(3)
  }
  if (checkbox?.ProfileOrigin4) {
    listPro.push(4)
  }
  return listPro
}
export const convertModifications = (checkbox, values) => {
  let listModi = []
  if (checkbox?.ProfileOrigin6) {
    listModi.push({
      ContentModifications: values?.ContentModifications6,
      SelectModifications: 1,
      SortOrder: 0,
    })
  }
  if (checkbox?.ProfileOrigin7) {
    listModi.push({
      ContentModifications: values?.ContentModifications7,
      SelectModifications: 2,
      SortOrder: 0,
    })
  }
  if (checkbox?.ProfileOrigin8) {
    if (values?.ListAmendmentsDiff?.length) {
      values?.ListAmendmentsDiff?.forEach(item => {
        listModi.push({
          ContentModifications: item?.AmendmentsDiffContent,
          SelectModifications: 3,
          SortOrder: 0,
          NameModifications: item?.AmendmentsDiffName,
          IsOrther: true,
        })
      })
    }
  }
  return listModi
}
export const convertFee = data => {
  if (data?.length) {
    let listFee = data?.map(item => ({
      FeeID: item?.FeeID,
      FeeName: item?.FeeName,
      Fee: item?.Fee,
      Number: item?.Quantity,
      Total: item?.Total,
    }))
    return listFee
  } else return []
}

//1.005265
export const convertOneOwner = values => {
  let ApplicationOwner = {
    IsMasterProfile: true, //Phân biệt chủ đơn và chủ đơn khác
    FullName: values?.OwnerFullName,
    Address: values?.OwnerAddress,
    Phone: values?.OwnerPhone,
    NumberCCCD: values?.OwnerNumberCCCD,
    Email: values?.OwnerEmail,
    ProvinceID: values?.OwnerProvinceID,
    Country: values?.OwnerCountry,
    TypeSubmitter: values?.OwnerTypeSubmitter,
  }
  return ApplicationOwner
}

export const convertOrganization = values => {
  let ManageOrganization = {
    FullName: values?.OrganizationFullName,
    Address: values?.OrganizationAddress,
    Phone: values?.OrganizationPhone,
    Email: values?.OrganizationEmail,
  }
  return ManageOrganization
}
export const convertOrganizationWithView = values => {
  let ManageOrganization = {
    OrganizationFullName: values?.FullName,
    OrganizationAddress: values?.Address,
    OrganizationPhone: values?.Phone,
    OrganizationEmail: values?.Email,
  }
  return ManageOrganization
}

//2.002126

export const convertComunityWithView = data => {
  let newData = {}
  if (!!data) {
    data.forEach((item, index) =>
      Object.keys(item).forEach(key => {
        if (key === "key") {
          newData[key] = item[key]
        } else {
          newData[`${key}_${index}`] = item[key]
        }
      }),
    )
  }
  return newData
}
// convertDocumentMultiLevel dùng trong form 1.005223 thôi
export const convertDocumentMultiLevel = async (
  values,
  dataDefaltDoc,
  dossierDetail,
  isEdit,
  checkbox,
) => {
  let Document = []
  let listFolder = dataDefaltDoc?.Data?.filter(
    i => i.canAddChildren && i.IsAddChild,
  )?.map(i => ({
    File: undefined,
    DocName: i?.DocName,
    ParentID: i?.ParentID,
    ID: i?.ID,
    Level: i?.Level,
    Language: i?.Language ? i?.Language : "Tiếng Việt",
    IsOriginalSubmitted: !!checkbox[`IsOriginalSubmitted${i.DocID}`],
    OriginalNumber: values?.[`OriginalNumber${i?.DocID}`],
    IsFirstCopy: !!checkbox[`IsFirstCopy${i?.DocID}`],
    FirstCopyNumber: values?.[`FirstCopyNumber${i?.DocID}`],
    // NumberCopies: NumberCopiesOld + (i?.FileLoad?.length || 0),
    // SampleNumber: 0,
    // Form:  0,
    DocumentID: i?.DocID,
    // : i?.DossierCateDocID,
    Type: i?.IsAdd ? 2 : 1,
  }))
  for (var key in values) {
    let docid = key
    if (values[key]?.length) {
      for (let i = 0; i < values[key]?.length; i++) {
        let file = values[key]?.[i]
        if (!file?.error) {
          // let linkDoc = await upFile(file?.originFileObj)
          let docItem = await dataDefaltDoc?.Data?.find(
            itemdataDefaltDoc => `${itemdataDefaltDoc?.DocID}` === `${docid}`,
          )
          if (
            file?.originFileObj ||
            file?.ObjectFileID ||
            docItem?.canAddChildren
          ) {
            let fileUp = file ? file?.originFileObj : undefined
            if (!!fileUp) fileUp[`NumberPage`] = file?.NumberPage
            Document.push({
              File: fileUp,
              DocName: docItem?.DocName,
              ParentID: docItem?.ParentID,
              ID: docItem?.ID,
              Level: docItem?.Level,
              Language: docItem?.Language ? docItem?.Language : "Tiếng Việt",
              IsOriginalSubmitted: !!checkbox[`IsOriginalSubmitted${docid}`],
              OriginalNumber: values?.[`OriginalNumber${docid}`],
              IsFirstCopy: !!checkbox[`IsFirstCopy${docid}`],
              FirstCopyNumber: values?.[`FirstCopyNumber${docid}`],
              // NumberPage: NumberPageOld + (NumberPage || 0),

              // NumberCopies: NumberCopiesOld + (docItem?.FileLoad?.length || 0),

              // SampleNumber: 0,
              // Form:  0,
              DocumentID: docItem?.DocID,
              // : docItem?.DossierCateDocID,
              Type: docItem?.IsAdd ? 2 : 1,
            })
          }
        }
      }
    }
  }
  return [...Document, ...listFolder]
}
// =====================================================================================

//Loại bỏ doc bị trùng nhau
export const removeDuplicatesByDocumentID = objects => {
  if (objects?.length) {
    const seenDocumentIDs = {} // Đối tượng để theo dõi các DocumentID đã xuất hiện
    const uniqueObjects = [] // Mảng chứa các object duy nhất

    for (const obj of objects) {
      if (!seenDocumentIDs[obj.DocumentID] && !!obj.DocumentID) {
        seenDocumentIDs[obj.DocumentID] = true // Đánh dấu DocumentID đã xuất hiện
        uniqueObjects.push(obj) // Thêm object vào mảng kết quả
      }
    }

    return uniqueObjects
    //backend yêu cầu thêm : chỉ truyền những doc không có ID
    // return uniqueObjects?.filter(item => !item?.ID)
  } else return []
}
//Loại bỏ các thuộc tính null của object
export const convertRemoveNulls = data => {
  let dataReturn = {}
  Object.keys(data).forEach(key => {
    if (data[key] !== null)
      dataReturn = { ...dataReturn, [`${key}`]: data[key] }
  })
  return dataReturn
}
//Loại bỏ biến thừa
export const dataUndefine = {
  AuthorAddress: undefined,
  AuthorDiff: undefined,
  AuthorEmail: undefined,
  AuthorFullName: undefined,
  AuthorNationality: undefined,
  AuthorPhone: undefined,
  RepresentAddress: undefined,
  RepresentEmail: undefined,
  RepresentFullName: undefined,
  RepresentPhone: undefined,
  RepresentPosition: undefined,
  // RequestPriorityRight: undefined,
  agree: undefined,
  checkboxdefau: undefined,
  OrtherOwner: undefined,
  OwnerAddress: undefined,
  OwnerCountry: undefined,
  OwnerEmail: undefined,
  OwnerFullName: undefined,
  OwnerPhone: undefined,
  OwnerProvinceID: undefined,
  OwnerTypeSubmitter: undefined,

  ProfileType: undefined,
  ProfileOrigin1: undefined,
  ProfileOrigin2: undefined,
  ProfileOrigin3: undefined,
  ProfileOrigin4: undefined,
  ProfileOrigin5: undefined,
  ProfileOrigin6: undefined,
  ProfileOrigin7: undefined,
  ProfileOrigin8: undefined,

  ListAmendmentsDiff: undefined,
  ApplicationOwnerRepresentativeType: undefined,
  Modification: undefined,
  fee: undefined,
  Code: undefined,
  OwnerNumberCCCD: undefined,
}
export const dataUndefine1011912 = {
  AuthorAddress: undefined,
  AuthorDiff: undefined,
  AuthorEmail: undefined,
  AuthorFullName: undefined,
  AuthorNationality: undefined,
  AuthorPhone: undefined,
  RepresentAddress: undefined,
  RepresentEmail: undefined,
  RepresentFullName: undefined,
  RepresentPhone: undefined,
  RepresentPosition: undefined,
  // RequestPriorityRight: undefined,
  agree: undefined,
  checkboxdefau: undefined,
  OrtherOwner: undefined,
  OwnerAddress: undefined,
  OwnerCountry: undefined,
  OwnerEmail: undefined,
  OwnerFullName: undefined,
  OwnerPhone: undefined,
  OwnerProvinceID: undefined,
  OwnerTypeSubmitter: undefined,

  ProfileType: undefined,
  ProfileOrigin1: undefined,
  ProfileOrigin2: undefined,
  ProfileOrigin3: undefined,
  ProfileOrigin4: undefined,
  ProfileOrigin5: undefined,
  ProfileOrigin6: undefined,
  ProfileOrigin7: undefined,
  ProfileOrigin8: undefined,

  ListAmendmentsDiff: undefined,
  ApplicationOwnerRepresentativeType: undefined,
  Modification: undefined,
  fee: undefined,
  Code: undefined,
  OwnerNumberCCCD: undefined,
  OwnerPosition: undefined,
}
export const dataUndefine1011927 = {
  AuthorAddress: undefined,
  AuthorDiff: undefined,
  AuthorEmail: undefined,
  AuthorFullName: undefined,
  AuthorNationality: undefined,
  AuthorPhone: undefined,
  RepresentAddress: undefined,
  RepresentEmail: undefined,
  RepresentFullName: undefined,
  RepresentPhone: undefined,
  RepresentPosition: undefined,
  // RequestPriorityRight: undefined,
  agree: undefined,
  checkboxdefau: undefined,
  OrtherOwner: undefined,
  OwnerAddress: undefined,
  OwnerCountry: undefined,
  OwnerEmail: undefined,
  OwnerFullName: undefined,
  OwnerPhone: undefined,
  OwnerProvinceID: undefined,
  OwnerTypeSubmitter: undefined,

  ProfileType: undefined,
  ProfileOrigin1: undefined,
  ProfileOrigin2: undefined,
  ProfileOrigin3: undefined,
  ProfileOrigin4: undefined,
  ProfileOrigin5: undefined,
  ProfileOrigin6: undefined,
  ProfileOrigin7: undefined,
  ProfileOrigin8: undefined,

  ListAmendmentsDiff: undefined,
  Modification: undefined,
  fee: undefined,
  Code: undefined,
  OwnerNumberCCCD: undefined,
  OwnerPosition: undefined,
}
export const dataUndefine1011922 = {
  AuthorAddress: undefined,
  AuthorDiff: undefined,
  AuthorEmail: undefined,
  AuthorFullName: undefined,
  AuthorNationality: undefined,
  AuthorPhone: undefined,
  agree: undefined,
  checkboxdefau: undefined,
  OrtherOwner: undefined,
  OwnerAddress: undefined,
  OwnerCountry: undefined,
  OwnerEmail: undefined,
  OwnerFullName: undefined,
  OwnerPhone: undefined,
  OwnerProvinceID: undefined,
  OwnerTypeSubmitter: undefined,
  ListAmendmentsDiff: undefined,
  ApplicationOwnerRepresentativeType: undefined,
  fee: undefined,
  Code: undefined,
  OwnerNumberCCCD: undefined,
  OwnerPosition: undefined,
}

//Convert body truyền lên và getInfor
//1.PatentRegistration
export const convertBodyStep1For1_PatentRegistration = ({
  dossierID,
  checkbox,
  valuesFormStep1,
  ApplicationOwners,
  Authors,
  ApplicationOwnerRepresentatives,
  Priorities,
  ProfileOrigin,
  Modifications,
}) => {
  return {
    DossierID: dossierID ? dossierID : undefined,
    ...checkbox,
    ...valuesFormStep1,
    IsPatentRights: checkbox?.IsPatentRights || 1,
    ValidDecision: !!checkbox?.ProfileOrigin3
      ? checkbox?.ValidDecision || 1
      : undefined,
    RequestPriorityRight: checkbox?.RequestPriorityRight,
    IsClassify: !!checkbox?.IsClassify,
    SingleSeparationNoDate: formatDateToYYYYMMDD(
      valuesFormStep1?.SingleSeparationNoDate,
    ),
    DigitalConversionUnitDate: formatDateToYYYYMMDD(
      valuesFormStep1?.DigitalConversionUnitDate,
    ),
    InternationalFilingDate: formatDateToYYYYMMDD(
      valuesFormStep1?.InternationalFilingDate,
    ),
    InternationalPublicationDay: formatDateToYYYYMMDD(
      valuesFormStep1?.InternationalPublicationDay,
    ),
    SelectedDateVN: formatDateToYYYYMMDD(valuesFormStep1?.SelectedDateVN),

    ApplicationOwners: ApplicationOwners,
    Authors: Authors,
    ApplicationOwnerRepresentatives: ApplicationOwnerRepresentatives?.length
      ? ApplicationOwnerRepresentatives
      : undefined,
    Priorities: Priorities?.length ? Priorities : undefined,
    ProfileOrigin: ProfileOrigin?.length ? ProfileOrigin?.join() : undefined,
    IsPaperVersion: Array.isArray(valuesFormStep1?.IsPaperVersion)
      ? valuesFormStep1?.IsPaperVersion?.join()
      : valuesFormStep1?.IsPaperVersion,

    Modifications: Modifications,
    IndependentProtectionPoint: valuesFormStep1?.IndependentProtectionPoint,
    NumberPCT: valuesFormStep1?.NumberPCT
      ? valuesFormStep1?.NumberPCT.toString()
      : undefined,
    SingleSeparationNo: valuesFormStep1?.SingleSeparationNo
      ? valuesFormStep1?.SingleSeparationNo.toString()
      : undefined,
    DigitalConversionUnit: valuesFormStep1?.DigitalConversionUnit
      ? valuesFormStep1?.DigitalConversionUnit.toString()
      : undefined,
    ApplicationDateNo: formatDateToYYYYMMDD(valuesFormStep1?.ApplicationDateNo),
    DateApplication: formatDateToYYYYMMDD(valuesFormStep1?.DateApplication),
    ...dataUndefine,
  }
}
export const convertDataDetailFor1_PatentRegistration = (
  dataRes,
  ProfileOrigin,
) => {
  return {
    //Đại diện chỉ có 1 người nhưng BE trả ApplicationOwnerRepresentative là list nên phải lấy ra phần tử đầu tiên
    ...dataRes?.ApplicationOwnerRepresentative?.[0],
    ...dataRes,
    ...ProfileOrigin,
    IndependentProtectionPoint: dataRes?.IndependentProtectionPoint,
    CreateDate: undefined,
    DatePublication: undefined,
    ApplicationDateNo: convertStringDateToDayjs(dataRes?.ApplicationDateNo),
    DateApplication: convertStringDateToDayjs(dataRes?.DateApplication),
    DigitalConversionUnitDate: convertStringDateToDayjs(
      dataRes?.DigitalConversionUnitDate,
    ),
    InternationalFilingDate: convertStringDateToDayjs(
      dataRes?.InternationalFilingDate,
    ),
    InternationalPublicationDay: convertStringDateToDayjs(
      dataRes?.InternationalPublicationDay,
    ),
    SelectedDateVN: convertStringDateToDayjs(dataRes?.SelectedDateVN),
    SingleSeparationNoDate: convertStringDateToDayjs(
      dataRes?.SingleSeparationNoDate,
    ),
  }
}
export const saveDossierBody1_PatentRegistration = ({
  dossier,
  Fees = undefined,
  Fee = undefined,
  //Body của api tính phí
  dataMoney = undefined,
  typeForm,
  dossierDetail,
  IsDraft,
  notDuplicatesDoc,

  //Biến riêng của các form
  //1.005265
  CddlUrl = undefined,
  //2.002126
  BrandUrl = undefined,
  //1.011.909_10/ 1.011921
  ListDocDelete = undefined,
  //1.003.996
  ltTaiLieu = undefined,
  //Đăng ký nhãn hiệu
  BrandID = undefined,
}) => {
  let body_add_new = {}
  //1_005265
  if (!!CddlUrl)
    body_add_new = {
      CddlUrl: CddlUrl,
    }
  //2.002126
  if (!!BrandUrl)
    body_add_new = {
      BrandUrl,
    }

  //1.011.898
  if (!!dossier?.InventDeductedMaintainTheNextValidity)
    body_add_new = {
      InventDeductedMaintainTheNextValidity:
        !!dossier?.InventDeductedMaintainTheNextValidity,
    }
  //1.011.909_10
  if (!!ListDocDelete)
    body_add_new = {
      ListDocDelete,
    }

  //1.003.996
  if (!!ltTaiLieu)
    body_add_new = {
      ltTaiLieu,
    }
  //Đăng ký nhãn hiệu

  if (!!BrandID)
    body_add_new = {
      ...body_add_new,
      BrandID,
    }

  //Nếu chủ đơn chính đồng thời là tác giả thì loại bỏ tác giả chính chỉ truyền tác giả khác
  // if (
  //   !!dossier?.ApplicationOwners?.find(
  //     item => !!item?.IsMasterProfile && !!item?.IsAuthor,
  //   )
  // )
  //   body_add_new = {
  //     ...body_add_new,
  //     Authors: dossier?.Authors?.filter(item => !item?.IsMasterProfile),
  //   }
  // body mới của form đã đổi

  return {
    ...dossier,
    ProfileType: typeForm?.Prefix,
    // ProfileType: typeForm?.CodeTTHC,
    DossierID: dossier?.DossierID || dossierDetail?.DossierID,
    DossierCateID: typeForm?.DossierCateID || dossierDetail?.DossierCateID,
    IsDraft: !!IsDraft,
    //Đổi biến
    // Fees: Fees || Fee,
    //Body của api tính phí
    FeesCharge:
      !IsDraft && dataMoney?.FeesCharge ? dataMoney?.FeesCharge : undefined,
    Documents: notDuplicatesDoc,
    //body bổ xung
    ...body_add_new,
    //Hiện không truyền Fee nữa mà để BE tính
    Fees: undefined,
    ...valiableUndefineOfForm_1,
  }
}
//1.005265 - Thủ tục đăng ký chỉ dẫn địa lý (6)
export const convertBodyStep1For1_005265 = ({
  dossierID,
  checkbox,
  valuesFormStep1,
  ApplicationOwner,
  ApplicationOwnerRepresentative,
  Organization,
}) => {
  return {
    ...checkbox,
    ApplicationCodeForDeclaration:
      valuesFormStep1?.ApplicationCodeForDeclaration,
    DossierID: dossierID?.DossierID,
    IsRegistered: checkbox?.IsRegistered,
    ProductName: valuesFormStep1?.ProductName,
    Description: valuesFormStep1?.Description,
    Technology: undefined,
    ReristrationNumber: checkbox?.IsRegistered
      ? valuesFormStep1?.ReristrationNumber
      : undefined,
    ReristrationDate: checkbox?.IsRegistered
      ? valuesFormStep1?.ReristrationDate?.format()
      : undefined,
    ReristrationCountry: checkbox?.IsRegistered
      ? valuesFormStep1?.ReristrationCountry
      : undefined,
    ApplicationOwners: [ApplicationOwner],
    ApplicationOwnerRepresentatives: ApplicationOwnerRepresentative,
    IsPaperVersion: Array.isArray(valuesFormStep1?.IsPaperVersion)
      ? valuesFormStep1?.IsPaperVersion?.join()
      : valuesFormStep1?.IsPaperVersion,
    Image: valuesFormStep1?.Image,
    //Đổi biến
    OrganizationgeographicalIndications: [Organization],
  }
}

//1.005323 - Thủ tục đăng ký kiểu dáng công nghiệp (3)

export const convertDataDetailFor1_005323 = (dataRes, ProfileOrigin) => {
  return {
    ...dataRes?.ApplicationOwnerRepresentative[0],
    ...dataRes,
    ApplicationDateNo: dataRes?.ApplicationDateNo
      ? dayjs(dataRes?.ApplicationDateNo)
      : undefined,
    CreateDate: undefined,
    DateApplication: dataRes?.DateApplication
      ? dayjs(dataRes?.DateApplication)
      : undefined,
    DatePublication: undefined,
    DigitalConversionUnitDate: dataRes?.DigitalConversionUnitDate
      ? dayjs(dataRes?.DigitalConversionUnitDate)
      : undefined,
    InternationalFilingDate: dataRes?.InternationalFilingDate
      ? dayjs(dataRes?.InternationalFilingDate)
      : undefined,
    InternationalPublicationDay: dataRes?.InternationalPublicationDay
      ? dayjs(dataRes?.InternationalPublicationDay)
      : undefined,
    SelectedDateVN: dataRes?.SelectedDateVN
      ? dayjs(dataRes?.SelectedDateVN)
      : undefined,
    SingleSeparationNoDate: dataRes?.SingleSeparationNoDate
      ? dayjs(dataRes?.SingleSeparationNoDate)
      : undefined,
    ...ProfileOrigin,
  }
}

export const convertBodyStep1For1_005323 = ({
  dossierID,
  checkbox,
  valuesFormStep1,
  ApplicationOwnerRepresentative,
  Priority,
  Author,
  ProfileOrigin,
  Modifications,
  ApplicationOwner,
}) => {
  return {
    DossierID: dossierID ? dossierID : undefined,
    ...checkbox,
    ...valuesFormStep1,
    IsFirstOrigin: checkbox?.IsFirstOrigin,
    // Description: valuesFormStep1?.Description,
    MonthIsJuggement: +valuesFormStep1.MonthIsJuggement,
    IsPatentRights: checkbox?.IsPatentRights || 1,
    ValidDecision: !!checkbox?.ProfileOrigin3
      ? checkbox?.ValidDecision || 1
      : undefined,
    RequestPriorityRight: checkbox?.RequestPriorityRight,
    IsClassify: !!checkbox?.IsClassify,
    SingleSeparationNoDate: valuesFormStep1?.SingleSeparationNoDate?.format(),
    DigitalConversionUnitDate:
      valuesFormStep1?.DigitalConversionUnitDate?.format(),
    InternationalFilingDate: valuesFormStep1?.InternationalFilingDate
      ? valuesFormStep1?.InternationalFilingDate.format()
      : undefined,
    InternationalPublicationDay: valuesFormStep1?.InternationalPublicationDay
      ? valuesFormStep1?.InternationalPublicationDay.format()
      : undefined,
    SelectedDateVN: valuesFormStep1?.SelectedDateVN
      ? valuesFormStep1?.SelectedDateVN.format()
      : undefined,

    ApplicationOwners: ApplicationOwner,
    Authors: Author,
    ApplicationOwnerRepresentatives: ApplicationOwnerRepresentative?.length
      ? ApplicationOwnerRepresentative
      : undefined,
    Priorities: Priority?.length ? Priority : undefined,
    ProfileOrigin: ProfileOrigin?.length ? ProfileOrigin?.join() : "",
    NameFacility: checkbox?.ProfileOrigin4 ? valuesFormStep1?.NameFacility : "",
    NameMission: checkbox?.ProfileOrigin4 ? valuesFormStep1?.NameMission : "",
    CodeMission: checkbox?.ProfileOrigin4 ? valuesFormStep1?.CodeMission : "",
    IsPaperVersion: Array.isArray(valuesFormStep1?.IsPaperVersion)
      ? valuesFormStep1?.IsPaperVersion?.join()
      : valuesFormStep1?.IsPaperVersion,

    Modifications: Modifications,
    IndependentProtectionPoint: valuesFormStep1?.IndependentProtectionPoint,
    NumberPCT: valuesFormStep1?.NumberPCT
      ? `${valuesFormStep1?.NumberPCT}`
      : undefined,
    SingleSeparationNo: valuesFormStep1?.SingleSeparationNo
      ? `${valuesFormStep1?.SingleSeparationNo}`
      : undefined,
    DigitalConversionUnit: valuesFormStep1?.DigitalConversionUnit
      ? `${valuesFormStep1?.DigitalConversionUnit}`
      : undefined,
    ApplicationDateNo: valuesFormStep1?.ApplicationDateNo
      ? valuesFormStep1?.ApplicationDateNo?.format()
      : undefined,
    DateApplication: valuesFormStep1?.DateApplication
      ? valuesFormStep1?.DateApplication?.format()
      : undefined,
    ...dataUndefine,
  }
}

//2.002126 - Thủ tục đăng ký nhãn hiệu (4)
export const convertBodyStep1For2_002126 = ({
  checkbox,
  valuesFormStep1,
  ApplicationOwnerRepresentatives,
  Priorities,
  ProfileOrigin,
  ApplicationOwners,
}) => {
  return {
    ...checkbox,
    BrandType: valuesFormStep1?.BrandType,
    ApplicationCodeForDeclaration:
      valuesFormStep1?.ApplicationCodeForDeclaration,
    BrandColor: valuesFormStep1?.BrandColor ? valuesFormStep1?.BrandColor : "",
    SingleSeparationNo: valuesFormStep1?.SingleSeparationNo,
    BrandDescription: valuesFormStep1?.BrandDescription,
    RequestPriorityRight: checkbox?.RequestPriorityRight,
    ApplicationOwners: ApplicationOwners,
    ApplicationOwnerRepresentatives: ApplicationOwnerRepresentatives?.length
      ? ApplicationOwnerRepresentatives
      : undefined,
    Prioritize: valuesFormStep1?.Prioritize,
    Priorities: Priorities?.length ? Priorities : undefined,
    IsPaperVersion: Array.isArray(valuesFormStep1?.IsPaperVersion)
      ? valuesFormStep1?.IsPaperVersion?.join(",")
      : valuesFormStep1?.IsPaperVersion,
    DateApplication: valuesFormStep1?.DateApplication
      ? valuesFormStep1?.DateApplication?.format()
      : undefined,
    ProfileOrigin: ProfileOrigin?.length ? ProfileOrigin?.join() : undefined,
    Image: valuesFormStep1?.Image,
  }
}

// 1.005267 - Thủ tục đăng ký thiết kế bố trí mạch tích hợp bán dẫn (7)

export const convertDataDetailFor1_005267 = (dataRes, ProfileOrigin) => {
  return {
    ...dataRes?.ApplicationOwnerRepresentative[0],
    ...dataRes,
    Description: dataRes?.Description,
    ApplicationDateNo: dataRes?.ApplicationDateNo
      ? dayjs(dataRes?.ApplicationDateNo)
      : undefined,
    CreateDate: undefined,
    DateApplication: dataRes?.DateApplication
      ? dayjs(dataRes?.DateApplication)
      : undefined,
    DatePublication: dataRes?.DatePublication
      ? dayjs(dataRes?.DatePublication)
      : undefined,
    DigitalConversionUnitDate: dataRes?.DigitalConversionUnitDate
      ? dayjs(dataRes?.DigitalConversionUnitDate)
      : undefined,
    InternationalFilingDate: dataRes?.InternationalFilingDate
      ? dayjs(dataRes?.InternationalFilingDate)
      : undefined,
    InternationalPublicationDay: dataRes?.InternationalPublicationDay
      ? dayjs(dataRes?.InternationalPublicationDay)
      : undefined,
    SingleSeparationNoDate: dataRes?.SingleSeparationNoDate
      ? dayjs(dataRes?.SingleSeparationNoDate)
      : undefined,
    ...ProfileOrigin,
  }
}
export const convertBodyStep1For1_005267 = ({
  dossierID,
  checkbox,
  valuesFormStep1,
  ApplicationOwnerRepresentative,
  Priority,
  Author,
  ProfileOrigin,
  Modifications,
  ApplicationOwner,
}) => ({
  DossierID: dossierID ? dossierID : undefined,
  ...checkbox,
  ...valuesFormStep1,
  Function: Array.isArray(checkbox?.Function)
    ? checkbox?.Function?.join()
    : checkbox?.Function,
  Structure: Array.isArray(checkbox?.Structure)
    ? checkbox?.Structure?.join()
    : checkbox?.Structure,
  Technology: Array.isArray(checkbox?.Technology)
    ? checkbox?.Technology?.join()
    : checkbox?.Technology,
  DatePublication: valuesFormStep1?.DatePublication?.format(),
  IsPatentRights: checkbox?.IsPatentRights || 1,
  ValidDecision: !!checkbox?.ProfileOrigin3
    ? checkbox?.ValidDecision || 1
    : undefined,
  RequestPriorityRight: checkbox?.RequestPriorityRight,
  IsClassify: !!checkbox?.IsClassify,
  SingleSeparationNoDate: valuesFormStep1?.SingleSeparationNoDate?.format(),
  DigitalConversionUnitDate:
    valuesFormStep1?.DigitalConversionUnitDate?.format(),
  InternationalFilingDate: valuesFormStep1?.InternationalFilingDate
    ? valuesFormStep1?.InternationalFilingDate.format()
    : undefined,
  InternationalPublicationDay: !!valuesFormStep1?.InternationalPublicationDay
    ? valuesFormStep1?.InternationalPublicationDay.format()
    : undefined,
  ApplicationOwners: ApplicationOwner,
  Authors: Author,
  ApplicationOwnerRepresentatives: ApplicationOwnerRepresentative?.length
    ? ApplicationOwnerRepresentative
    : undefined,
  Priority: Priority?.length ? Priority : undefined,
  ProfileOrigin: ProfileOrigin?.length ? ProfileOrigin?.join() : undefined,
  IsPaperVersion: Array.isArray(valuesFormStep1?.IsPaperVersion)
    ? valuesFormStep1?.IsPaperVersion?.join()
    : valuesFormStep1?.IsPaperVersion,

  Modifications: Modifications,
  IndependentProtectionPoint: valuesFormStep1?.IndependentProtectionPoint,
  NumberPCT: valuesFormStep1?.NumberPCT
    ? `PCT/${valuesFormStep1?.NumberPCT}`
    : undefined,
  SingleSeparationNo: valuesFormStep1?.SingleSeparationNo
    ? `${checkbox?.IsPatentRights === 2 ? "2-" : "1-"}${
        valuesFormStep1?.SingleSeparationNo
      }`
    : undefined,
  DigitalConversionUnit: valuesFormStep1?.DigitalConversionUnit
    ? `${checkbox?.IsPatentRights === 2 ? "1-" : "2-"}${
        valuesFormStep1?.DigitalConversionUnit
      }`
    : undefined,
  ApplicationDateNo: valuesFormStep1?.ApplicationDateNo
    ? valuesFormStep1?.ApplicationDateNo?.format()
    : undefined,
  DateApplication: valuesFormStep1?.DateApplication
    ? valuesFormStep1?.DateApplication?.format()
    : undefined,
  ...dataUndefine,
})

//1.011.898
export const convertBodyFor1_011898 = ({
  values,
  dossierID,
  ApplicationOwner,
  ApplicationOwnerRepresentative,
  checkbox,
  Document,
}) => {
  return {
    ...values,
    DossierID: dossierID ? dossierID : undefined,
    MedicineDateRange: values?.MedicineDateRange
      ? values?.MedicineDateRange.format()
      : undefined,
    MedicineExpirationDate: values?.MedicineExpirationDate
      ? values?.MedicineExpirationDate.format()
      : undefined,
    InventProtectionCertificateDate: values?.InventProtectionCertificateDate
      ? values?.InventProtectionCertificateDate.format()
      : undefined,
    MedicineDelayTime: values?.MedicineDelayTime
      ? values?.MedicineDelayTime.format()
      : undefined,
    InventLatestFeePaymentDeadline: values?.InventLatestFeePaymentDeadline
      ? values?.InventLatestFeePaymentDeadline.format()
      : undefined,
    Document: Document,
    ApplicationOwners: ApplicationOwner,
    ApplicationOwnerRepresentatives: ApplicationOwnerRepresentative,
    // ExtensionMaintenance: ExtensionMaintenance,
    ...checkbox,
    ...dataUndefine,
  }
}
export const convertDataDetailFor1_011898 = ({
  data,
  owner,
  ApplicationOwnerRepresentative,
}) => {
  return {
    ...data,
    ...owner,
    ...ApplicationOwnerRepresentative,
    MedicineDateRange: data?.MedicineDateRange
      ? dayjs(data?.MedicineDateRange)
      : null,
    MedicineExpirationDate: data?.MedicineExpirationDate
      ? dayjs(data?.MedicineExpirationDate)
      : null,
    InventProtectionCertificateDate: data?.InventProtectionCertificateDate
      ? dayjs(data?.InventProtectionCertificateDate)
      : null,
    MedicineDelayTime: data?.MedicineDelayTime
      ? dayjs(data?.MedicineDelayTime)
      : null,
    InventLatestFeePaymentDeadline: data?.InventLatestFeePaymentDeadline
      ? dayjs(data?.InventLatestFeePaymentDeadline)
      : null,
  }
}

//1.011.907_08
export const convertBodyFor1_011_907_08 = ({
  values,
  Document,
  ApplicationOwner,
  ApplicationOwnerRepresentative,
  dossierDetail,
  ExtensionMaintenance,
  checkbox,
  typeForm,
}) => {
  return {
    ...values,
    InternationalFilingDate: values?.InternationalFilingDate
      ? values?.InternationalFilingDate.format()
      : undefined,
    InternationalPublicationDay: values?.InternationalPublicationDay
      ? values?.InternationalPublicationDay.format()
      : undefined,
    SelectedDateVN: values?.SelectedDateVN
      ? values?.SelectedDateVN.format()
      : undefined,
    Document: Document,

    ApplicationOwners: ApplicationOwner,
    ApplicationOwnerRepresentatives: ApplicationOwnerRepresentative,
    // ExtensionMaintenance: ExtensionMaintenance,
    Extends: ExtensionMaintenance[0],
    ...checkbox,
    ...dataUndefine,
    DossierCateID: typeForm?.DossierCateID || dossierDetail?.DossierCateID,
  }
}

//1.011.909_10
export const convertBodyStep1For1_011_909_10 = ({
  checkbox,
  dossierID,
  VBBHNumber,
  values,
  ApplicationOwner,
  ApplicationOwnerRepresentative,
  dataLength,
}) => {
  return {
    ...checkbox,
    DossierID: dossierID?.DossierID,
    VBBHNumber: VBBHNumber,
    ReissueReason: values.ReissueReason,
    ApplicationCodeForDeclaration: values.ApplicationCodeForDeclaration,
    ApplicationOwners: ApplicationOwner,
    ApplicationOwnerRepresentatives: ApplicationOwnerRepresentative,
    TypeRequestPerson: Array.isArray(values?.TypeRequestPerson)
      ? values?.TypeRequestPerson?.join()
      : values?.TypeRequestPerson,
    lengthData: dataLength,
    HinhThuc: checkbox?.CancelContent === 1 ? true : false,
    IsChuVBBH: checkbox?.TypeRequestPerson === 1 ? true : false,
  }
}

//1.003966
export const convertBodyStep1For1_003966 = ({
  dossierID,
  checkbox,
  values,
  ApplicationOwnerRepresentative,
  ProfileOrigin,
  ApplicationOwner,
  Author,
  Modifications,
  Priority,
}) => {
  return {
    DossierID: dossierID ? dossierID : undefined,
    ...checkbox,
    ...values,
    Dear: checkbox?.Dear,
    DecisionNumber: +values?.DecisionNumber,
    DonDangKyRelate: values?.DonDangKyRelate?.toString(),
    VBBHNumber: values?.VBBHNumber?.toString(),
    DecisionDate: values?.DecisionDate
      ? values?.DecisionDate?.format()
      : undefined,
    RelateNumber: checkbox?.RelateNumber,
    TimeTransfer: values?.TimeTransfer?.format(),
    DatePublication: values?.DatePublication?.format(),
    IsPatentRights: checkbox?.IsPatentRights || 1,
    ValidDecision: !!checkbox?.ProfileOrigin3
      ? checkbox?.ValidDecision || 1
      : undefined,
    RequestPriorityRight: checkbox?.RequestPriorityRight,
    IsClassify: !!checkbox?.IsClassify,
    SingleSeparationNoDate: values?.SingleSeparationNoDate?.format(),
    DigitalConversionUnitDate: values?.DigitalConversionUnitDate?.format(),
    InternationalFilingDate: values?.InternationalFilingDate
      ? values?.InternationalFilingDate.format()
      : undefined,
    InternationalPublicationDay: values?.InternationalPublicationDay
      ? values?.InternationalPublicationDay.format()
      : undefined,
    SelectedDateVN: values?.SelectedDateVN
      ? values?.SelectedDateVN.format()
      : undefined,

    ApplicationOwners: ApplicationOwner,
    Author: Author,
    ApplicationOwnerRepresentatives: ApplicationOwnerRepresentative?.length
      ? ApplicationOwnerRepresentative
      : undefined,
    Priority: Priority?.length ? Priority : undefined,
    ProfileOrigin: ProfileOrigin?.length ? ProfileOrigin?.join() : undefined,
    Modifications: Modifications,
    IndependentProtectionPoint: values?.IndependentProtectionPoint,
    ApplicationDateNo: values?.ApplicationDateNo
      ? values?.ApplicationDateNo?.format()
      : undefined,
    DateApplication: values?.DateApplication
      ? values?.DateApplication?.format()
      : undefined,
    ...dataUndefine,
  }
}
