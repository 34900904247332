import { Form, Tabs } from "antd"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import Notice from "src/components/Notice"
import DossierService from "src/services/DossierService"
import Documents from "../0.Common/components/Documents"
import Fees from "../0.Common/components/Fees"
import ListButtonForm from "../0.Common/components/ListButtonForm"
import { money } from "../0.Common/data/data"
import {
  convertDoc,
  convertFee,
  convertOwner,
  convertPriority,
  convertRemoveNulls,
  dataUndefine,
  fileListWithView,
  ownerWithView,
  removeDuplicatesByDocumentID,
  saveDossierBody1_PatentRegistration,
} from "../0.Common/functions/convertFunction"
import {
  handleFormAffterSave,
  handleUploadFileDocument,
  validateStepUploadDocument,
} from "../0.Common/functions/documentHandleFunction"
import {
  checkRuleForm,
  getListDataDefaltDoc,
} from "../0.Common/functions/utils"
import {
  DeclareStyle,
  PatentRegistrationChildBorder,
  StylesTabPattern,
} from "../1.PatentRegistration/style"
import DeclarationPatent from "./components/DeclarationPatent"
import { StoreContext } from "src/lib/store"
import CheckLoadingDocument from "../0.Common/components/CheckLoadingDocument"

const RegisterIndustrialIndustrial = ({ isEdit, onCancel, onOk }) => {
  const { uploadListFileStore } = useContext(StoreContext)
  const dispatch = useDispatch()
  const location = useLocation()
  const { listDossier } = useSelector(state => state.dossier)
  const [typeForm, setTypeForm] = useState({})
  const [loading, setLoading] = useState(false)
  const [deleteFileID, setDeleteFileID] = useState([])
  const [dossierID, setDossierID] = useState()
  const [dossierLocal, setDossierLocal] = useState()
  const [activeKey, setActiveKey] = useState(1)
  const navigate = useNavigate()
  const [checkbox, setCheckbox] = useState({ checkboxdefau: "" })
  const [ownersOrther, setOwnersOrther] = useState([])
  const [form] = Form.useForm()
  const [formFile] = Form.useForm()
  const { userInfo } = useSelector(state => state?.appGlobal)
  const [dataDefaltDoc, setDataDefaltDoc] = useState({ Data: [] })
  const [dossierDetail, setDossierDetail] = useState({})
  const [infoFile, setInfoFile] = useState([])
  const [dataPrio, setDataPrio] = useState([])
  const [dataMoney, setDataMoney] = useState(money)

  useEffect(() => {
    if (typeForm?.DossierCateID) {
      checkRuleForm({
        DossierCateID: typeForm?.DossierCateID,
        setLoading,
        navigate,
      })
      getListDataDefaltDoc({
        DossierCateID: typeForm?.DossierCateID,
        Documents: [],
        setDataDefaltDoc,
        setLoading,
      })
    }
  }, [typeForm])
  useEffect(() => {
    if (isEdit) setDossierID(pre => ({ ...pre, DossierID: isEdit }))
  }, [isEdit])
  useEffect(() => {
    if (listDossier?.length)
      setTypeForm(pre =>
        listDossier?.find(item => item?.Router === location?.pathname),
      )
  }, [location, listDossier])

  const getDetails = async () => {
    try {
      setLoading(true)
      const res = await DossierService.getInforDossier({
        DossierID: dossierID?.DossierID,
      })
      if (res?.isError) return
      const dataRes = convertRemoveNulls(res?.Object || {})
      setDossierDetail(dataRes)
      setDataPrio(
        dataRes?.SubjectNames.map((i, key) => ({
          SubjectName: i,
          id: key,
        })),
      )
      const data = {
        ...dataRes,
        // ...dataPrios,
        IsTransfer: res?.Object?.IsTransfer,
      }
      const dataOwner = data?.ApplicationOwner?.find(
        item => item?.IsMasterProfile,
      )
      const owner = ownerWithView(dataOwner)
      setCheckbox({
        ...data,
        ...owner,
      })
      const Doc = convertDoc(data, setDossierLocal)

      getListDataDefaltDoc({
        DossierCateID: dataRes?.DossierCateID,
        Documents: Doc,
        setDataDefaltDoc,
        setLoading,
      })
      let fileListConvert = fileListWithView({
        formFile,
        Doc,
        setCheckbox,
        // haveFirstCopy: true,
        haveOriginalNumber: true,
      })

      form.setFieldsValue({
        ...data,
        ...owner,
        ...fileListConvert,
      })
    } finally {
      setLoading(false)
    }
  }
  const { listFormUploadFile } = useSelector(state => state.uploadFileList)
  useEffect(() => {
    if (!!dossierID?.DossierID && !listFormUploadFile[dossierID?.DossierID])
      getDetails()
  }, [dossierID, listFormUploadFile])

  const validateStep1 = async IsDraft => {
    //Lưu tờ khai
    const values = !!IsDraft
      ? await form.getFieldValue()
      : await form.validateFields()
    const ApplicationOwner = convertOwner(values, checkbox)

    let Priority = []
    if (!!checkbox?.RequestPriorityRight) {
      Priority = convertPriority(dataPrio)
      if (!Priority) {
        Notice({
          msg: "Chỉ dẫn về đơn(các đơn) chưa được nhập đủ",
          isSuccess: false,
        })
        return
      }
    }
    const body = {
      DossierID: dossierID ? dossierID.DossierID : undefined,
      ...checkbox,
      ...values,
      // Dear: values.Dear,
      SubjectCate: checkbox?.SubjectCate,
      SubjectName: dataPrio.map(i => i.SubjectName).toString(),
      ApplicationOwners: ApplicationOwner,
      ...dataUndefine,
      lengthData: dataPrio?.filter(i => !!i.SubjectName)?.length,
    }
    setDossierLocal(pre => ({ ...pre, ...body }))
    if (!!IsDraft) {
      await validateStep2({ ...dossierLocal, ...body }, true)
    } else setActiveKey(2)
  }

  const validateStep2 = async (bodyvali1, IsDraft) => {
    await validateStepUploadDocument({
      dispatch,
      dossierID,
      bodyvali1: bodyvali1,
      IsDraft: IsDraft,
      dossierLocal,
      formFile,
      dataDefaltDoc,
      dossierDetail,
      isEdit,
      checkbox,
      setDossierLocal,
      saveDossier,
      setActiveKey,
    })
  }
  const saveDossier = async (body2, IsDraft) => {
    try {
      setLoading(true)
      const dossier = !!IsDraft
        ? !!body2
          ? body2
          : { ...dossierLocal, ...body2 }
        : { ...dossierLocal, ...body2 }
      const Fee = convertFee(dataMoney?.Data)
      const Documents = dossier?.Document?.map(item => ({
        ...item,
        File: undefined,
      }))
      const notDuplicatesDoc = removeDuplicatesByDocumentID(Documents)
      const { Document, ...remainingDossier } = dossier
      const body = saveDossierBody1_PatentRegistration({
        dossier: remainingDossier,
        Fee,
        dataMoney,
        typeForm,
        dossierDetail,
        IsDraft,
        notDuplicatesDoc,
        ListDocDelete: dossier?.ListDocDelete,
      })
      const res = dossierID
        ? await DossierService.updateDossier30(body)
        : await DossierService.insertDossier30(body)
      if (!!res.isError) return
      const listFile = dossier?.Document
      const listFileID = res?.Object?.Document
      //Duyệt danh sách các file doc, file nào có uid thì tải lên
      await handleUploadFileDocument({
        responseDossier: res?.Object,
        uploadListFileStore,
        listFileID,
        listFile,
        deleteFileID,
        dispatch,
      })
      //Xử lý sau khi lưu
      handleFormAffterSave({
        IsDraft,
        isEdit,
        onCancel,
        onOk,
        navigate,
        setDossierID,
        res,
      })
    } finally {
      setLoading(false)
    }
  }
  const items = [
    {
      key: 1,
      // disabled: true,
      forceRender: true,
      label: (
        <div>
          <b>Bước 1:</b> Điền tờ khai
        </div>
      ),
      children: (
        <PatentRegistrationChildBorder>
          <DeclarationPatent
            form={form}
            checkbox={checkbox}
            setCheckbox={setCheckbox}
            ownersOrther={ownersOrther}
            setOwnersOrther={setOwnersOrther}
            dataSource={dataPrio}
            setDataSource={setDataPrio}
            userInfo={userInfo}
            isEdit={isEdit}
          />
        </PatentRegistrationChildBorder>
      ),
    },
    {
      key: 2,
      disabled: true,
      forceRender: true,
      label: (
        <div>
          <b>Bước 2:</b> Nhập các tài liệu có trong đơn
        </div>
      ),
      children: (
        <PatentRegistrationChildBorder>
          <Documents
            form={formFile}
            dataDefalt={dataDefaltDoc}
            setDataDefalt={setDataDefaltDoc}
            setDeleteFileID={setDeleteFileID}
            infoFile={infoFile}
            setInfoFile={setInfoFile}
            checkbox={checkbox}
            setCheckbox={setCheckbox}
          />
        </PatentRegistrationChildBorder>
      ),
    },
    {
      key: 3,
      disabled: true,
      label: (
        <div>
          <b>Bước 3:</b> Xem phí lệ phí
        </div>
      ),
      forceRender: true,
      children: (
        <PatentRegistrationChildBorder>
          <Fees
            dataMoney={dataMoney}
            setDataMoney={setDataMoney}
            dossierLocal={dossierLocal}
            setLoading={setLoading}
            typeForm={typeForm}
            dataDefaltDoc={dataDefaltDoc}
            DossierCateCode={"1.011930"}
            checkbox={checkbox}
            formFile={formFile}
            infoFile={infoFile}
          />
        </PatentRegistrationChildBorder>
      ),
    },
  ]
  return (
    <DeclareStyle>
      <StylesTabPattern isEdit={!!isEdit}>
        <CheckLoadingDocument
          activeKey={activeKey}
          handleFormAffterSaveDoc={async () => {
            if (activeKey !== 3) await getDetails()
          }}
          dossierID={dossierID}
          setActiveKey={() => setActiveKey(3)}
        />
        <Tabs
          type="card"
          defaultActiveKey="1"
          items={items}
          activeKey={activeKey}
          tabBarExtraContent={
            <ListButtonForm
              dossierID={dossierID}
              loading={loading}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              validateStep1={validateStep1}
              validateStep2={validateStep2}
              saveDossier={saveDossier}
              dossierLocal={dossierLocal}
            />
          }
          onChange={key => {
            if (activeKey < key) {
              if (key === 1) {
                setActiveKey(key)
              } else if (key === 2) {
                validateStep1(false)
              } else if (key === 3) {
                validateStep1(false)
                validateStep2(false, false)
              }
            } else setActiveKey(key)
          }}
        />
      </StylesTabPattern>
    </DeclareStyle>
  )
}

export default RegisterIndustrialIndustrial
