import { useEffect, useState } from "react"
import TableCustom from "src/components/Table/CustomTable"
import { formatMoney } from "src/lib/utils"
import { apiFee, convertMoneyFee } from "../functions/FeesBody"

const Fees = ({
  dataMoney,
  setDataMoney,
  dossierLocal,
  typeForm,
  dataDefaltDoc,
  DossierCateCode,
  checkbox,
  formFile,
  infoFile,
  setLoading = () => {},
}) => {
  const [money, setMoney] = useState([])
  const [hinhcongbo, sethinhcongbo] = useState(0)
  const sharedOnCell = (_, index) => {
    if (index === money?.length - 1) {
      return {
        colSpan: 0,
      }
    }
    return {}
  }
  const tinhPhiNopDon = async () => {
    try {
      //Lấy body api
      setLoading(true)
      //Lấy tên api và service
      const api = apiFee(DossierCateCode)
      if (!api) return
      const body = convertMoneyFee(
        typeForm,
        dataDefaltDoc,
        checkbox,
        formFile,
        infoFile,
        DossierCateCode,
        dossierLocal,
      )

      //Gọi api và truyền body
      const res = await api?.ServiceName[api?.apiFeeName](body)
      if (res?.isError) return

      setDataMoney({
        ...res?.Object,
        FeesCharge: {
          FeesCharges: {
            ...body,
            Type: undefined,
          },
          Type: api?.apiFeeName[0].toUpperCase() + api?.apiFeeName?.slice(1),
        },
      })
      const moneyStart = {
        id: 12,
        feeType: "",
        level: "",
        quality: "",
        money: "",
      }
      if (res?.Object?.Data?.length)
        setMoney([...res?.Object?.Data?.filter(i => !!i.Quantity), moneyStart])
      else setMoney([moneyStart])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (dossierLocal) {
      tinhPhiNopDon()
    }
  }, [dossierLocal])

  useEffect(() => {
    sethinhcongbo(
      dataDefaltDoc?.Data?.filter(item => +item?.ParentID === 2)?.length,
    )
  }, [dataDefaltDoc?.Data])

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      width: 70,
      render: (value, record, idx) => {
        if (idx === money?.length - 1) {
          return (
            <div className="d-flex-sb">
              <div>
                <b>
                  <i>Tổng số phí, lệ phí nộp theo đơn là:</i>
                </b>
              </div>
              <div style={{ textAlign: "right" }} className="fw-600">
                {dataMoney?.TotalFee ? formatMoney(dataMoney?.TotalFee) : 0}đ
              </div>
            </div>
          )
        } else return <div className="text-center">{idx + 1}</div>
      },
      align: "center",
      onCell: (_, index) => ({
        colSpan: index === money?.length - 1 ? 5 : 1,
      }),
    },
    {
      title: "Loại phí, lệ phí",
      dataIndex: "FeeName",
      key: "FeeName",
      onCell: sharedOnCell,
      render: (val, record, idx) => <div className="text-center">{val}</div>,
    },
    {
      title: "Mức thu",
      dataIndex: "Fee",
      key: "Fee",
      width: 200,
      onCell: sharedOnCell,
      render: (value, record, idx) => (
        <div style={{ textAlign: "right" }}>
          {value ? formatMoney(value) : 0} đ
        </div>
      ),
    },
    {
      title: "Số lượng",
      dataIndex: "Quantity",
      key: "Quantity",
      onCell: sharedOnCell,
      render: (val, record, idx) => <div className="text-center">{val}</div>,
    },
    {
      title: "Số tiền",
      dataIndex: "Total",
      key: "Total",
      width: 200,
      onCell: sharedOnCell,
      render: (value, record, idx) => (
        <div style={{ textAlign: "right" }}>
          {value ? formatMoney(value) : 0} đ
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="p-16">
        {!!hinhcongbo ? (
          <div>
            <b>Số hình công bố:</b> {hinhcongbo || 0}
          </div>
        ) : (
          <></>
        )}
        <TableCustom
          dataSource={money}
          isPrimary
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      </div>
    </>
  )
}

export default Fees
