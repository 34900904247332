import { Col, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import TableCustom from "src/components/Table/CustomTable"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import { EditableCell, EditableRow } from "./component/EditRowTable"

const Instruction = ({ dataSource, setDataSource, checkbox }) => {
  const [error, setError] = useState(false)
  const { listSystemKey } = useSelector(state => state.appGlobal)
  useEffect(() => {
    let err = false
    //Bắt buộc số đơn với nước nộp đơn
    dataSource?.forEach(item => {
      if (!item?.number || !item?.country) {
        err = true
      }
    })
    setError(pre => err)
  }, [dataSource])

  //Thêm row
  const handleAdd = () => {
    const uniqueId = Date.now().toString()
    setDataSource(pre => {
      return [
        ...pre,
        {
          key: uniqueId,
        },
      ]
    })
  }
  //Input trong ô
  const handleSave = row => {
    const newData = [...dataSource]
    const index = newData.findIndex(item => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
      // date: moment(),
    })
    setDataSource(newData)
  }
  const handleSave3 = (row, SubjectName) => {
    const newData = [...dataSource]
    const index = newData.findIndex(item => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
      SubjectName: SubjectName,
    })
    setDataSource(newData)
  }
  //Xóa row
  const handleDelete = key => {
    const newData = dataSource.filter(item => item.key !== key)
    setDataSource(newData)
  }
  const defaultColumns = [
    {
      title: "Môn số",
      dataIndex: "id",
      width: "5%",
      align: "center",
      render: (text, row, idx) => <div className="text-center">{idx + 1}</div>,
    },
    {
      title: `Tên môn`,
      dataIndex: "SubjectName",
      key: "SubjectName",
      align: "center",
      width: "70%",
      // editable: true,
      render: (value, record, idx) => (
        <Select
          value={value}
          placeholder="Chọn"
          className="input-border-bottom-only"
          onChange={value => handleSave3(record, value)}
          style={{ width: "100%" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getListComboByKey(
            SYSTEM_KEY?.CAC_MON_THUOC_CHUYEN_NGANH_GIAM_DINH_DANG_KY,
            listSystemKey,
          )?.map(item => (
            <Select.Option value={+item?.CodeValue} key={+item?.CodeValue}>
              {item?.Description}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Xoá",
      dataIndex: "id",
      width: "7%",
      align: "center",
      render: (value, record, idx) => (
        <div className="d-flex-center">
          <ButtonCircle
            title="Xóa"
            iconName="delete-outline"
            onClick={() => {
              handleDelete(record.key)
            }}
          />
        </div>
      ),
    },
  ]
  //Cột mà bảng sẽ nhận
  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  return (
    <Row>
      <Col
        span={24}
        className={
          !!checkbox?.RequestPriorityRight && !!error
            ? "required-input-color"
            : ""
        }
      >
        <TableCustom
          isPrimary
          noMrb
          showPagination
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
          textEmpty="Không có dữ liệu"
          pagination={false}
          rowKey="id"
        />
        <div className="d-flex-end mt-8">
          <Button
            btnType="third"
            iconName="add-file-blue"
            onClick={() => {
              handleAdd()
            }}
          >
            Thêm
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default Instruction
