import React, { useState } from "react"

export const StoreContext = React.createContext(null)
const convertArrayUniqueDataUpload = arr => {
  let uniqueDataUpload = arr.reduce((acc, current) => {
    const checkFileUnique = acc.find(
      item => item.ID === current.ID || item.uid === current.uid,
    )
    if (!checkFileUnique) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])
  return uniqueDataUpload
}
export default function StoreProvider({ children }) {
  const [user, setUser] = useState({})
  const [spvObj, setSpvObj] = useState({})
  const [ucObj, setUcObj] = useState({})
  const [collapsed, setCollapsed] = useState(false)
  const [listTab, setListTab] = useState(null)
  const [tabSelect, setTabSelect] = useState(null)
  const [routerBeforeLogin, setRouterBeforeLogin] = useState()
  const [isModelNotification, setIsModelNotification] = useState(false)
  const [uploadFileList, setUploadFileList] = useState([])
  const [filePdfFromHtml, setFilePdfFromHtml] = useState(false)

  const updateListFileAddFile = newFile => {
    setUploadFileList(pre => [...pre, newFile])
  }
  const updateListFileAddListFile = listFile => {
    setUploadFileList(pre => {
      let startData = pre || []
      let listUpload = [...startData, ...listFile]
      return convertArrayUniqueDataUpload(listUpload)
    })
  }
  const deleteUploadFileList = (ID, reload = false) => {
    if (!!reload) {
      setUploadFileList(pre => {
        return [...pre]
      })
    } else setUploadFileList(pre => pre?.filter(item => item?.ID !== ID))
  }

  const deleteListUploadFileList = ListID => {
    setUploadFileList(pre => pre?.filter(item => !ListID?.includes(item?.ID)))
  }
  const store = {
    pdfFromHtmlStore: [filePdfFromHtml, setFilePdfFromHtml],
    uploadListFileStore: [
      uploadFileList,
      updateListFileAddFile,
      deleteUploadFileList,
      updateListFileAddListFile,
      deleteListUploadFileList,
    ],
    tabStore: [tabSelect, setTabSelect],
    isNotificationUpdate: [isModelNotification, setIsModelNotification],
    userStore: [user, setUser],
    spvStore: [spvObj, setSpvObj],
    ucStore: [ucObj, setUcObj],
    collapsedStore: [collapsed, setCollapsed],
    listTabStore: [listTab, setListTab],
    routerStore: [routerBeforeLogin, setRouterBeforeLogin],
  }
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
