import { LoadingOutlined } from "@ant-design/icons"
import { Space, Spin } from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "src/components/MyButton/Button"
import { addFormUploadFile } from "src/redux/uploadFileList"

const ListButtonForm = ({
  loading,
  activeKey,
  setActiveKey,
  validateStep1,
  validateStep2,
  saveDossier,
  dossierLocal,
  displaySaveInStep2 = false,
  dossierID,
  formName,
}) => {
  const dispatch = useDispatch()
  const { listFormUploadFile } = useSelector(state => state.uploadFileList)
  const checkLoading = () => {
    return !!(
      loading ||
      !!(!!dossierID?.DossierID && !!listFormUploadFile[dossierID?.DossierID])
    )
  }
  return (
    <Space
      size={16}
      className={"pb-8 box-spin"}
      // className={!!isEdit ? "list-btn-fix_top2" : "list-btn-fix_top"}
    >
      <Spin
        className="ml-8"
        wrapperClassName="load-file-spin"
        fullscreen
        // indicator={
        //   <LoadingOutlined
        //     style={{
        //       fontSize: 18,
        //     }}
        //     spin
        //   />
        // }
        spinning={checkLoading()}
      />
      {activeKey > 1 && (
        <Button
          btnType="third"
          loading={checkLoading()}
          onClick={() => setActiveKey(activeKey - 1)}
        >
          Quay lại
        </Button>
      )}

      {activeKey === 1 && (
        <Button
          btnType="primary"
          loading={checkLoading()}
          onClick={() => {
            if (activeKey === 1) validateStep1(true)
            else if (activeKey === 2) validateStep2(false, true)
            else if (activeKey === 3) saveDossier(dossierLocal, true)
          }}
        >
          Lưu nháp
        </Button>
      )}

      {activeKey !== 3 ? (
        <Button
          btnType="primary"
          loading={checkLoading()}
          onClick={() => {
            if (activeKey === 1) validateStep1(false)
            else if (activeKey === 2) {
              //Lưu xong file đã
              // dispatch(addFormUploadFile(dossierID?.DossierID))
              validateStep2(false, "continue")
              // validateStep2(false, false)
            }
          }}
          type="submit"
          htmlType="submit"
          form={formName || "myForm_1"}
        >
          {activeKey === 2 ? "Lưu nháp và Tiếp tục" : "Tiếp tục"}
        </Button>
      ) : (
        <Button
          btnType="primary"
          loading={checkLoading()}
          onClick={async () => {
            // await validateStep2(false, "save")
            console.log("dossierLocal", dossierLocal)
            await saveDossier(dossierLocal, false)
          }}
        >
          Lưu
        </Button>
      )}
      {!!displaySaveInStep2 && (
        <Button
          btnType="primary"
          loading={checkLoading()}
          onClick={() => validateStep2(false, false)}
        >
          Lưu
        </Button>
      )}
    </Space>
  )
}

export default ListButtonForm
