import { configureStore } from "@reduxjs/toolkit"
import appGlobalReducer from "./appGlobal"
import commonReducer from "./common"
import roleReducer from "./role"
import loginModalReducer from "./loginModal"
import dossierReducer from "./dossier"
import regionReducer from "./region"
import paymentReducer from "./payment"
import uploadFileListReducer from "./uploadFileList"

export default configureStore({
  reducer: {
    appGlobal: appGlobalReducer,
    role: roleReducer,
    common: commonReducer,
    loginModal: loginModalReducer,
    dossier: dossierReducer,
    region: regionReducer,
    uploadFileList: uploadFileListReducer,
    payment: paymentReducer,
  },
})
