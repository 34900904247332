export const apiGetListDossierOff = "/DossierOff/GetListDossierOff"
export const apiGetListRepresent = "/DossierOff/GetListRepresent"
export const apiGetListDonVi = "/DossierOff/GetListDonVi"
export const apiTinhPhiDonXacLapQuyen = "DossierOff/TinhPhiDonXacLapQuyen"
export const apiTinhPhiDonXacLapQuyenQuocTe =
  "DossierOff/TinhPhiDonXacLapQuyenQuocTe"
export const apiChangeStatusDossierOff = "DossierOff/ChangeStatusDossierOff"
export const apiSaveFeeDossierOff = "DossierOff/SaveFeeDossierOff"
export const apiGetDetailsByProfileCode = "/DossierOff/GetDetailsByProfileCode"
export const apiGetListHinhThucNop = "/DossierOff/GetListHinhThucNop"
export const apiSuaSoDon = "/DossierOff/SuaSoDon"
export const apiCheckSoDonGoc = "/DossierOff/CheckSoDonGoc"
export const apiTinhPhiDonLienQuanToiDon =
  "/DossierOff/TinhPhiDonLienQuanToiDon"
export const apiTinhPhiCacDonKhac = "/DossierOff/TinhPhiCacDonKhac"
export const apiTinhPhiDonLienQuanToiVanBang =
  "/DossierOff/TinhPhiDonLienQuanToiVanBang"
export const apiLock_UnlockDossier = "/DossierOff/UnLock_Lock"
export const apiGetDataVBBH = "/DossierOff/GetDataVBBH"
export const apiInPhieuBaoThuDonDT = "DossierOff/InPhieuBaoThuDonDT"
export const apiHuyDon = "DossierOff/HuyDon"
