import {
  apiTinhPhiDonXacLapQuyen,
  apiTinhPhiDonXacLapQuyenQuocTe,
  apiChangeStatusDossierOff,
  apiSaveFeeDossierOff,
  apiGetListDonVi,
  apiGetListDossierOff,
  apiGetListRepresent,
  apiGetDetailsByProfileCode,
  apiGetListHinhThucNop,
  apiSuaSoDon,
  apiCheckSoDonGoc,
  apiTinhPhiDonLienQuanToiDon,
  apiTinhPhiCacDonKhac,
  apiTinhPhiDonLienQuanToiVanBang,
  apiLock_UnlockDossier,
  apiGetDataVBBH,
  apiInPhieuBaoThuDonDT,
  apiHuyDon,
} from "./urls"
import http from "../index"
import FeeService from "../FeeService"
// =================================================================
// const tinhPhiDonXacLapQuyen = body => http.post(apiTinhPhiDonXacLapQuyen, body)
const tinhPhiDonXacLapQuyen = body =>
  FeeService.tinhPhi(body, "TinhPhiDonXacLapQuyen")

// const tinhPhiDonXacLapQuyenQuocTe = body =>
//   http.post(apiTinhPhiDonXacLapQuyenQuocTe, body)
const tinhPhiDonXacLapQuyenQuocTe = body =>
  FeeService.tinhPhi(body, "TinhPhiDonXacLapQuyenQuocTe")

// const TinhPhiDonLienQuanToiDon = body =>
//   http.post(apiTinhPhiDonLienQuanToiDon, body)
const TinhPhiDonLienQuanToiDon = body =>
  FeeService.tinhPhi(body, "TinhPhiDonLienQuanToiDon")

//const TinhPhiCacDonKhac = body => http.post(apiTinhPhiCacDonKhac, body)
const TinhPhiCacDonKhac = body => FeeService.tinhPhi(body, "TinhPhiCacDonKhac")

// const TinhPhiDonLienQuanToiVanBang = body =>
//   http.post(apiTinhPhiDonLienQuanToiVanBang, body)
const TinhPhiDonLienQuanToiVanBang = body =>
  FeeService.tinhPhi(body, "TinhPhiDonLienQuanToiVanBang")

const changeStatusDossierOff = body =>
  http.post(apiChangeStatusDossierOff, body)
const saveFeeDossierOff = body => http.post(apiSaveFeeDossierOff, body)
const gettListDossierOff = body => http.post(apiGetListDossierOff, body)
const getListRepresent = () => http.post(apiGetListRepresent)
const getListDonVi = () => http.post(apiGetListDonVi)
const GetDetailsByProfileCode = params =>
  http.get(apiGetDetailsByProfileCode + `?ProfileCode=${params}`)
const getListHinhThucNop = () => http.post(apiGetListHinhThucNop)
const updateSoDon = body => http.post(apiSuaSoDon, body)
const CheckSoDonGoc = body => http.post(apiCheckSoDonGoc, body)
const getDataVBBH = params => http.get(apiGetDataVBBH, { params })
const unlockLockDossier = (status, body) =>
  http.post(apiLock_UnlockDossier + `?status=${status}`, body)
const print_phieu_bao_thu_DT = body => http.post(apiInPhieuBaoThuDonDT, body)
const huyDon = body => http.post(apiHuyDon, body)

const DossierOffService = {
  huyDon,
  tinhPhiDonXacLapQuyen,
  tinhPhiDonXacLapQuyenQuocTe,
  changeStatusDossierOff,
  saveFeeDossierOff,
  gettListDossierOff,
  getListRepresent,
  getListDonVi,
  GetDetailsByProfileCode,
  getListHinhThucNop,
  updateSoDon,
  CheckSoDonGoc,
  TinhPhiDonLienQuanToiDon,
  TinhPhiCacDonKhac,
  TinhPhiDonLienQuanToiVanBang,
  unlockLockDossier,
  getDataVBBH,
  print_phieu_bao_thu_DT,
}

export default DossierOffService
