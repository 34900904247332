import { Form, Input, Space } from "antd"
import React from "react"
import { useEffect } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"

const ModalUpdateDocType = ({ open, onCancel, setDataDefalt }) => {
  const [formModalDocType] = Form.useForm()
  useEffect(() => {
    if (open) {
      formModalDocType.setFieldsValue({
        DocName: open?.DocName,
      })
    }
  }, [open])

  const handle = async () => {
    const value = await formModalDocType.validateFields()
    setDataDefalt(pre => ({
      ...pre,
      Data: [
        ...pre?.Data?.filter(item => !(item?.DocID === open?.DocID)),
        {
          DocID: open?.DocID,
          DocName: value?.DocName,
          ParentID: open?.ParentID,
          Level: open?.Level,
          SortOrder: open?.SortOrder,
          DossierCateID: open?.DossierCateID,
          DossierCateDocID: open?.DossierCateDocID,
          Fee: open?.Fee,
          IsRequired: false,
          IsAdd: true,
        },
      ],
    }))
    onCancel()
  }
  return (
    <CustomModal
      title={"Thêm tài liệu"}
      width="50vw"
      footer={
        <Space className="d-flex-end">
          <Button btnType="primary" onClick={() => handle()}>
            Lưu
          </Button>
        </Space>
      }
      open={open}
      // onOk={handleOk}
      onCancel={onCancel}
    >
      <Form form={formModalDocType}>
        <div>
          <b>Tên tài liệu</b>
        </div>
        <Form.Item
          name="DocName"
          rule={[{ required: true, message: "Vui lòng nhập mật khẩu mới" }]}
        >
          <Input placeholder="Tên tài liệu" />
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default ModalUpdateDocType
