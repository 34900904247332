import { Modal } from "antd"
import STORAGE, { deleteStorage } from "src/lib/storage"
import { saveAs } from "file-saver"

export default function ModalErrorSign() {
  Modal.info({
    // icon: <SvgIcon name="warning-usb" />,
    icon: null,
    okText: "Đã cài đặt",
    className: "modal-error-sign",
    // cancelText,
    onOk: close => {
      close()
      deleteStorage(STORAGE.ERROR_EXTENSION)
    },

    width: 700,
    content: (
      <div>
        <div className="fs-16 fw-600 mb-8">
          Vui lòng đảm bảo rằng đã cài đặt đẩy đủ các ứng dụng hỗ trợ để sử dụng
          chứng từ số trên hệ thống.
        </div>{" "}
        <div>
          Nếu chưa cài đặt ứng dụng vui lòng{" "}
          <span
            style={{ color: "#1677ff" }}
            className="fw-600 pointer"
            onClick={() =>
              saveAs(
                window.env?.TOOL_URL || process.env.TOOL_URL,
                "Extension_tool.rar",
              )
            }
          >
            Tải tại đây
          </span>
        </div>
      </div>
    ),
  })
}
