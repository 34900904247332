import CB1 from "src/components/Modal/CB1"
import ROUTER from "src/router"
import DossierCateService from "src/services/DossierCateService"
import GuestServices from "src/services/GuestService"
import dayjs from "dayjs"
import { Upload, message } from "antd"
import AuthService from "src/services/AuthService"
import { getDocument } from "pdfjs-dist"
import { getListComboByKey } from "src/lib/utils"
import { SYSTEM_KEY, VN_CODE } from "src/constants/constants"

//Check ủy quyền đăng ký tờ khai
export const checkRuleForm = async ({
  DossierCateID,
  setLoading,
  navigate,
}) => {
  try {
    setLoading(true)
    const res = await DossierCateService.getById(DossierCateID)
    if (res?.isError) return
    if (res.Object.IsValid) return
    CB1({
      title: "Bạn không được ủy quyền trong lĩnh vực này!",
      icon: "warning-usb2",
      okText: "Đồng ý",
      cancelText: "Đóng",
      onOk: async close => {
        close()
        navigate(ROUTER.THU_TUC_HANH_CHINH)
      },
      onCancel: async close => {
        close()
        navigate(ROUTER.THU_TUC_HANH_CHINH)
      },
    })
  } finally {
    setLoading(false)
  }
}

//Lấy danh sách tài liệu mặc định
export const getListDataDefaltDoc = async ({
  DossierCateID,
  Documents,
  setDataDefaltDoc,
  setLoading,
}) => {
  try {
    setLoading(true)
    const res = await GuestServices.getDocAttach({
      DossierCateID: DossierCateID,
    })
    if (res.isError) return
    let listDocumentDefault = res?.Object?.Data || []
    //Documents là danh sách các tài liệu đã lưu hoặc lưu nháp
    Documents?.forEach(item => {
      if (listDocumentDefault?.length) {
        // let docItem = listDocumentDefault?.find(i => i?.DocID === item.DocID)
        // listDocumentDefault = [
        //   ...listDocumentDefault?.filter(
        //     itemDoc => itemDoc?.DocID !== docItem?.DocID,
        //   ),
        //   {
        //     ...docItem,
        //     ...item,
        //   },
        // ]
        //lấy index của document trong mảng, nếu không có sẽ trả -1
        let docItemIndex = listDocumentDefault?.findIndex(
          i => i?.DocID === item.DocID,
        )
        let docItem = listDocumentDefault[docItemIndex]

        if (docItemIndex !== -1)
          listDocumentDefault[docItemIndex] = {
            ...docItem,
            ...item,
          }
        else {
          //Lấy cha của item để kế thừa 1 số thuộc tính cần
          let docItemParent =
            listDocumentDefault?.find(i => i?.DocID === item.ParentID) || {}
          listDocumentDefault = [
            ...listDocumentDefault,
            {
              ListFileExtention: docItemParent?.ListFileExtention || [],
              ...item,
            },
          ]
        }
      } else {
        listDocumentDefault = [
          ...listDocumentDefault,
          {
            ...item,
          },
        ]
      }
    })
    setDataDefaltDoc({ Data: listDocumentDefault })
  } finally {
    setLoading(false)
  }
}

export const convertStringDateToDayjs = date => {
  if (!!date) return dayjs(date)
  return undefined
}
export const formatDateToYYYYMMDD = date => {
  if (!!date) return date?.format("YYYY-MM-DD")
  return undefined
}

export const checkIsRequiredDocument = (record, checkbox) => {
  if (
    (!!record?.IsRequired && !record?.IsAddChild) ||
    !!(!!checkbox?.ProfileOrigin2 && record?.Code === "CONG_VAN_TACH_DON")
  )
    return true
  return false
}
export const checkIsRequiredUpfileDocument = (record, checkbox) => {
  if (
    !!record?.IsRequired ||
    !!(!!checkbox?.ProfileOrigin2 && record?.Code === "CONG_VAN_TACH_DON")
  )
    return true
  return false
}
export const checkIsAddChildDocument = record => {
  if (!!record?.IsAddChild || (!!record?.children?.length && record.Level >= 3))
    return true
  return false
}
export const defaultValueOfCheckboxOriginal = (record, checkbox) => {
  if (checkbox[`IsOriginalSubmitted${record?.DocID}`] === 0) return 0
  else if (!!checkbox[`IsOriginalSubmitted${record?.DocID}`]) return 1
  else return undefined
}

export const typeFileAccept = record => {
  if (record?.ListFileExtention?.length)
    return `${record?.ListFileExtention?.join()}`
  return ".pdf"
}

export const checkFilebeforeUpload = async (
  file,
  record,
  setLoading,
  setFile,
  form,
  setNumPagesLoading,
) => {
  const listFile = form.getFieldValue(`${record?.DocID}`)
  //Check giới hạn số lượng tệp được tải lên, nếu ===1 mà đang có file rồi thì không cho up
  if (
    !!(
      (
        (record?.MaxFile ? +record?.MaxFile : 0) > 0 &&
        listFile?.length >= (record?.MaxFile ? +record?.MaxFile : 0)
      )
      //  &&
      // listFile[0]?.ObjectFileID
    )
  ) {
    message.error(
      `Số lượng tệp đính kèm của loại tài liệu này bị giới hạn là ${
        record?.MaxFile ? +record?.MaxFile : 0
      } tệp.`,
    )
    return Upload.LIST_IGNORE
  }

  // check cỡ chữ
  const fileType = file.name.split(".")
  const isFileType = record?.ListFileExtention?.length
    ? record?.ListFileExtention?.map(item => item?.toLowerCase())?.includes(
        `.${fileType[fileType?.length - 1]}`,
      )
    : fileType[fileType?.length - 1].toLowerCase() === "pdf"
  if (!isFileType) {
    message.error(
      `Hãy chọn file ${record?.ListFileExtention?.join() || ".pdf"}`,
    )
    return Upload.LIST_IGNORE
  } else if (fileType[fileType?.length - 1] === "pdf") {
    const fontsize = await getFontSize(file, setLoading)
    if (record?.DocID === 8 && fontsize < 13) {
      message.error(`Cỡ chữ phải lớn hơn hoặc bằng 13`)
      return Upload.LIST_IGNORE
    } else {
      if (record?.IsCheckSigned) {
        const formData = new FormData()
        formData.append("file", file)
        const res = await AuthService.checkPdfSigned(formData)
        if (res.isError) return
        if (!!+res?.Object)
          getPageNum(file, record, setLoading, setFile, setNumPagesLoading)
        if (!+res?.Object) {
          message.error(`Vui lòng ký số tài liệu trước khi gửi lên!`)
          return Upload.LIST_IGNORE
        }
      } else {
        getPageNum(file, record, setLoading, setFile, setNumPagesLoading)
      }
    }
  }

  // check độ phân giải ảnh
  let imageType = false
  if (
    ["png", "svg", "jpg", "jpeg"]?.includes(
      fileType[fileType?.length - 1].toLowerCase(),
    )
  )
    imageType = true
  if (!!imageType) {
    const checkPixelFile = await checkPixel(file, 200, 200)
    if (!checkPixelFile) {
      message.error(`Độ phân giải ảnh không phù hợp!!!`)
      return Upload.LIST_IGNORE
    }
  }

  return false
}

// export const getFontSize = async (file, setLoading) => {
//   try {
//     setLoading(true)
//     const formData = new FormData()
//     formData.append("file", file)
//     const res = await AuthService.getFontSizePdf(formData)
//     return res?.Object
//   } finally {
//     setLoading(false)
//   }
// }

const findMostFrequent = arr => {
  let frequencyMap = {}
  let maxFrequency = 0
  let mostFrequentElement = null
  arr.forEach(element => {
    frequencyMap[element] = (frequencyMap[element] || 0) + 1
    if (frequencyMap[element] > maxFrequency) {
      maxFrequency = frequencyMap[element]
      mostFrequentElement = element
    }
  })
  return mostFrequentElement
}

export const getFontSize = async (file, setLoading) => {
  if (!window.env.CHECK_FONT_SIZE_DOSSIER) return 13
  try {
    setLoading(true)
    let listFontSize = []
    const arrayBuffer = await file.arrayBuffer()
    const pdfData = new Uint8Array(arrayBuffer)
    const pdf = await getDocument({ data: pdfData }).promise
    const numPages = pdf.numPages
    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i)
      const textContent = await page.getTextContent()
      textContent.items.forEach(item => {
        listFontSize.push(Math.floor(item?.height))
      })
    }
    if (!listFontSize.length) {
      return message.error("File tải lên phải có chữ!!")
    }
    const fontSize = findMostFrequent(listFontSize)
    return fontSize
  } catch (error) {
    console.error("Lỗi khi phân tích tệp PDF: ", error)
  } finally {
    setLoading(false)
  }
}

export const getPageNum = async (
  fileUp,
  record,
  setLoading,
  setFile,
  setNumPagesLoading,
) => {
  try {
    setLoading(true)
    // Tạo một URL từ file để sử dụng trong <Document>
    const fileUrl = URL.createObjectURL(fileUp)
    setFile({
      fileUrl,
      fileUp,
      record,
    })
  } finally {
    setLoading(false)
    setNumPagesLoading(pre => ({ ...pre, [record?.DocID]: true }))
  }
}

export const checkPixel = async (file, IMAGE_MAX_WIDTH, IMAGE_MAX_HEIGHT) => {
  return new Promise((resolve, reject) => {
    // var IMAGE_MAX_WIDTH = 11
    // var IMAGE_MAX_HEIGHT = 11
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener("load", event => {
      const loadedImageUrl = event.target?.result
      const image = document.createElement("img")
      image.src = loadedImageUrl

      image.addEventListener("load", imww => {
        const { width, height } = image
        if (width > IMAGE_MAX_WIDTH && height > IMAGE_MAX_HEIGHT) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    })
  })
}

export const removeUploadFileDoc = (
  file,
  record,
  setDeleteFileID,
  setDataDefalt,
) => {
  if (!!file?.ObjectFileID) setDeleteFileID(pre => [...pre, file?.ObjectFileID])
  setDataDefalt(pre => ({
    ...pre,
    Data: pre?.Data?.map(item => {
      if (item?.DocID === record?.DocID) {
        if (item?.FileLoad?.find(i => i?.uid === file?.uid)) {
          return {
            ...item,
            FileLoad: item?.FileLoad?.filter(i => i?.uid !== file?.uid),
          }
        }
        return item
      }
      return item
    }),
  }))
}
export const nestDocumentForm = (items, id, link) => {
  let listFilter = items?.filter(item => item[link] === id)
  if (listFilter.length > 0) {
    return listFilter.map(item => ({
      ...item,
      children: nestDocumentForm(items, item?.DocID, link),
    }))
  } else {
    return null
  }
}

export const onDocumentLoadSuccess = (
  setNumPagesLoading,
  setDataDefalt,
  setInfoFile,
  file,
  { numPages },
) => {
  // setNumPages(pre => numPages)

  setNumPagesLoading(pre => ({ ...pre, [file?.record?.DocID]: false }))
  setDataDefalt(pre => {
    return {
      ...pre,
      Data: pre?.Data?.map(item => {
        if (
          item?.DocID === file?.record?.DocID &&
          !(item?.FileLoad?.length >= file?.record?.MaxFile)
        ) {
          if (item?.FileLoad?.length) {
            return {
              ...item,
              FileLoad: [
                ...item?.FileLoad,
                {
                  uid: file?.fileUp?.uid,
                  NumberPage: numPages,
                  FileName: file?.fileUp?.name,
                },
              ],
            }
          } else {
            return {
              ...item,
              FileLoad: [
                {
                  uid: file?.fileUp?.uid,
                  NumberPage: numPages,
                  FileName: file?.fileUp?.name,
                },
              ],
            }
          }
        }
        return item
      }),
    }
  })
  setInfoFile(pre =>
    pre?.length
      ? [...pre, { uid: file?.fileUp?.uid, NumberPage: numPages }]
      : [{ uid: file?.fileUp?.uid, NumberPage: numPages }],
  )
}
export const checkVNCodeKey = (listSystemCate = [], Country = 0) =>
  getListComboByKey(SYSTEM_KEY?.QUOC_GIA, listSystemCate).find(
    i => i.ID === Country,
  )?.CodeKey === VN_CODE
