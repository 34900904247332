import { Col, Form, Row } from "antd"
import DeclarationOwner from "./DeclarationOwner"
import FlInput from "src/components/FloatingLabel/Input"
import FieldPractice from "./FieldPractice"

const DeclarationPatent = ({
  form,
  checkbox,
  setCheckbox,
  dataSource,
  setDataSource,
  isEdit,
}) => {
  return (
    <Form
      form={form}
      // initialValues={{
      //   ListAmendmentsDiff: [{}],
      // }}
      // layout="vertical"
      id="myForm_1"
      scrollToFirstError={{
        behavior: "smooth",
        block: "center",
        inline: "center",
      }}
      getContainer={() => document.getElementById("layout-user-scroll")}
    >
      <div className="p-24">
        <Row gutter={[16, 6]}>
          <div className="go-code">
            <Form.Item name="ApplicationCodeForDeclaration">
              <FlInput borderBottomOnly label="ID" />
            </Form.Item>
          </div>
          <Col span={24}>
            <div className="text-center fw-600 fs-24">TỜ KHAI</div>
          </Col>
          <Col span={24}>
            <div className="text-center">
              ĐĂNG KÝ DỰ KIỂM TRA NGHIỆP VỤ ĐẠI DIỆN SỞ HỮU CÔNG NGHIỆP
            </div>
          </Col>
          <Col span={24}>
            <div className="text-center">
              <b>Kính gửi:</b> CỤC SỞ HỮU TRÍ TUỆ
            </div>
          </Col>
          <Col span={24}>
            <div className="text-center">
              Người nộp đơn dưới đâu đề nghị được tham dự kiểm tra nghiệp vụ đại
              diện sở hữu công nghiêp:{" "}
            </div>
          </Col>
        </Row>
      </div>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          {/* NGƯỜI NỘP ĐƠN */}
          <DeclarationOwner
            checkbox={checkbox}
            setCheckbox={setCheckbox}
            form={form}
          />
        </Col>
        <Col span={24}>
          {/* LĨNH VỰC HÀNH NGHỀ ĐĂNG KÝ DỰ KIỂM TRA */}
          <FieldPractice
            form={form}
            checkbox={checkbox}
            setCheckbox={setCheckbox}
            dataSource={dataSource}
            setDataSource={setDataSource}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default DeclarationPatent
