import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  paymentUrl: "",
  paymentSuccess: false,
  receiptUrl: "",
  loadingPage: false,
}

export const paymentReducerSlice = createSlice({
  name: "paymentReducer",
  initialState,
  reducers: {
    setPaymentUrl: (state, action) => {
      state.paymentUrl = action.payload
    },
    setPaymentSuccess: (state, action) => {
      state.paymentSuccess = action.payload
    },
    setReceiptUrl: (state, action) => {
      state.receiptUrl = action.payload
    },
    setLoadingPage: (state, action) => {
      state.loadingPage = action.payload
    },
  },
})

export const {
  setPaymentUrl,
  setReceiptUrl,
  setPaymentSuccess,
  setLoadingPage,
} = paymentReducerSlice.actions

export default paymentReducerSlice.reducer
