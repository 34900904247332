import {
  apitinhPhiBoSungXacLapQuyen,
  apiGetInforToCalFee,
  apiGetInforDossierInWipo,
  apiTinhPhiBoSungDonVBBH,
  apiGetListPhiBS,
  apiGetListLePhiBS,
  apiTinhPhi,
  apiFeeList,
  apiTinhFee,
  apiGetListFeeNew,
} from "./urls"
import http from "../index"
import QueryString from "qs"

//Fee bo sung
// const tinhPhiBoSungXacLapQuyen = body =>
//   http.post(apitinhPhiBoSungXacLapQuyen, body)
const tinhPhiBoSungXacLapQuyen = body =>
  tinhPhi(body, "TinhPhiBoSungXacLapQuyen")

// const tinhPhiBoSungDonVBBH = body => http.post(apiTinhPhiBoSungDonVBBH, body)
const tinhPhiBoSungDonVBBH = body => tinhPhi(body, "TinhPhiBoSungDonVBBH")

// ===============================================================
const getInforToCalFee = body => http.post(apiGetInforToCalFee, body)
const getInforDossierInWipo = value =>
  http.post(
    `${apiGetInforDossierInWipo}?input=${!!value?.code ? value?.code : value
    }&RelationType=${!!value?.RelationType ? value?.RelationType : ""}`,
  )
const getListPhiBS = params =>
  http.get(`${apiGetListPhiBS}?${QueryString.stringify(params)}`)
const getListLePhiBS = body => http.get(apiGetListLePhiBS, body)

//List Fee
const getListFee = param => http.get(apiFeeList + `?DossierCateID=` + param)

//Fee
const tinhPhi = (body, feeType) =>
  http.post(apiTinhPhi + "?feeType=" + feeType, body)

//Fee don off
const tinhFee = (body, feeType) =>
  http.post(apiTinhFee + "?feeType=" + feeType, body)

//List Fee
const getListFeeNew = () => http.get(apiGetListFeeNew)

const FeeService = {
  tinhPhiBoSungXacLapQuyen,
  getInforToCalFee,
  getInforDossierInWipo,
  tinhPhiBoSungDonVBBH,
  getListPhiBS,
  getListLePhiBS,
  tinhPhi,
  getListFee,
  tinhFee,
  getListFeeNew,
}
export default FeeService
