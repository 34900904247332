// Dossier_
export const apiGetInfoDossier = "Dossier_/GetInforDossier"
// Dossier_1_
export const apiThemDangKySangChe = "Dossier_1_/ThemDangKySangChe"
//Nhân bản tờ khai
export const apiCreateDossierByID = "Dossier_1_/NhanBanToKhaiSangChe"
export const apiCapNhatDangKySangChe = "Dossier_1_/CapNhatDangKySangChe"
export const apiThemDangKyChiDanDiaLy = "Dossier_1_/ThemMoiToKhaiChiDanDiaLi"
export const apiCapNhatDangKyChiDanDiaLy = "Dossier_1_/SuaToKhaiChiDanDiaLy"
export const apiThemDangKyKieuDangCongNghiep =
  "Dossier_1_/ThemDangKyKieuDangCongNghiep"
export const apiCapNhatDangKyKieuDangCongNghiep =
  "Dossier_1_/CapNhatDangKyKieuDangCongNghiep"
export const apiThemDangKyNhanHieu = "Dossier_1_/ThemDangKyNhanHieu"
export const apiSuaDangKyNhanHieu = "Dossier_1_/SuaDangKyNhanHieu"
export const apiThemDangKyThietKeBoTriMachTichHopBanDan =
  "Dossier_1_/ThemDangKyThietKeBoTriMachTichHopBanDan"
export const apiCapNhatDangKyThietKeBoTriMachTichHopBanDan =
  "Dossier_1_/CapNhatDangKyThietKeBoTriMachTichHopBanDan"
//Dossier_2_
export const apiThemDossier24 =
  "Dossier_2_/ThemMoiGiaiQuyetKhieuNaiLienQuanDenSoHuuCongNghiep"
export const apiCapNhatDossier24 =
  "Dossier_2_/SuaGiaiQuyetKhieuNaiLienQuanDenSoHuuCongNghiep"
export const apiThemSuaDoiBoSungTachDonDangKySHCN =
  "Dossier_2_/ThemMoiSuaDoiBoSungTachDonDangKySoHuuCongNghiep"
export const apiCapNhatSuaDoiBoSungTachDonDangKySHCN =
  "Dossier_2_/ChinhSuaSuaDoiBoSungTachDonDangKySoHuuCongNghiep"
export const apiThemDangKyNhanHieuNguonGocVN =
  "Dossier_2_/ThemDangKyNhanHieuNguonGocVN"
export const apiSuaDangKyNhanHieuNguonGocVN =
  "Dossier_2_/SuaDangKyNhanHieuNguonGocVN"
//Dossier_3_
export const apiInsertDossier42 =
  "Dossier_3_/ThemMoiYeuCauThamDinhNoiDungDonDangKySangCheCuaNguoiThuBa"
export const apiUpdateDossier42 =
  "Dossier_3_/SuaYeuCauThamDinhNoiDungDonDangKySangCheCuaNguoiThuBa"
export const apiInsert_DDCCSHSCVSCTTVCPLHDP =
  "Dossier_3_/Insert_DDCCSHSCVSCTTVCPLHDP"
export const apiUpdate_DDCCSHSCVSCTTVCPLHDP =
  "Dossier_3_/Update_DDCCSHSCVSCTTVCPLHDP"
export const apiDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu =
  "Dossier_3_/DangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu"
export const apiSuaDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu =
  "Dossier_3_/SuaDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu"
export const apiInsertToKhaiChuyenNhuongDonDangKySHCN =
  "Dossier_3_/InsertToKhaiChuyenNhuongDonDangKySHCN"
export const apiUpdateToKhaiChuyenNhuongDonDangKySHCN =
  "Dossier_3_/UpdateToKhaiChuyenNhuongDonDangKySHCN"

//Dossier_4_
export const apiThemDuyTriGiaHanVanBangBaoHoSangChe =
  "Dossier_4_/ThemToKhaiGiaHanDuyTriVBBH_DTSHCN"
export const apiSuaDuyTriGiaHanVanBangBaoHoSangChe =
  "Dossier_4_/UpdateToKhaiGiaHanDuyTriVBBH_DTSHCN"
export const apiInsertCancelHieuLucVBBH = "Dossier_4_/InsertCancelHieuLucVBBH"
export const apiUpdateCancelHieuLucVBBH = "Dossier_4_/UpdateCancelHieuLucVBBH"
export const apiThemToKhaiSuaDoiVBBH_DTSHCN =
  "Dossier_4_/InsertSuaDoiVBHH_DTSHCN"
export const apiUpdateToKhaiSuaDoiVBBH_DTSHCN =
  "Dossier_4_/UpdateSuaDoiVBHH_DTSHCN"
export const apiInsertCapLaiOrCapPhoBangVBBH =
  "Dossier_4_/InsertCapLaiOrCapPhoBangVBBH"
export const apiUpdateCapLaiOrCapPhoBangVBBH =
  "Dossier_4_/UpdateCapLaiOrCapPhoBangVBBH"
export const apiThemDangKyHopDongChuyenNhuongQuyenSHCN =
  "Dossier_4_/InsertDangKyHopDongCNQSHCN"
export const apiCapNhatDangKyHopDongChuyenNhuongQuyenSHCN =
  "Dossier_4_/UpdateDangKyHopDongCNQSHCN"
export const apiThemDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN =
  "Dossier_4_/InsertDangKyHopDongCQSĐTSHCN"
export const apiCapNhatDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN =
  "Dossier_4_/UpdateDangKyHopDongCQSĐTSHCN"
export const apiThemDangKyYCSDND_GH_CDHLHD =
  "Dossier_4_/Insert_YCSDND_GH_CDHLHD"
export const apiCapNhatYCSDND_GH_CDHLHD = "Dossier_4_/Update_YCSDND_GH_CDHLHD"
export const apiYeuCauBatBuocChuyenGiaoQuyenSuDungSangChe =
  "Dossier_4_/ThemMoiQuyetDinhBatBuocChuyenGiaoQSDSC"
export const apiSuaYeuCauBatBuocChuyenGiaoQuyenSuDungSangChe =
  "Dossier_4_/ChinhSuaQuyetDinhBatBuocChuyenGiaoQSDSC"
export const apiThemMoiToKhai38 =
  "Dossier_4_/ThemMoi_GiaoQuyenDangKi_ChoPhepSuDung"
export const apiSuaToKhai38 =
  "Dossier_4_/ChinhSua_GiaoQuyenDangKi_ChoPhepSuDung"
export const apiInsertCapCapLaiChungChiHanhNgheDVDaiDienSHCN =
  "Dossier_4_/InsertCapCapLaiChungChiHanhNgheDVDaiDienSHCN"
export const apiUpdateCapLaiChungChiHanhNgheDVDD =
  "Dossier_4_/UpdateCapLaiChungChiHanhNgheDVDD"
export const apiInsert_GNNDDSHCON = "Dossier_4_/Insert_GNNDDSHCON"
export const apiUpdate_GNNDDSHCON = "Dossier_4_/Update_GNNDDSHCON"
export const apiYeuCauGhiNhanToChucDichVuDaiDienSHCN =
  "Dossier_4_/YeuCauGhiNhanToChucDichVuDaiDienSHCN"
export const apiSuaYeuCauGhiNhanToChucDichVuDaiDienSHCN =
  "Dossier_4_/SuaYeuCauGhiNhanToChucDichVuDaiDienSHCN"
export const apiInsert_GNTDTTCTCDVDDSHCN = "Dossier_4_/Insert_GNTDTTCTCDVDDSHCN"
export const apiUpdate_GNTDTTCTCDVDDSHCN = "Dossier_4_/Update_GNTDTTCTCDVDDSHCN"
export const apiYeuCauXoaTenToChucDichVuDaiDienSHCN =
  "Dossier_4_/YeuCauXoaTenToChucDichVuDaiDienSHCN"
export const apiSuaYeuCauXoaTenToChucDichVuDaiDienSHCN =
  "Dossier_4_/SuaYeuCauXoaTenToChucDichVuDaiDienSHCN"
export const apiYeuCauXoaTenNguoiDichVuDaiDienSHCN =
  "Dossier_4_/YeuCauXoaTenNguoiDichVuDaiDienSHCN"
export const apiSuaYeuCauXoaTenNguoiDaiDienSHCN =
  "Dossier_4_/SuaYeuCauXoaTenNguoiDaiDienSHCN"
export const apiThem_TTDKDKTNVGDSHCN = "Dossier_4_/Insert_TTDKDKTNVGDSHCN"
export const apiCapNhat_TTDKDKTNVGDSHCN = "Dossier_4_/Update_TTDKDKTNVGDSHCN"
export const apiThemMoiToKhai42 = "Dossier_4_/ThemMoiToKhai42"
export const apiSuaToKhai42 = "Dossier_4_/SuaToKhai42"
export const apiThemCapLaiTheGiamDinhVienSHCN = "Dossier_4_/Insert_CLTGDVSHCN"
export const apiSuaCapLaiTheGiamDinhVienSHCN = "Dossier_4_/Update_CLTGDVSHCN"
export const apiInsert_CLGCNTCGDSHCN = "Dossier_4_/Insert_CLGCNTCGDSHCN"
export const apiUpdate_CLGCNTCGDSHCN = "Dossier_4_/Update_CLGCNTCGDSHCN"
// Dossier_5_
export const apiInsertDossier30 = "Dossier_5_/InsertDossier30"
export const apiUpdateDossier30 = "Dossier_5_/UpdateDossier30"

// =============================================================================================
// =============================================================================================
export const apiGetList = "Dossier/GetList"
export const apiGetListCreated = "Dossier/GetListCreated"
export const apiUpdateStatus = "Dossier/UpdateStatus"
export const apiUpdateListDossier = "Dossier/UpdateListDossier"
export const apiUpdateProfileCode = "Dossier/UpdateProfileCode"
export const apiGetAllDossierCate = "Dossier/GetAllDossierCate"
export const apiIsCheckFeeAndContent = "Dossier/IsCheckFeeAndContent"
export const apiGetInforListDossier = "Dossier/GetInforListDossier"
export const apiTinhPhiNopDon = "Dossier/TinhPhiNopDon"
export const apiGetLogProgress = "Dossier/GetLogProgress"
export const apiGetTotalByTypeForKH = "Dossier/GetTotalByTypeForKH"
export const apiGetTotalByTypeForAdmin = "Dossier/GetTotalByTypeForAdmin"
export const apiGetTotalIsPaidByType = "Dossier/GetTotalIsPaidByType"
export const apiGetTotalIsSignWaitByType = "Dossier/GetTotalIsSignWaitByType"
export const apiGetTotalIsSignWaitReject = "Dossier/GetTotalIsSignWaitReject"
export const apiGetTotalIsSignNewByType = "Dossier/GetTotalIsSignNewByType"
export const apiDeleteDosier = "Dossier/DeleteDosier"
export const apiExportDossier = "Dossier/ExportDossier"
export const apiGopToKhai = "SyncWipoIpas/GopToKhai"
export const apiExportPaymentInfo = "Dossier/ExportPaymentInfo"
export const apiComboboxBank = "Dossier/ComboboxBank"
export const apiUpdateSyncStatus = "Dossier/UpdateSyncStatus"
export const apiDongBoDonLv4 = "SyncWipoIpas/DongBoDonLv4"
export const apiImportIpc = "Ipc/ImportIpc"
export const apiGetAllIpc = "Ipc/GetAllIpc"
export const apiGetListRepresentative = "Dossier/GetListRepresentative"
export const apiInBarcode = "Dossier/InBarcode"
export const apiGetFileSignedBase64 = "Dossier/GetFileSignedBase64"
export const apiCapSoDon = "DossierOff/CapSoDon"
export const apiPrintPdfBarCode = "DossierOff/PrintPdfBarCode"
export const apiPrintPdfBarCodeHtml = "DossierOff/PrintPdfBarCodeHtml"
export const apiGetListRepresent = "DossierOff/GetListRepresent"
export const apiListGetHinhThucNop = "DossierOff/GetListHinhThucNop"
export const apiSignToResult = "Dossier/SignToResult"
export const apiSignToRejectResult = "Dossier/SignToRejectResult"
export const apiUpdatePayment = "Dossier/UpdatePayment"
export const apiUpdateReceipt = "Dossier/UpdateReceipt"
export const apiClassifyGetList = "Classify/GetList"
export const apiClassifyGetAllIpc = "Classify/GetAllIpc"
export const apiGetListLocarno = "Classify/GetListLocarno"
export const apiGetAllLocarno = "Classify/GetAllLocarno"
export const apiCapSoDonV4 = "Dossier/CapSoDonV4"
export const apiExportDossierOnl = "Dossier/ExportDossierOnl"
export const apiGetDetailDossier = "Dossier/GetDetailDossier"
