//Authenticate
export const apiLogin = `Authservice/Login`
export const apiLogout = `Authservice/Logout`
export const apiRegister = `Authservice/Register`
export const apiBussinessRegister = `Authservice/BussinessRegister`
export const apiRegisterAccount = `Authservice/RegisterAccount`
export const apiForgotPassword = `Authservice/ForgotPassword`
export const apiChangePassword = `Authservice/ChangePassword`
export const apiVerifyCode = `Authservice/VerifyCode`
export const apiConvertAddress = `Authservice/ConvertAddress`
export const apiGetBase64FromUrl = `Authservice/GetBase64FromUrl`

//PDF
export const apiGetNumberPagePdf = "Authservice/GetNumberPagePdf"
export const apiGetFontSizePdf = "Authservice/GetFontSizePdf"
export const apiCheckPdfSigned = "Authservice/CheckPdfSigned"
