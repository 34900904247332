import { Form, Input } from "antd"
import React from "react"
import {
  converSingleCorrectFormat,
  formatterProfileCodeWithPrefixAndSingleCode,
} from "src/lib/utils"

export const CODE_EXAMPLE = `____-_____`
const RootNumberInput = ({
  form,
  open,
  checkbox,
  checkValueExistence,
  handleEnterPress,
  inputRefTachDon,
}) => {
  return (
    <Form.Item
      label="Số đơn gốc:"
      name="SoDonGoc"
      rules={[
        {
          required: checkbox.IsTachDon,
          message: "Số đơn gốc không được để trống.",
        },
      ]}
    >
      <Input
        placeholder={"____-_____"}
        onBlur={async e => {
          let valueConvert = converSingleCorrectFormat(
            e.target.value,
            CODE_EXAMPLE,
          )
          await form.setFieldsValue({
            SoDonGoc: valueConvert,
          })
          if (!!valueConvert) checkValueExistence(valueConvert)
        }}
        addonBefore={`${open?.SingleCode}-`}
        onChange={e => {
          let soDonGoc = formatterProfileCodeWithPrefixAndSingleCode(
            e?.target?.value,
            open?.SingleCode,
          )
          form.setFieldsValue({
            SoDonGoc: soDonGoc,
          })
        }}
        maxLength={CODE_EXAMPLE?.length}
        onPressEnter={handleEnterPress}
        ref={inputRefTachDon}
      />
    </Form.Item>
  )
}

export default RootNumberInput
