import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Notice from "src/components/Notice"
import { StoreContext } from "src/lib/store"
import {
  addFormUploadFile,
  deleteDossierUploading,
  deleteFormUploadFile,
} from "src/redux/uploadFileList"

const CheckLoadingDocument = ({
  dossierID,
  setActiveKey,
  handleFormAffterSaveDoc,
  activeKey,
}) => {
  const { listFormUploadFile } = useSelector(state => state.uploadFileList)
  const dispatch = useDispatch()
  const { uploadListFileStore } = useContext(StoreContext)
  const [
    uploadFileList,
    updateListFileAddFile,
    deleteUploadFileList,
    updateListFileAddListFile,
    deleteListUploadFileList,
  ] = uploadListFileStore
  const { loadingPercent } = useSelector(state => state.uploadFileList)
  const checkHaveNotSuccessFile = (DossierID, loadingPercent) => {
    uploadFileList?.forEach(item => {
      if (!loadingPercent?.[item?.ID]?.IsSuccess) return false
      else if (
        loadingPercent?.[item?.ID]?.IsError ||
        loadingPercent?.[item?.ID]?.IsErrorUpFileApi ||
        loadingPercent?.[item?.ID]?.IsApi === "error"
      ) {
        return "error"
      }
    })
    return true
  }
  // let msg = true
  //   for (let obj in loadingPercent) {
  //     if (obj !== "reload") {
  //       if (
  //         loadingPercent[obj]?.DossierID === DossierID &&
  //         !loadingPercent[obj]?.IsError &&
  //         !loadingPercent[obj]?.IsApi
  //       ) {
  //         return false
  //       } else if (
  //         loadingPercent[obj]?.IsApi === "error" ||
  //         !!loadingPercent[obj]?.IsError
  //       )
  //         msg = "error"
  //     }
  //   }
  //   return msg
  const checkUploadFileOfDossier = (DossierID, loadingPercent) => {
    if (
      !uploadFileList?.length ||
      !uploadFileList?.find(item => item?.DossierID === DossierID)
    )
      return "notfile"
    else if (
      uploadFileList?.find(
        item => item?.DossierID === DossierID && !loadingPercent[item?.ID],
      )
    )
      return false
    for (let i = 0; i < uploadFileList?.length; i++) {
      let item = uploadFileList[i]

      if (
        loadingPercent[item?.ID]?.DossierID === DossierID &&
        !loadingPercent[item?.ID]?.IsError &&
        !loadingPercent[item?.ID]?.IsApi
      )
        return false
      else if (
        loadingPercent?.[item?.ID]?.IsError ||
        loadingPercent?.[item?.ID]?.IsErrorUpFileApi ||
        loadingPercent?.[item?.ID]?.IsApi === "error"
      ) {
        return "error"
      }
    }
    return true
  }
  const checkLoadingDocument = () => {
    let checkUpload = checkUploadFileOfDossier(
      dossierID?.DossierID,
      loadingPercent,
    )
    // let checkHaveNotSuccess = checkHaveNotSuccessFile(
    //   dossierID?.DossierID,
    //   loadingPercent,
    // )
    if (
      !!dossierID?.DossierID &&
      // && !!checkHaveNotSuccess
      !!checkUpload
    ) {
      dispatch(deleteDossierUploading(dossierID?.DossierID))
      dispatch(deleteFormUploadFile(dossierID?.DossierID))

      if (checkUpload === "error") {
        Notice({
          isSuccess: false,
          msg: "Có file upload bị lỗi!",
        })
      } else {
        // setActiveKey && handleFormAffterSaveDoc && handleFormAffterSaveDoc()
        setActiveKey && setActiveKey()
      }
    }
  }
  useEffect(() => {
    if (activeKey > 1) checkLoadingDocument()
  }, [loadingPercent, dossierID?.DossierID])
  return <></>
}

export default CheckLoadingDocument
