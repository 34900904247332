import QueryString from "qs"
import http from "../index"
import {
  apiCapNhatDangKyChiDanDiaLy,
  apiCapNhatDangKyHopDongChuyenNhuongQuyenSHCN,
  apiCapNhatDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN,
  apiCapNhatDangKyKieuDangCongNghiep,
  apiCapNhatDangKySangChe,
  apiCapNhatDangKyThietKeBoTriMachTichHopBanDan,
  apiCapNhatDossier24,
  apiCapNhatSuaDoiBoSungTachDonDangKySHCN,
  apiCapNhatYCSDND_GH_CDHLHD,
  apiCapNhat_TTDKDKTNVGDSHCN,
  apiCapSoDon,
  apiCapSoDonV4,
  apiClassifyGetAllIpc,
  apiClassifyGetList,
  apiComboboxBank,
  apiCreateDossierByID,
  apiDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu,
  apiDeleteDosier,
  apiDongBoDonLv4,
  apiExportDossier,
  apiExportDossierOnl,
  apiExportPaymentInfo,
  apiGetAllDossierCate,
  apiGetAllIpc,
  apiGetAllLocarno,
  apiGetDetailDossier,
  apiGetFileSignedBase64,
  apiGetInfoDossier,
  apiGetInforListDossier,
  apiGetList,
  apiGetListCreated,
  apiGetListLocarno,
  apiGetListRepresent,
  apiGetListRepresentative,
  apiGetLogProgress,
  apiGetTotalByTypeForAdmin,
  apiGetTotalByTypeForKH,
  apiGetTotalIsPaidByType,
  apiGetTotalIsSignNewByType,
  apiGetTotalIsSignWaitByType,
  apiGetTotalIsSignWaitReject,
  apiGopToKhai,
  apiImportIpc,
  apiInBarcode,
  apiInsertCancelHieuLucVBBH,
  apiInsertCapCapLaiChungChiHanhNgheDVDaiDienSHCN,
  apiInsertCapLaiOrCapPhoBangVBBH,
  apiInsertDossier30,
  apiInsertDossier42,
  apiInsertToKhaiChuyenNhuongDonDangKySHCN,
  apiInsert_CLGCNTCGDSHCN,
  apiInsert_DDCCSHSCVSCTTVCPLHDP,
  apiInsert_GNNDDSHCON,
  apiInsert_GNTDTTCTCDVDDSHCN,
  apiIsCheckFeeAndContent,
  apiListGetHinhThucNop,
  apiPrintPdfBarCode,
  apiPrintPdfBarCodeHtml,
  apiSignToRejectResult,
  apiSignToResult,
  apiSuaCapLaiTheGiamDinhVienSHCN,
  apiSuaDangKyNhanHieu,
  apiSuaDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu,
  apiSuaDangKyNhanHieuNguonGocVN,
  apiSuaDuyTriGiaHanVanBangBaoHoSangChe,
  apiSuaToKhai38,
  apiSuaToKhai42,
  apiSuaYeuCauBatBuocChuyenGiaoQuyenSuDungSangChe,
  apiSuaYeuCauGhiNhanToChucDichVuDaiDienSHCN,
  apiSuaYeuCauXoaTenNguoiDaiDienSHCN,
  apiSuaYeuCauXoaTenToChucDichVuDaiDienSHCN,
  apiThemCapLaiTheGiamDinhVienSHCN,
  apiThemDangKyChiDanDiaLy,
  apiThemDangKyHopDongChuyenNhuongQuyenSHCN,
  apiThemDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN,
  apiThemDangKyKieuDangCongNghiep,
  apiThemDangKyNhanHieu,
  apiThemDangKyNhanHieuNguonGocVN,
  apiThemDangKySangChe,
  apiThemDangKyThietKeBoTriMachTichHopBanDan,
  apiThemDangKyYCSDND_GH_CDHLHD,
  apiThemDossier24,
  apiThemDuyTriGiaHanVanBangBaoHoSangChe,
  apiThemMoiToKhai38,
  apiThemMoiToKhai42,
  apiThemSuaDoiBoSungTachDonDangKySHCN,
  apiThemToKhaiSuaDoiVBBH_DTSHCN,
  apiThem_TTDKDKTNVGDSHCN,
  apiTinhPhiNopDon,
  apiUpdateCancelHieuLucVBBH,
  apiUpdateCapLaiChungChiHanhNgheDVDD,
  apiUpdateCapLaiOrCapPhoBangVBBH,
  apiUpdateDossier30,
  apiUpdateDossier42,
  apiUpdateListDossier,
  apiUpdatePayment,
  apiUpdateProfileCode,
  apiUpdateReceipt,
  apiUpdateStatus,
  apiUpdateSyncStatus,
  apiUpdateToKhaiChuyenNhuongDonDangKySHCN,
  apiUpdateToKhaiSuaDoiVBBH_DTSHCN,
  apiUpdate_CLGCNTCGDSHCN,
  apiUpdate_DDCCSHSCVSCTTVCPLHDP,
  apiUpdate_GNNDDSHCON,
  apiUpdate_GNTDTTCTCDVDDSHCN,
  apiYeuCauBatBuocChuyenGiaoQuyenSuDungSangChe,
  apiYeuCauGhiNhanToChucDichVuDaiDienSHCN,
  apiYeuCauXoaTenNguoiDichVuDaiDienSHCN,
  apiYeuCauXoaTenToChucDichVuDaiDienSHCN,
} from "./urls"
import { apiTinhPhi } from "../FeeService/urls"
import FeeService from "../FeeService"

const themDangKySangChe = body => http.post(apiThemDangKySangChe, body)
const themDuyTriGiaHanVanBangBaoHoSangChe = body =>
  http.post(apiThemDuyTriGiaHanVanBangBaoHoSangChe, body)
const capNhatDangKySangChe = body => http.post(apiCapNhatDangKySangChe, body)
const suaDuyTriGiaHanVanBangBaoHoSangChe = body =>
  http.post(apiSuaDuyTriGiaHanVanBangBaoHoSangChe, body)
const themDangKyChiDanDiaLy = body => http.post(apiThemDangKyChiDanDiaLy, body)
const capNhatDangKyChiDanDiaLy = body =>
  http.post(apiCapNhatDangKyChiDanDiaLy, body)
const themDangKyNhanHieu = body => http.post(apiThemDangKyNhanHieu, body)
const suaDangKyNhanHieu = body => http.post(apiSuaDangKyNhanHieu, body)
const getList = body => http.post(apiGetList, body)
const themDangKyKieuDangCongNghiep = body =>
  http.post(apiThemDangKyKieuDangCongNghiep, body)
const capNhatDangKyKieuDangCongNghiep = body =>
  http.post(apiCapNhatDangKyKieuDangCongNghiep, body)
const themDangKyThietKeBoTriMachTichHopBanDan = body =>
  http.post(apiThemDangKyThietKeBoTriMachTichHopBanDan, body)

const capNhatDangKyThietKeBoTriMachTichHopBanDan = body =>
  http.post(apiCapNhatDangKyThietKeBoTriMachTichHopBanDan, body)
const themDangKyHopDongChuyenQuyenSHCN = body =>
  http.post(apiThemDangKyHopDongChuyenNhuongQuyenSHCN, body)
const capNhatDangKyHopDongChuyenQuyenSHCN = body =>
  http.post(apiCapNhatDangKyHopDongChuyenNhuongQuyenSHCN, body)
const themDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN = body =>
  http.post(apiThemDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN, body)
const capNhatDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN = body =>
  http.post(apiCapNhatDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN, body)
const themDangKyYCSDND_GH_CDHLHD = body =>
  http.post(apiThemDangKyYCSDND_GH_CDHLHD, body)
const capNhatYCSDND_GH_CDHLHD = body =>
  http.post(apiCapNhatYCSDND_GH_CDHLHD, body)
const isCheckFeeAndContent = params =>
  http.put(apiIsCheckFeeAndContent + `?${QueryString.stringify(params)}`)
const getInforDossier = params =>
  http.get(apiGetInfoDossier + `?${QueryString.stringify(params)}`)
const getInforListDossier = body => http.post(apiGetInforListDossier, body)
const getListCreated = body => http.post(apiGetListCreated, body)
const getAllDossierCate = body => http.post(apiGetAllDossierCate, body)
const updateStatus = body => http.post(apiUpdateStatus, body)
const updateListDossier = body => http.post(apiUpdateListDossier, body)
const updateProfileCode = params =>
  http.put(apiUpdateProfileCode + `?${QueryString.stringify(params)}`)
// ===========================
// const tinhPhiNopDon = body => http.post(apiTinhPhiNopDon, body)
const tinhPhiNopDon = body => FeeService.tinhPhi(body, "TinhPhiNopDon")

const getLogProgress = body =>
  http.get(apiGetLogProgress + `?${QueryString.stringify(body)}`)
const getTotalByTypeForKH = params =>
  http.get(apiGetTotalByTypeForKH, { params })
const inBarcode = body => http.post(apiInBarcode + `?IsMain=0,`, body)
const getFileSignedBase64 = body => http.post(apiGetFileSignedBase64, body)
const getTotalByTypeForAdmin = params =>
  http.get(apiGetTotalByTypeForAdmin, { params })
const getTotalIsPaidByType = params =>
  http.get(apiGetTotalIsPaidByType, { params })
const getTotalIsSignWaitByType = params =>
  http.get(apiGetTotalIsSignWaitByType, { params })
const getTotalIsSignWaitReject = params =>
  http.get(apiGetTotalIsSignWaitReject, { params })
const getTotalIsSignNewByType = params =>
  http.get(apiGetTotalIsSignNewByType, { params })
const deleteDosier = body => http.post(apiDeleteDosier, body)
const exportDossier = body =>
  http.get(apiExportDossier + `?${QueryString.stringify(body)}`, {
    responseType: "blob",
  })
const gopToKhai = body =>
  http.post(apiGopToKhai + `?${QueryString.stringify(body)}`, body, {
    responseType: "blob",
  })
const getComboboxBank = body =>
  http.get(apiComboboxBank + `?${QueryString.stringify(body)}`)
const exportPaymentInfo = body =>
  http.post(apiExportPaymentInfo, body, {
    responseType: "blob",
  })
const insertDossier30 = body =>
  http.post(apiInsertDossier30, body, {
    responseType: "blob",
  })
const updateDossier30 = body =>
  http.post(apiUpdateDossier30, body, {
    responseType: "blob",
  })
const importIpc = body => http.post(apiImportIpc, body)
const getAllIpc = body => http.post(apiGetAllIpc, body)
const getListRepresentative = body => http.post(apiGetListRepresentative, body)
const capSoDon = body => http.post(apiCapSoDon, body)
const printPdfBarCode = body => http.post(apiPrintPdfBarCode, body)
const printPdfBarCodeHtml = body => http.post(apiPrintPdfBarCodeHtml, body)

const getListRepresent = body => http.post(apiGetListRepresent, body)
const getListHinhThucNop = body => http.post(apiListGetHinhThucNop, body)
const signToResult = body => http.post(apiSignToResult, body)
const signToRejectResult = body => http.post(apiSignToRejectResult, body)
const themSuaDoiBoSungTachDonDangKySHCN = body =>
  http.post(apiThemSuaDoiBoSungTachDonDangKySHCN, body)
const capNhatSuaDoiBoSungTachDonDangKySHCN = body =>
  http.post(apiCapNhatSuaDoiBoSungTachDonDangKySHCN, body)
const themToKhaiChuyenNhuongDonDangKySHCN = body =>
  http.post(apiInsertToKhaiChuyenNhuongDonDangKySHCN, body)
const capNhatToKhaiChuyenNhuongDonDangKySHCN = body =>
  http.post(apiUpdateToKhaiChuyenNhuongDonDangKySHCN, body)
const themToKhaiSuaDoiVBBH_DTSHCN = body =>
  http.post(apiThemToKhaiSuaDoiVBBH_DTSHCN, body)
const updateToKhaiSuaDoiVBBH_DTSHCN = body =>
  http.post(apiUpdateToKhaiSuaDoiVBBH_DTSHCN, body)
const yeuCauBatBuocChuyenGiaoQuyenSuDungSangChe = body =>
  http.post(apiYeuCauBatBuocChuyenGiaoQuyenSuDungSangChe, body)
const suaYeuCauBatBuocChuyenGiaoQuyenSuDungSangChe = body =>
  http.post(apiSuaYeuCauBatBuocChuyenGiaoQuyenSuDungSangChe, body)
const insertCapLaiOrCapPhoBangVBBH = body =>
  http.post(apiInsertCapLaiOrCapPhoBangVBBH, body)
const updateCapLaiOrCapPhoBangVBBH = body =>
  http.post(apiUpdateCapLaiOrCapPhoBangVBBH, body)
const them_TTDKDKTNVGDSHCN = body => http.post(apiThem_TTDKDKTNVGDSHCN, body)
const capNhat_TTDKDKTNVGDSHCN = body =>
  http.post(apiCapNhat_TTDKDKTNVGDSHCN, body)
const themDossier24 = body => http.post(apiThemDossier24, body)
const capNhatDossier24 = body => http.post(apiCapNhatDossier24, body)
const insertCapCapLaiChungChiHanhNgheDVDaiDienSHCN = body =>
  http.post(apiInsertCapCapLaiChungChiHanhNgheDVDaiDienSHCN, body)
const updateCapLaiChungChiHanhNgheDVDD = body =>
  http.post(apiUpdateCapLaiChungChiHanhNgheDVDD, body)
const insertCancelHieuLucVBBH = body =>
  http.post(apiInsertCancelHieuLucVBBH, body)
const updateCancelHieuLucVBBH = body =>
  http.post(apiUpdateCancelHieuLucVBBH, body)
const themDangKyNhanHieuNguonGocVN = body =>
  http.post(apiThemDangKyNhanHieuNguonGocVN, body)
const suaDangKyNhanHieuNguonGocVN = body =>
  http.post(apiSuaDangKyNhanHieuNguonGocVN, body)
const insertYeuCauXoaTenToChucDichVuDaiDienSHCN = body =>
  http.post(apiYeuCauXoaTenToChucDichVuDaiDienSHCN, body)
const suaYeuCauXoaTenToChucDichVuDaiDienSHCN = body =>
  http.post(apiSuaYeuCauXoaTenToChucDichVuDaiDienSHCN, body)
const insertYeuCauXoaTenNguoiDichVuDaiDienSHCN = body =>
  http.post(apiYeuCauXoaTenNguoiDichVuDaiDienSHCN, body)
const suaYeuCauXoaTenNguoiDaiDienSHCN = body =>
  http.post(apiSuaYeuCauXoaTenNguoiDaiDienSHCN, body)
const insert_GNNDDSHCON = body => http.post(apiInsert_GNNDDSHCON, body)
const update_GNNDDSHCON = body => http.post(apiUpdate_GNNDDSHCON, body)
const dangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu = body =>
  http.post(apiDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu, body)
const suaDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu = body =>
  http.post(apiSuaDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu, body)

const insert_GNTDTTCTCDVDDSHCN = body =>
  http.post(apiInsert_GNTDTTCTCDVDDSHCN, body)
const update_GNTDTTCTCDVDDSHCN = body =>
  http.post(apiUpdate_GNTDTTCTCDVDDSHCN, body)
const insert_DDCCSHSCVSCTTVCPLHDP = body =>
  http.post(apiInsert_DDCCSHSCVSCTTVCPLHDP, body)
const update_DDCCSHSCVSCTTVCPLHDP = body =>
  http.post(apiUpdate_DDCCSHSCVSCTTVCPLHDP, body)

const yeuCauGhiNhanToChucDichVuDaiDienSHCN = body =>
  http.post(apiYeuCauGhiNhanToChucDichVuDaiDienSHCN, body)
const suaYeuCauGhiNhanToChucDichVuDaiDienSHCN = body =>
  http.post(apiSuaYeuCauGhiNhanToChucDichVuDaiDienSHCN, body)
const themMoiToKhai42 = body => http.post(apiThemMoiToKhai42, body)
const capNhatToKhai42 = body => http.post(apiSuaToKhai42, body)
const themCapLaiTHeGiamDinhVienSHTT = body =>
  http.post(apiThemCapLaiTheGiamDinhVienSHCN, body)
const suaCapLaiTHeGiamDinhVienSHTT = body =>
  http.post(apiSuaCapLaiTheGiamDinhVienSHCN, body)

const themMoiToKhai38 = body => http.post(apiThemMoiToKhai38, body)
const suaToKhai38 = body => http.post(apiSuaToKhai38, body)

const themToKhai935 = body => http.post(apiInsert_CLGCNTCGDSHCN, body)
const suaToKhai935 = body => http.post(apiUpdate_CLGCNTCGDSHCN, body)
const themToKhai934 = body => http.post(apiInsert_CLGCNTCGDSHCN, body)
const suaToKhai934 = body => http.post(apiUpdate_CLGCNTCGDSHCN, body)
const themToKhai897 = body => http.post(apiInsertDossier42, body)
const suaToKhai897 = body => http.post(apiUpdateDossier42, body)
const updateSyncStatus = body => http.post(apiUpdateSyncStatus, body)
const DongBoDonLv4 = body => http.post(apiDongBoDonLv4, body)
const classifyGetList = body => http.post(apiClassifyGetList, body)
const classifyGetAllIpc = body => http.post(apiClassifyGetAllIpc, body)
const classifyGetListLocarno = body => http.post(apiGetListLocarno, body)
const classifyGetAllLocarno = body => http.post(apiGetAllLocarno, body)
const updatePayment = body => http.post(apiUpdatePayment, body)
const updateReceipt = body => http.post(apiUpdateReceipt, body)
const createDossierByID = params => http.get(apiCreateDossierByID, { params })
const capSoDonV4 = body => http.patch(apiCapSoDonV4, body)
const exportDossierOnl = body =>
  http.post(apiExportDossierOnl, body, {
    responseType: "blob",
  })

const getDetailDossier = body => http.post(apiGetDetailDossier, body)

const DossierService = {
  printPdfBarCodeHtml,
  getDetailDossier,
  exportDossierOnl,
  themDangKySangChe,
  themMoiToKhai38,
  suaToKhai38,
  yeuCauGhiNhanToChucDichVuDaiDienSHCN,
  suaYeuCauGhiNhanToChucDichVuDaiDienSHCN,
  insert_GNTDTTCTCDVDDSHCN,
  update_GNTDTTCTCDVDDSHCN,
  insert_GNNDDSHCON,
  update_GNNDDSHCON,
  insertCapCapLaiChungChiHanhNgheDVDaiDienSHCN,
  updateCapLaiChungChiHanhNgheDVDD,
  capNhatDangKySangChe,
  insertCapLaiOrCapPhoBangVBBH,
  updateCapLaiOrCapPhoBangVBBH,
  themDuyTriGiaHanVanBangBaoHoSangChe,
  suaDuyTriGiaHanVanBangBaoHoSangChe,
  getList,
  themDangKyChiDanDiaLy,
  capNhatDangKyChiDanDiaLy,
  themDangKyNhanHieu,
  suaDangKyNhanHieu,
  themDangKyKieuDangCongNghiep,
  capNhatDangKyKieuDangCongNghiep,
  themDangKyHopDongChuyenQuyenSHCN,
  capNhatDangKyHopDongChuyenQuyenSHCN,
  themDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN,
  capNhatDangKyHopDongChuyenQuyenSuDungDoiTuongSHCN,
  themDangKyThietKeBoTriMachTichHopBanDan,
  themDangKyYCSDND_GH_CDHLHD,
  capNhatYCSDND_GH_CDHLHD,
  isCheckFeeAndContent,
  getInforDossier,
  getInforListDossier,
  getListCreated,
  getAllDossierCate,
  updateStatus,
  updateListDossier,
  updateProfileCode,
  tinhPhiNopDon,
  getLogProgress,
  getTotalByTypeForKH,
  getTotalByTypeForAdmin,
  getTotalIsPaidByType,
  getTotalIsSignWaitByType,
  getTotalIsSignWaitReject,
  getTotalIsSignNewByType,
  deleteDosier,
  capNhatDangKyThietKeBoTriMachTichHopBanDan,
  exportDossier,
  exportPaymentInfo,
  getComboboxBank,
  importIpc,
  getAllIpc,
  getListRepresentative,
  capSoDon,
  printPdfBarCode,
  getListRepresent,
  getListHinhThucNop,
  signToResult,
  signToRejectResult,
  themToKhaiSuaDoiVBBH_DTSHCN,
  updateToKhaiSuaDoiVBBH_DTSHCN,
  yeuCauBatBuocChuyenGiaoQuyenSuDungSangChe,
  suaYeuCauBatBuocChuyenGiaoQuyenSuDungSangChe,
  themSuaDoiBoSungTachDonDangKySHCN,
  capNhatSuaDoiBoSungTachDonDangKySHCN,
  themToKhaiChuyenNhuongDonDangKySHCN,
  capNhatToKhaiChuyenNhuongDonDangKySHCN,
  them_TTDKDKTNVGDSHCN,
  capNhat_TTDKDKTNVGDSHCN,
  themDossier24,
  capNhatDossier24,
  insertCancelHieuLucVBBH,
  updateCancelHieuLucVBBH,
  insertYeuCauXoaTenToChucDichVuDaiDienSHCN,
  suaYeuCauXoaTenToChucDichVuDaiDienSHCN,
  insertYeuCauXoaTenNguoiDichVuDaiDienSHCN,
  suaYeuCauXoaTenNguoiDaiDienSHCN,
  gopToKhai,
  insert_DDCCSHSCVSCTTVCPLHDP,
  update_DDCCSHSCVSCTTVCPLHDP,
  themDangKyNhanHieuNguonGocVN,
  suaDangKyNhanHieuNguonGocVN,
  dangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu,
  suaDangKyNhanHieuDuocChuyenDoiTuDangKyQuocTeNhanHieu,
  inBarcode,
  getFileSignedBase64,
  themMoiToKhai42,
  capNhatToKhai42,
  themCapLaiTHeGiamDinhVienSHTT,
  suaCapLaiTHeGiamDinhVienSHTT,
  themToKhai935,
  suaToKhai935,
  themToKhai934,
  suaToKhai934,
  insertDossier30,
  updateDossier30,
  themToKhai897,
  suaToKhai897,
  classifyGetList,
  classifyGetAllIpc,
  classifyGetListLocarno,
  classifyGetAllLocarno,
  updateSyncStatus,
  DongBoDonLv4,
  updatePayment,
  updateReceipt,
  createDossierByID,
  capSoDonV4,
}

export default DossierService
