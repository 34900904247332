import { HubConnectionBuilder } from "@microsoft/signalr"
import { Spin } from "antd"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import STORAGE, { getStorage, setStorage } from "src/lib/storage"
import CommonService from "src/services/CommonService"
import "./App.scss"
import LoadingChunkFile from "./components/LoadingChunkFile"
import CB1 from "./components/Modal/CB1"
import ModalLoading from "./components/Modal/Loading"
import PrintPdfFromHtml from "./components/PrintPdfFromHtml"
import { ACCOUNT_TYPE_ID } from "./constants/constants"
import { StoreContext } from "./lib/store"
import { routeNav } from "./pages/USER/Declaration/component/routeNav"
import {
  getListSystemCate,
  getListSystemKey,
  setListTabs,
  setUserInfo,
} from "./redux/appGlobal"
import { setListDossier } from "./redux/dossier"
import { setPaymentSuccess, setReceiptUrl } from "./redux/payment"
import { setListProvince } from "./redux/region"
import AppRouter from "./router/AppRouter"
import GuestServices from "./services/GuestService"
import RoleService from "./services/RoleService"
import SystemCateService from "./services/SystemCateService"
import ModalErrorSign from "./pages/USER/ListProfile/components/SignCert/ModalErrorSign"
import Notice from "./components/Notice"
import { useLocation } from "react-router-dom"
import MenuItemBreadcrumb, {
  ListTitleRouter,
} from "./components/Layouts/MenuItems"
import ROUTER from "./router"

function App() {
  const isLogin = getStorage(STORAGE.TOKEN)
  const { listDossier } = useSelector(state => state.dossier)
  const location = useLocation()
  const { dossierUploading } = useSelector(state => state.uploadFileList)
  const retryTimes = [0, 1000, 3000, 5000, 10000, 30000, 60000]
  const [connection, setConnection] = useState()
  const dispatch = useDispatch()
  const { modalLoading, uploadFileLoading } = useSelector(state => state.common)
  const [loading, setLoading] = useState(false)
  const getList = async () => {
    try {
      const res = await GuestServices.getAllDossierCate()
      const resRegion = await GuestServices.getByRegionId({ regionId: 234 })
      if (res?.isError) return
      let data = res?.Object?.filter(item => !!item?.IsOnl)
      let listDossier = routeNav()
        .map((route, index) => {
          let routeinfo = data?.find(item => item.Prefix === route.Prefix)
          return {
            ...route,
            ...routeinfo,
            // name: routeinfo?.Abstract || route?.name,
          }
        })
        ?.filter(itemCate => !!itemCate?.DossierCateID)
      dispatch(setListDossier(listDossier))
      if (resRegion?.isError) return
      dispatch(setListProvince(resRegion?.Object))
    } finally {
    }
  }

  //=======================SignalR=========================
  // Trước khi bắt đầu bất kỳ nỗ lực kết nối lại nào,
  // HubConnection sẽ chuyển sang trạng HubConnectionState.Reconnectingthái và kích hoạt onreconnecting

  // Nếu máy khách kết nối lại thành công trong bốn lần thử đầu tiên,
  // HubConnectionsẽ chuyển trở lại Connectedtrạng thái và thực hiện onreconnectedcác lệnh gọi lại.

  // Nếu máy khách không kết nối lại thành công trong bốn lần thử đầu tiên,
  // HubConnectionsẽ chuyển sang Disconnectedtrạng thái và kích hoạt các lệnh gọi lại onclose của nó
  const start = async () => {
    try {
      await connection.start()
      console.log("<<<<<<SignalR Connected.>>>>>>>>")
    } catch (err) {
      console.log(err)
      setTimeout(start, 5000)
    }
  }
  useEffect(() => {
    const RESTFUL_BASE_URL =
      window.env?.BASE_URL || process.env.REACT_APP_API_WS || ""
    const connect = new HubConnectionBuilder()
      .withUrl(
        `${RESTFUL_BASE_URL}/signalrServer?Authorization=` +
          encodeURIComponent(isLogin),
        {
          headers: {
            Authorization: isLogin,
            // MaintenanceModeCode: getStorage(STORAGE.MAINTENANCE_CODE),
          },
        },
      )
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 3000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return 1000
          } else {
            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
            return 3000
          }
        },
      })
      .build()
    setConnection(connect)
  }, [])
  useEffect(() => {
    if (!!isLogin && !!connection) {
      connection.onreconnecting(error => {
        console.log("Đang kết nối lại SignalR")
      })
      connection.onreconnected(connectionId => {
        console.log("Kết nối lại SignalR  thành công")
      })
      connection.onclose(async () => {
        console.log("Kết nối lại SignalR không  thành công")
        // await start();
      })
      start()
      // NotifyMessage
      connection.on("NotifyMessage", message => {
        console.log("messageNo", message)
        // if (!!message.Data) {
        //   dispatch(setNotify(message?.Data))
        // }
      })
      connection.on("KEY_NOTIFY_PAYMENT", message => {
        console.log("KEY_NOTIFY_PAYMENT", message)
        if (message.connectedStatus) {
          dispatch(setReceiptUrl(message?.data))
          dispatch(setPaymentSuccess(true))
        }
        // if (!!message.data?.IsStatus) {
        //   if (statusPay !== true) {
        //     setStatusPay(true)
        //     setDataPay(message.data?.Message)
        //   }
        // }
      })
    }
  }, [isLogin, connection])
  // ===============================
  useEffect(() => {
    getList()
  }, [])
  useEffect(() => {
    if (!!isLogin) {
      getData()
      setStorage(STORAGE.DEV_MODE, !!window.env?.IS_DEV)
    }
  }, [isLogin])
  const getSystemKey = async () => {
    const res = await CommonService.getSystemKey("All")
    if (res.IsError) return
    dispatch(getListSystemKey(res.Object))
  }
  useEffect(() => {
    getSystemKey()
  }, [])

  const getSystemCate = async () => {
    const resSystem = await SystemCateService.getForCombobox()
    if (resSystem?.isError) return
    dispatch(getListSystemCate(resSystem.Object))
  }
  const getData = async () => {
    try {
      setLoading(true)
      dispatch(setUserInfo(getStorage(STORAGE.USER_INFO)))
      if (
        getStorage(STORAGE.USER_INFO)?.AccountType !== ACCOUNT_TYPE_ID.HocVien
      ) {
        const resp = await RoleService.getListTab()
        if (resp.isOk) {
          dispatch(setListTabs(resp.Object || []))
        }
      }
      getSystemCate()
    } finally {
      setLoading(false)
    }
  }

  const { uploadListFileStore } = useContext(StoreContext)
  const [
    uploadFileList,
    updateListFileAddFile,
    deleteUploadFileList,
    updateListFileAddListFile,
    deleteListUploadFileList,
  ] = uploadListFileStore
  const { loadingPercent } = useSelector(state => state.uploadFileList)
  const [keydown, setKeyDown] = useState()
  const handleF5 = () => {
    CB1({
      title:
        "Đang có file tải dở hoặc tải lên lỗi, các tài liệu này sẽ bị mất đi nếu bạn reload lại trang web. Bạn có chắc chắn vẫn muốn reload lại trang web không?",
      icon: "trashRed",
      okText: "Đồng ý",
      onOk: () => {
        window.location.reload()
      },
    })
  }
  // const checkKeyDown = keydown => {
  //   switch (keydown.key) {
  //     case "F5":
  //       handleF5()
  //       break
  //     default:
  //       break
  //   }
  // }
  // useEffect(() => {
  //   if (keydown?.key) checkKeyDown(keydown)
  // }, [keydown])
  // useEffect(() => {
  //   const getKeyDown = e => {
  //     if (["F5"]?.includes(e?.key)) {
  //       let listLoadingFileID = uploadFileList?.filter(
  //         itemFile =>
  //           // !loadingPercent?.[itemFile?.ID]?.IsError &&
  //           // !loadingPercent?.[itemFile?.ID]?.IsErrorUpFileApi &&
  //           !loadingPercent?.[itemFile?.ID]?.IsSuccess,
  //       )
  //       if (listLoadingFileID?.length) {
  //         e?.preventDefault()
  //         e?.stopPropagation()
  //         setKeyDown(e)
  //       }
  //       // else window.location.reload()
  //     }
  //   }
  //   window.addEventListener("keydown", e => getKeyDown(e), true)

  //   return () => window.removeEventListener("keydown", e => getKeyDown(e))
  // }, [])
  useEffect(() => {
    const handleBeforeUnload = event => {
      if (
        !isLogin &&
        ![
          "/dich_vu/ho-so-cho-xu-ly",
          "/ho-tro",
          "/payment-success",
          ROUTER.HOME,
        ]?.includes(location?.pathname)
      ) {
        // Do các trình duyệt đề phòng các trang lừa đảo nên không cho custon messege
        event.preventDefault()
        event.returnValue = ""
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [location?.pathname])

  const getPageName = pathname => {
    const listTitle = listDossier?.length ? listDossier : routeNav()
    const titlePropertyName = listDossier?.length ? "Abstract" : "name"
    const pageName =
      ListTitleRouter()?.find(item => pathname === item?.key)?.label ||
      listTitle?.find(item => pathname === item?.Router)?.[titlePropertyName]
    return !!pageName ? pageName : "Cục sở hữu trí tuệ"
  }
  useEffect(() => {
    document.title = getPageName(location?.pathname)
  }, [location?.pathname])
  return (
    <div className="layout-center">
      <div className="layout-max-width">
        <Spin spinning={loading} fullscreen />
        <AppRouter />
      </div>
      {!!modalLoading && <ModalLoading />}
      <LoadingChunkFile />
      <PrintPdfFromHtml />
    </div>
  )
}

export default App
