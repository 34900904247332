import { createSlice } from "@reduxjs/toolkit"
import { setLoadingPage } from "./payment"
import moment from "moment"
import dossier from "./dossier"

const initialState = {
  uploadFileList: [],
  loadingPercent: {},
  listFormUploadFile: {},
  loadingUpload: { loading: false, reload: -1 },
  dossierUploading: [],
}
const checkTimeRemainingDifference = (timeRemaining, oldData) => {
  if (timeRemaining > 60) {
    if (
      !!(
        timeRemaining - oldData?.TimeRemaining > 0 &&
        timeRemaining - oldData?.TimeRemaining < 90
      )
    )
      return true
  } else if (
    !!(
      timeRemaining - oldData?.TimeRemaining > 0 &&
      timeRemaining - oldData?.TimeRemaining < 2
    )
  )
    return true

  return false
}
const checkTimeRemaining = ({ oldData, ID, data }) => {
  const dataPercent = { ...data, number: (oldData?.number || 0) + 1 }
  if (!data?.IsError) {
    if (+data?.Percent <= 100) {
      const startTime = moment(dataPercent?.startTime)
      let timeRemaining = moment().diff(startTime, "seconds")
      timeRemaining = timeRemaining / +dataPercent?.Percent
      timeRemaining = timeRemaining * (100 - +dataPercent?.Percent)
      timeRemaining = timeRemaining ? timeRemaining.toFixed(0) : 0
      //Tránh TimeRemaining bị nhảy ngược nên phải kiểm tra với TimeRemaining cũ
      if (!!checkTimeRemainingDifference(timeRemaining, oldData))
        timeRemaining = oldData?.TimeRemaining

      return {
        [ID]: {
          ...dataPercent,
          TimeRemaining:
            dataPercent?.number % 4 === 0
              ? timeRemaining
              : oldData?.TimeRemaining,
          PercentRemaining: +data?.Percent,
        },
      }
    } else {
      return {
        [ID]: {
          ...dataPercent,
          TimeRemaining: 0,
          PercentRemaining: +data?.Percent,
        },
      }
    }
  } else
    return {
      [ID]: data,
    }
}
export const uploadFileListSlice = createSlice({
  name: "uploadFileList",
  initialState,
  reducers: {
    addFormUploadFile: (state, action) => {
      if (state.listFormUploadFile[action.payload] !== true)
        state.listFormUploadFile = {
          ...state.listFormUploadFile,
          [action.payload]: true,
        }
    },
    deleteFormUploadFile: (state, action) => {
      state.listFormUploadFile = {
        ...state.listFormUploadFile,
        [action.payload]: false,
      }
    },
    reloadLoadingPercent: (state, action) => {
      state.loadingPercent = {
        ...state.loadingPercent,
        reload: (state.loadingPercent?.reload || 0) + 1,
      }
    },
    setLoadingUpload: (state, action) => {
      state.loadingUpload = {
        ...state.loadingUpload,
        ...action.payload,
      }
    },
    setDossierUploading: (state, action) => {
      state.dossierUploading = [...state.dossierUploading, action.payload]
    },

    deleteDossierUploading: (state, action) => {
      state.dossierUploading = state.dossierUploading.filter(
        item => item !== action.payload,
      )
    },

    setUploadFileList: (state, action) => {
      state.uploadFileList = [action.payload, ...state.uploadFileList]
    },
    deleteUploadFileList: (state, action) => {
      state.uploadFileList = state.uploadFileList?.filter(
        item => item?.ID !== action.payload,
      )
    },
    setLoadingPercent: (state, action) => {
      const { ID, data, noLoading = false, delay = false } = action.payload
      if (!!noLoading) {
        state.loadingPercent[ID] = {
          // ...state.loadingPercent[ID],
          ...data,
          noLoading,
        }
      } else {
        if (data?.IsError || data?.IsSuccess)
          state.loadingUpload = {
            ...state.loadingUpload,
            ...data,
            loading: false,
            reload: -1,
            ID: ID,
          }

        const newLoadingPercent = checkTimeRemaining({
          oldData: state.loadingPercent[ID],
          ID,
          data,
        })
        if (!delay) {
          state.loadingPercent[ID] = {
            ...state.loadingPercent[ID],
            ...newLoadingPercent[ID],
          }
        } else if (
          state.loadingPercent[ID]?.compareTime &&
          moment().diff(
            moment(state.loadingPercent[ID]?.compareTime),
            "seconds",
          ) < 3
        ) {
        } else {
          state.loadingPercent[ID] = {
            ...state.loadingPercent[ID],
            ...newLoadingPercent[ID],
          }
        }
      }
    },
    deleteLoadingPercent: (state, action) => {
      const ID = action.payload
      let startData = { ...state.loadingPercent }
      delete startData[ID]
      state.loadingPercent = startData
    },
    resetLoadingPercent: (state, action) => {
      const ID = action.payload
      let startData = { ...state.loadingPercent }
      let startUpload = { [ID]: { DossierID: startData[ID]?.DossierID } }
      delete startData[ID]
      state.loadingPercent = { ...startData, ...startUpload }
      state.listFormUploadFile = {
        ...state.listFormUploadFile,
        [startData[ID]?.DossierID]: true,
      }
    },
    deleteListLoadingPercent: (state, action) => {
      let startData = { ...state.loadingPercent }
      let ListID = action.payload
      for (let index = 0; index < ListID.length; index++) {
        const ID = ListID[index]
        delete startData[ID]
      }
      state.loadingPercent = startData
    },
  },
})

export const {
  addFormUploadFile,
  deleteFormUploadFile,
  reloadLoadingPercent,
  resetLoadingPercent,
  setLoadingUpload,
  setDossierUploading,
  deleteDossierUploading,
  setUploadFileList,
  setLoadingPercent,
  deleteUploadFileList,
  deleteLoadingPercent,
  deleteListLoadingPercent,
} = uploadFileListSlice.actions

export default uploadFileListSlice.reducer
