import { CloseCircleFilled, ReloadOutlined } from "@ant-design/icons"
import { Progress, Tooltip } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SvgIcon from "src/components/SvgIcon"
import { StoreContext } from "src/lib/store"
import {
  addFormUploadFile,
  deleteLoadingPercent,
  resetLoadingPercent,
  setLoadingUpload,
} from "src/redux/uploadFileList"

const UploadChunkItem = ({ data, listFileUploaded, setListFileUploaded }) => {
  const dispatch = useDispatch()
  const { loadingPercent, loadingUpload } = useSelector(
    state => state.uploadFileList,
  )
  const { uploadListFileStore } = useContext(StoreContext)
  const [
    uploadFileList,
    updateListFileAddFile,
    deleteUploadFileList,
    updateListFileAddListFile,
    deleteListUploadFileList,
  ] = uploadListFileStore
  // const [timeRemaining, setTimeRemaining] = useState(data?.size)
  // const [percentRemaining, setPercentRemaining] = useState(0)
  let percentRemaining = loadingPercent?.[data?.ID]?.PercentRemaining
  let timeRemaining =
    +loadingPercent?.[data?.ID]?.TimeRemaining === 0
      ? 0
      : +loadingPercent?.[data?.ID]?.TimeRemaining || data?.size / 1000000

  //Convert thời gian còn lại
  //Lớn hơn 60s là phút
  //Nhỏ hơn 60 giây là giây
  const getSecondsRemaining = () => {
    if (timeRemaining < 0) return false
    if ((timeRemaining > 0 ? timeRemaining : 0) < 60)
      return `${timeRemaining > 0 ? timeRemaining.toFixed(2) : 0} giây`
    let secondsRemaining = (timeRemaining > 0 ? timeRemaining : 0) / 60 || 0
    if (secondsRemaining > 1) secondsRemaining = secondsRemaining.toFixed(0)
    else secondsRemaining = secondsRemaining.toFixed(2)
    return `${secondsRemaining} phút`
  }

  // useEffect(() => {
  //   //Khởi tạo thời gian còn lại bằng thời gian ước đoán từ dung lượng file
  //   setTimeRemaining(data?.size / 100000)
  // }, [])

  // useEffect(() => {
  //   //TimeRemaining: thời gian còn lại
  //   //PercentRemaining: phằn trăm còn lại của file chưa tải hết
  //   if (loadingPercent) {
  //     // setTimeRemaining(loadingPercent?.[data?.ID]?.TimeRemaining)
  //     // setPercentRemaining(loadingPercent?.[data?.ID]?.PercentRemaining)
  //   }
  // }, [loadingPercent])

  const setIconFile = type => {
    switch (type) {
      case "pdf":
        return "file-pdf"
      case "doc":
      case "docx":
        return "file-doc"
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
        return "small-image-red"
      default:
        return "iconPdf"
    }
  }
  return (
    <div className="d-flex-sb mb-6  mt-6 close-box" style={{ gap: "8px" }}>
      <div className="d-flex-start">
        <SvgIcon
          name={setIconFile(data?.type?.split("/")[1])}
          className="mr-8"
        />{" "}
        <div className={"d-flex-start no-wrap"}>
          <div
            className={`max-line1 ${
              !!getSecondsRemaining() &&
              !(
                loadingPercent?.[data?.ID]?.IsError ||
                loadingPercent?.[data?.ID]?.IsErrorUpFileApi
              ) &&
              !loadingPercent?.[data?.ID]?.IsSuccess
                ? "is-time-remaining"
                : "not-time-remaining"
            }`}
            style={{
              display: "block",
            }}
          >
            {data?.name}
          </div>
          {!!getSecondsRemaining() &&
            !(
              loadingPercent?.[data?.ID]?.IsError ||
              loadingPercent?.[data?.ID]?.IsErrorUpFileApi
            ) &&
            !loadingPercent?.[data?.ID]?.IsSuccess && (
              <div className="ml-4">- Còn {getSecondsRemaining()} </div>
            )}
        </div>
      </div>
      <div
        className="d-flex-end "
        style={{
          background: "#fff",
          marginRight: !(
            !!(
              loadingPercent?.[data?.ID]?.IsError ||
              loadingPercent?.[data?.ID]?.IsErrorUpFileApi
            ) || !loadingPercent?.[data?.ID]?.Percent
          )
            ? 8
            : 0,
        }}
      >
        <div className="d-flex-center mr-8">
          <Progress
            strokeLinecap="butt"
            type="circle"
            status={
              loadingPercent?.[data?.ID]?.IsError ||
              loadingPercent?.[data?.ID]?.IsErrorUpFileApi
                ? "exception"
                : undefined
            }
            percent={
              loadingPercent?.[data?.ID]?.IsError ||
              loadingPercent?.[data?.ID]?.IsErrorUpFileApi ||
              loadingPercent?.[data?.ID]?.IsSuccess
                ? 100
                : +percentRemaining
            }
            size={30}
            showInfo={true}
            format={
              !loadingPercent?.[data?.ID]?.IsSuccess
                ? number =>
                    loadingPercent?.[data?.ID]?.IsError ||
                    loadingPercent?.[data?.ID]?.IsErrorUpFileApi
                      ? "Lỗi"
                      : number < 100
                      ? `${number.toFixed(1)}%`
                      : `${number.toFixed(0)}%`
                : undefined
            }
          />
        </div>
        <div className="d-flex-end" style={{ gap: "8px" }}>
          {(!!(
            loadingPercent?.[data?.ID]?.IsError ||
            loadingPercent?.[data?.ID]?.IsErrorUpFileApi
          ) ||
            !loadingPercent?.[data?.ID]?.Percent) && (
            <div
              className=" close-icon d-flex-center "
              onClick={() => {
                //Xóa file trong context
                // deleteUploadFileList(data?.ID, true)
                //Xóa phần trăm tải của file đã lưu trong redux
                dispatch(resetLoadingPercent(data?.ID))
                //Xóa ID file đã lưu lúc hoàn thành hoặc tải lỗi
                setListFileUploaded(pre =>
                  pre?.filter(item => item !== data?.ID),
                )
                dispatch(addFormUploadFile(data?.DossierID))
                // //Reload
                dispatch(
                  setLoadingUpload({
                    IsSuccess: undefined,
                    IsError: undefined,
                    IsErrorUpFileApi: undefined,
                    reload: -3,
                  }),
                )
              }}
            >
              <Tooltip title="Upload lại" mouseEnterDelay={1}>
                <ReloadOutlined
                  className="pointer"
                  style={{
                    color: "#0d99ff",
                    fontSize: "26px",
                    cursorL: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          )}
          {!!(
            loadingPercent?.[data?.ID]?.IsError ||
            loadingPercent?.[data?.ID]?.IsErrorUpFileApi
          ) && (
            <div
              className=" close-icon d-flex-center "
              onClick={() => {
                //Xóa file trong context
                deleteUploadFileList(data?.ID)
                //Xóa phần trăm tải của file đã lưu trong redux
                dispatch(deleteLoadingPercent(data?.ID))
                //Xóa ID file đã lưu lúc hoàn thành hoặc tải lỗi
                setListFileUploaded(pre =>
                  pre?.filter(item => item?.ID !== data?.ID),
                )
              }}
            >
              <Tooltip title="Xóa" mouseEnterDelay={1}>
                <CloseCircleFilled
                  className="pointer"
                  style={{
                    color: "#e50500",
                    fontSize: "26px",
                    cursorL: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadChunkItem
