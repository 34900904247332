import { Col, Form, Row, Select } from "antd"
import { useState, useEffect } from "react"
import {
  getRegexEmail,
  getRegexMobile,
  regexIDCard,
} from "src/lib/stringsUtils"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"

import { ACCOUNT_TYPE_KH, SYSTEM_KEY, VN_CODE } from "src/constants/constants"
import { RedStar } from "src/components/FloatingLabel/styled"
import { useSelector } from "react-redux"
import { getListComboByKey } from "src/lib/utils"
const DeclarationOwner = ({ isView, form }) => {
  const [value, setValue] = useState(0)
  const { userInfo } = useSelector(state => state?.appGlobal)
  const owner = isView?.ApplicationOwner?.find(item => item?.IsMasterProfile)
  const { listSystemCate } = useSelector(state => state.appGlobal)
  const { listProvince } = useSelector(state => state.region)

  useEffect(() => {
    if (
      ACCOUNT_TYPE_KH?.includes(userInfo?.AccountType) ||
      userInfo?.AccountType === 9
    ) {
      form.setFieldsValue({
        OwnerFullName: userInfo?.FullName,
        // OwnerPosition: userInfo?.Position,
        OwnerAddress: userInfo?.Address,
        OwnerPhone: userInfo?.PhoneNumber,
        OwnerEmail: userInfo?.Email,
        NumberCCCD: userInfo?.Identification,
        Country: userInfo?.OwnerCountry,
        ProvinceID: userInfo?.OwnerProvinceID,
      })
    }
  }, [])

  return (
    <>
      <div className="declaration-shadow-box p-24">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="d-flex-start mb-8">
              <div style={{ width: "100%" }}>
                <div className="text-center fw-600 title-box  title-header-color">
                  NGƯỜI NỘP ĐƠN
                </div>
                <div className="text-center">
                  (Cá nhân đơn dưới đây đề nghị được tham dự kiểm tra nghiệp vụ
                  đại diện dở hữu công nghiệp)
                </div>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <Form.Item
              name="OwnerFullName"
              rules={[
                { required: true, message: "Thông tin không được để trống" },
              ]}
            >
              <FlInput borderBottomOnly isRequired label="Tên đầy đủ" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              // className="mb-10"
              name="OwnerNumberCCCD"
              rules={[
                { required: true, message: "Thông tin không được để trống" },
                {
                  pattern: regexIDCard(),
                  message: "Số căn cước công dân nhập sai định dạng!",
                },
              ]}
            >
              <FlInput
                borderBottomOnly
                isRequired
                label="Số chứng minh nhân dân/căn cước công dân"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="OwnerCountry"
              rules={[
                { required: true, message: "Thông tin không được để trống" },
              ]}
            >
              <FlSelect borderBottomOnly label="Quốc gia" isRequired showSearch>
                {getListComboByKey(SYSTEM_KEY?.QUOC_GIA, listSystemCate)?.map(
                  item => (
                    <Select.Option value={item?.ID}>
                      {item?.Description}
                    </Select.Option>
                  ),
                )}
              </FlSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                if (
                  getListComboByKey(SYSTEM_KEY?.QUOC_GIA, listSystemCate).find(
                    i => i.ID === getFieldValue("OwnerCountry"),
                  )?.CodeKey === VN_CODE
                ) {
                  return (
                    <Form.Item
                      name="OwnerProvinceID"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <FlSelect
                        borderBottomOnly
                        label="Tỉnh/Thành"
                        isRequired
                        showSearch
                      >
                        {listProvince.map(i => (
                          <Select.Option value={i?.RegionID} key={i?.RegionID}>
                            {i?.RegionName}
                          </Select.Option>
                        ))}
                      </FlSelect>
                    </Form.Item>
                  )
                }
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="OwnerPhone"
              rules={[
                {
                  pattern: getRegexMobile(),
                  message: "Số điện thoại là chuỗi từ 8 đến 15 kí tự chữ số",
                },
              ]}
            >
              <FlInput borderBottomOnly label="Điện thoại" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="OwnerEmail"
              rules={[
                {
                  pattern: getRegexEmail(),
                  message: "Email sai định dạng",
                },
              ]}
            >
              <FlInput borderBottomOnly label="Email" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default DeclarationOwner
