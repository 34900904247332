import {
  CloseOutlined,
  CloudUploadOutlined,
  DownOutlined,
  DownloadOutlined,
} from "@ant-design/icons"
import { Collapse, FloatButton } from "antd"
import _ from "lodash"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreContext } from "src/lib/store"
import {
  addFormUploadFile,
  deleteDossierUploading,
  deleteFormUploadFile,
  deleteListLoadingPercent,
  setDossierUploading,
  setLoadingPercent,
  setLoadingUpload,
} from "src/redux/uploadFileList"
import FileService from "src/services/FileService"
import Warning from "../Modal/Warning"
import UploadChunk from "./components/UploadChunk"
import { onFileChange } from "./functionsCommon"
import { LoadingChunkFileStyle } from "./styled"
const LoadingChunkFile = () => {
  const dispatch = useDispatch()
  const { uploadListFileStore } = useContext(StoreContext)
  const [
    uploadFileList,
    updateListFileAddFile,
    deleteUploadFileList,
    updateListFileAddListFile,
    deleteListUploadFileList,
  ] = uploadListFileStore
  const { loadingPercent, loadingUpload, dossierUploading } = useSelector(
    state => state.uploadFileList,
  )
  const [openPopup, setOpenPopup] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [listFileUploaded, setListFileUploaded] = useState([])
  const [listIDFileTus, setListIDFileTus] = useState([])
  let numberNotInListFileUploaded = uploadFileList?.filter(
    item =>
      !(
        loadingPercent?.[item?.ID]?.IsError ||
        loadingPercent?.[item?.ID]?.IsErrorUpFileApi
      ) && !listFileUploaded?.includes(item?.ID),
  )?.length
  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex-sb" style={{ width: "100%" }}>
          <div>
            {!!numberNotInListFileUploaded ? "Đang tải " : "Đã tải "}
            {
              !!numberNotInListFileUploaded
                ? numberNotInListFileUploaded
                : uploadFileList?.filter(
                    item => !!loadingPercent?.[item?.ID]?.IsSuccess,
                  )?.length
              // uploadFileList?.filter(
              //     item => !!listFileUploaded?.includes(item?.ID),
              //   )?.length
            }
            {" / "}
            {uploadFileList?.length}
            {/* mục lên */}
          </div>
          <button
            className="ex-button pointer"
            onClick={() => setCollapse(pre => !pre)}
          >
            <DownOutlined />
          </button>
        </div>
      ),
      children: (
        <UploadChunk
          listFileUploaded={listFileUploaded}
          setListFileUploaded={setListFileUploaded}
          data={_.sortBy(uploadFileList, [
            o => {
              return loadingPercent?.[o?.ID]?.Percent === 100
            },
          ])}
        />
      ),

      // extra: (
      //   <CloseOutlined
      //     onClick={() => {
      //       if (!!uploadFileList && uploadFileList?.length) {
      //         let listDeleteID =
      //           // uploadFileList
      //           //   ?.filter(item => item)
      //           //   ?.map(itemFile => itemFile?.ID)
      //           uploadFileList
      //             ?.filter(itemFile => {
      //               // !loadingPercent?.[itemFile?.ID]?.IsError &&
      //               // !loadingPercent?.[itemFile?.ID]?.IsErrorUpFileApi &&
      //               return !!loadingPercent?.[itemFile?.ID]?.IsSuccess
      //             })
      //             ?.map(itemFile => itemFile?.ID)
      //         // let listDeleteID = uploadFileList
      //         // ?.filter(item => item)
      //         // ?.map(
      //         //   itemFile =>
      //         //     loadingPercent?.[itemFile?.ID]?.IsError ||
      //         //     loadingPercent?.[itemFile?.ID]?.IsErrorUpFileApi ||
      //         //     loadingPercent?.[itemFile?.ID]?.IsSuccess,
      //         // )
      //         //Xóa file trong context
      //         deleteListUploadFileList(listDeleteID)
      //         //Xóa phần trăm tải của file đã lưu trong redux
      //         dispatch(deleteListLoadingPercent(listDeleteID))
      //         //Xóa ID file đã lưu lúc hoàn thành hoặc tải lỗi
      //         setListFileUploaded(pre =>
      //           pre?.filter(item => !listDeleteID?.includes(item?.ID)),
      //         )
      //       }
      //       setOpenPopup(pre => false)
      //     }}
      //   />
      // ),
      showArrow: false,
    },
  ]
  useEffect(() => {
    let listFileWillUpload = uploadFileList?.filter(
      item => !listFileUploaded?.includes(item?.ID),
    )
    if (listFileWillUpload?.length) {
      if (!openPopup) setOpenPopup(pre => true)

      //reload sẽ là -1 khi bắt đầu hoặc vừa tải file thành công hoặc thất bại
      if (
        !loadingUpload?.loading &&
        loadingUpload?.reload === -1 &&
        listFileWillUpload[0]?.ID !== loadingUpload?.loading
      )
        dispatch(setLoadingUpload({ loading: false, reload: 0 }))
    }
  }, [uploadFileList?.length])

  useEffect(() => {
    if (!loadingUpload?.loading) {
      //Danh sách file chưa upload
      let listFileWillUpload = uploadFileList?.filter(item => {
        const checkInListFileUploaded = !listFileUploaded?.includes(item?.ID)
        //Khi up thành công hoặc thất bị thì loadingUpload đã được set
        //nhưng do listFileUploaded chưa ăn ngay luôn ID file đấy
        //nên phải check thêm ID file đã upload thành công hoặc thất bại đấy "loadingUpload?.ID"
        if (!!(loadingUpload?.IsError || loadingUpload?.IsSuccess))
          return checkInListFileUploaded && item?.ID !== loadingUpload?.ID
        else return checkInListFileUploaded
      })
      if (listFileWillUpload?.length) {
        let uploadFile = listFileWillUpload[0]
        dispatch(setLoadingUpload({ loading: uploadFile?.ID, reload: 1 }))
        dispatch(setDossierUploading(uploadFile?.DossierID))
        dispatch(addFormUploadFile(uploadFile?.DossierID))
        onFileChange({
          file: uploadFile?.file,
          ID: uploadFile?.ID,
          setLoadingPercent: value => dispatch(setLoadingPercent(value)),
          setListFileUploaded,
          FileObjectID: uploadFile?.ObjectID,
          setListIDFileTus,
          DossierID: uploadFile?.DossierID,
          NumberPage: uploadFile?.file?.NumberPage,
        })
      }
    }
  }, [loadingUpload?.reload])
  const uploadChunkingFile = async listIDFileTus => {
    try {
      const res = await FileService.uploadChunkingFile({
        FileIDList: [
          {
            FileID: listIDFileTus?.IDFileTus,
            FileName: listIDFileTus?.name,
            NumberPage: listIDFileTus?.NumberPage,
          },
        ],
        GuidID: listIDFileTus?.ObjectID,
      })
      if (res?.isError) {
        setListFileUploaded(pre =>
          pre?.filter(item => item !== listIDFileTus?.ID),
        )
        dispatch(
          setLoadingPercent({
            ID: listIDFileTus?.ID,
            data: {
              DossierID: listIDFileTus?.DossierID,
              TimeRemaining: 0,
              Percent: 100,
              IsSuccess: false,
              IsError: true,
              IsErrorUpFileApi: true,
              IsApi: "error",
            },
            noLoading: true,
          }),
        )
        return
      }

      dispatch(
        setLoadingPercent({
          ID: listIDFileTus?.ID,
          data: {
            DossierID: listIDFileTus?.DossierID,
            TimeRemaining: 0,
            Percent: 100,
            IsSuccess: true,
            IsApi: "success",
          },
          noLoading: true,
        }),
      )
    } catch (error) {
      console.log("errrUploadChunkingFile", error)
    } finally {
      dispatch(deleteDossierUploading(listIDFileTus?.DossierID))
    }
  }
  useEffect(() => {
    if (
      !!listIDFileTus?.ID &&
      listIDFileTus?.ObjectID &&
      loadingPercent?.[listIDFileTus?.ID]?.IsSuccess
    ) {
      uploadChunkingFile(listIDFileTus)
    }
  }, [listIDFileTus])

  const [openWarming, setOpenWarming] = useState(false)
  //Chặn reload trang nếu đang tải dở, tuy nhiên chưa chặn được khi mở modal nên đang tắt
  // useEffect(() => {
  //   const getKeyDown = e => {
  //     if (e?.key === "F5") {
  //       e?.preventDefault()
  //       e?.stopPropagation()
  //       if (!!dossierUploading) {
  //         setOpenWarming(true)
  //       } else {
  //         window.location.reload()
  //       }
  //     }
  //   }
  //   window.addEventListener("keydown", e => getKeyDown(e), true)

  //   return () => window.removeEventListener("keydown", e => getKeyDown(e))
  // }, [dossierUploading])
  return !!openPopup ? (
    <LoadingChunkFileStyle>
      {!collapse ? (
        <Collapse
          items={items}
          expandIconPosition={"end"}
          defaultActiveKey={[1]}
          activeKey={collapse ? [] : [1]}
        />
      ) : (
        <FloatButton
          icon={<CloudUploadOutlined />}
          tooltip="Danh sách file tải lên"
          onClick={() => setCollapse(pre => !pre)}
        />
        // <button
        //   className="ex-button pointer"
        //   onClick={() => setCollapse(pre => !pre)}
        // >
        //   <DownloadOutlined />
        // </button>
      )}
      {!!openWarming && !!dossierUploading && (
        <Warning
          isOpen={!!openWarming}
          content={{
            title:
              "Các tài liệu đang được tải sẽ thất bại nếu tải lại trang, bạn có chắc vẫn muốn tải lại trang không?",
          }}
          onOk={() => {
            window.location.reload()
          }}
          onCancel={e => {
            setOpenWarming(false)
          }}
        />
      )}
    </LoadingChunkFileStyle>
  ) : (
    <></>
  )
}

export default LoadingChunkFile
