export const LIST_FEE_ID = {
  // 1	Lệ phí nộp đơn (gồm cả đơn tách, đơn chuyển đổi)
  FeeID_1: 1, //A
  // 2	Lệ phí yêu cầu gia hạn thời hạn trả lời thông báo của Tổ chức thu phí, lệ phí (mỗi lần được phép gia hạn)
  FeeID_2: 2, //AC
  // 3	Lệ phí cấp Văn bằng bảo hộ
  FeeID_3: 3, //AL
  // 4	Lệ phí cấp VBBH cho điểm YCBH thứ 2 trở đi
  FeeID_4: 4, //AM
  // 5	Lệ phí cấp Giấy chứng nhận đăng ký Hợp đồng chuyển quyền sử dụng quyền sở hữu công nghiệp
  FeeID_5: 5, //AR
  // 6	Lệ phí duy trì hiệu lực Văn bằng bảo hộ sáng chế, giải pháp hữu ích
  FeeID_6: 6, //AS
  // 7	Lệ phí duy trì/gia hạn hiệu lực muộn (cho mỗi tháng nộp muộn)
  FeeID_7: 7, //AT
  // 8	Lệ phí yêu cầu chấm dứt/hủy bỏ hiệu lực Văn bằng bảo hộ
  FeeID_8: 8, //B
  // 9	Lệ phí cấp Chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp
  FeeID_9: 9, //BC
  // 10	Lệ phí công bố quyết định cấp Chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp
  FeeID_10: 10, //BM
  // 11	Lệ phí đăng bạ Quyết định cấp Chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp
  FeeID_11: 11, //BR
  // 12	Phí thẩm định đơn đăng ký sở hữu công nghiệp
  FeeID_12: 12, //BS
  // 13	Phí thẩm định nội dung bản mô tả có trên 6 trang (từ trang thứ 7 trở đi)
  FeeID_13: 13, //BT
  // 14	Phí phân loại Quốc tế
  FeeID_14: 14, //H
  // 15	Nếu mỗi nhóm có trên 6 sản phẩm/dịch vụ, phải nộp thêm cho mỗi sản phẩm/dịch vụ từ thứ 7 trở đi
  FeeID_15: 15, //P
  // 16	Phí thẩm định yêu cầu hưởng quyền ưu tiên (mỗi đơn/yêu cầu)
  FeeID_16: 16, //PC
  // 17	Phí thẩm định yêu cầu sửa đổi đơn đăng ký sở hữu công nghiệp
  FeeID_17: 17, //PCT_VN
  // 18	Phí thẩm định yêu cầu chuyển nhượng đơn (mỗi đơn đăng ký)
  FeeID_18: 18, //PL
  // 19	Phí thẩm định hồ sơ đơn đăng ký chuyển giao quyền sở hữu công nghiệp (mỗi văn bằng bảo hộ)
  FeeID_19: 19, //PM
  // 20	Phí thẩm định yêu cầu gia hạn, duy trì, sửa đổi văn bằng bảo hộ
  FeeID_20: 20, //PS
  // 21	Phí thẩm định yêu cầu chấm dứt hiệu lực văn bằng bảo hộ (mỗi văn bằng bảo hộ)
  FeeID_21: 21, //PT
  // 22	Phí thẩm định yêu cầu hủy bỏ hiệu lực văn bằng bảo hộ (mỗi văn bằng bảo hộ)
  FeeID_22: 22, //BL
  // 23	Phí thẩm định hồ sơ kiểm tra nghiệp vụ đại diện sở hữu công nghiệp, nghiệp vụ giám định sở hữu công nghiệp (mỗi môn)
  FeeID_23: 23, //PR

  // 24	Phí phúc tra kết quả kiểm tra nghiệp vụ đại diện sở hữu công nghiệp, nghiệp vụ giám định sở hữu công nghiệp (mỗi môn)
  FeeID_24: 24,
  // 25	Phí thẩm định Hồ sơ yêu cầu cấp Chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp
  FeeID_25: 25,
  //  26	Phí giải quyết yêu cầu phản đối cấp Văn bằng bảo hộ của người thứ ba
  FeeID_26: 26,
  // 27	Phí tra cứu thông tin nhằm phục vụ việc thẩm định, giải quyết khiếu nại và các công việc khác trong phạm vi trách nhiệm
  FeeID_27: 27,
  // 28	Nếu đối tượng tra cứu là nhãn hiệu có trên 6 sản phẩm/dịch vụ trong một nhóm, phải nộp thêm cho mỗi sản phẩm/dịch vụ từ thứ 7 trở đi
  FeeID_28: 28,
  // 29	Phí công bố thông tin về sở hữu công nghiệp
  FeeID_29: 29,
  // 30	Nếu có trên 1 hình, từ hình thứ hai trở đi phải nộp thêm cho mỗi hình
  FeeID_30: 30,
  // 31	Nếu bản mô tả sáng chế có trên 6 trang, từ trang thứ 7 trở đi phải nộp thêm cho mỗi trang
  FeeID_31: 31,
  // 32	Phí đăng bạ thông tin về sở hữu công nghiệp
  FeeID_32: 32,
  // 33	Phí sử dụng Văn bằng bảo hộ
  FeeID_33: 33,
  // 34	Năm thứ 1; Năm thứ 2 đối với sáng chế, giải pháp hữu ích
  FeeID_34: 34,
  // 35	Năm thứ 3; Năm thứ 4 đối với sáng chế, giải pháp hữu ích
  FeeID_35: 35,
  // 36	Năm thứ 5; Năm thứ 6 đối với sáng chế, giải pháp hữu ích
  FeeID_36: 36,
  // 37	Năm thứ 7; Năm thứ 8 đối với sáng chế, giải pháp hữu ích
  FeeID_37: 37,
  // 38	Năm thứ 9; Năm thứ 10 đối với sáng chế, giải pháp hữu ích
  FeeID_38: 38,
  // 39	Năm thứ 11 - Năm thứ 13 đối với sáng chế, giải pháp hữu ích
  FeeID_39: 39,
  // 40	Năm thứ 14 - Năm thứ 16 đối với sáng chế, giải pháp hữu ích
  FeeID_40: 40,
  // 41	Năm thứ 17 - Năm thứ 20 đối với sáng chế, giải pháp hữu ích
  FeeID_41: 41,
  // 42	Phí kiểm tra sơ bộ về mặt hình thức đơn sáng chế quốc tế để nộp cho văn phòng quốc tế và cơ quan tra cứu quốc tế
  FeeID_42: 42,
  // 43	Phí thực hiện thủ tục đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam - không bao gồm các khoản phí phải nộp cho Văn phòng quốc tế
  FeeID_43: 43,
  // 44	Phí thẩm định sửa đổi, chuyển nhượng, gia hạn, mở rộng lãnh thổ, hạn chế danh mục sản phẩm, chấm dứt, huỷ bỏ hiệu lực nhãn hiệu đăng ký quốc tế có nguồn gốc Việt Nam
  FeeID_44: 44,
  // 45	Phí riêng đối với đăng ký quốc tế nhãn hiệu theo Nghị định thư Madrid có chỉ định Việt Nam
  FeeID_45: 45,
  // 46	Phí thẩm định đơn cho mỗi nhóm hàng hóa hoặc dịch vụ
  FeeID_46: 46,
  // 47	Phí thẩm định đơn gia hạn cho mỗi nhóm hàng hóa hoặc dịch vụ
  FeeID_47: 47,
  // 48	Phí gia hạn trả lời
  FeeID_48: 48,
  FeeID_49: 49,
  FeeID_50: 50,
  FeeID_51: 51,
  FeeID_52: 52,
  FeeID_53: 53,
  FeeID_54: 54,
  FeeID_55: 55,
  FeeID_56: 56,
  FeeID_57: 57,
  FeeID_58: 58,
  FeeID_59: 59,
  FeeID_60: 60,
  FeeID_61: 61,
  FeeID_62: 62,
  FeeID_63: 63,
}
//Đơn XLQQT
export const LIST_FEE_XLQQT_ID = {
  // Đơn PCT/VN
  // [LIST_FEE_ID.PCT_VN]: LIST_FEE_ID.FeeID_42,
  [LIST_FEE_ID.FeeID_17]: LIST_FEE_ID.FeeID_42,
  //Nhãn hiệu và kiểu dáng công nghiệp
  // [LIST_FEE_ID.A]: LIST_FEE_ID.FeeID_43,
  // [LIST_FEE_ID.B]: LIST_FEE_ID.FeeID_43,
  // [LIST_FEE_ID.H]: LIST_FEE_ID.FeeID_43,
  // [LIST_FEE_ID.P]: LIST_FEE_ID.FeeID_43,
  [LIST_FEE_ID.FeeID_1]: LIST_FEE_ID.FeeID_43,
  [LIST_FEE_ID.FeeID_8]: LIST_FEE_ID.FeeID_43,
  [LIST_FEE_ID.FeeID_14]: LIST_FEE_ID.FeeID_43,
  [LIST_FEE_ID.FeeID_15]: LIST_FEE_ID.FeeID_43,
  //Đơn còn lại
  // [LIST_FEE_ID.AC]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.AL]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.AM]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.AR]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.AS]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.AT]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.BC]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.BM]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.BR]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.BS]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.BT]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.PC]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.PL]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.PM]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.PS]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.PT]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.BL]: LIST_FEE_ID.FeeID_44,
  // [LIST_FEE_ID.PR]: LIST_FEE_ID.FeeID_44,

  [LIST_FEE_ID.FeeID_2]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_3]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_4]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_5]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_6]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_7]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_9]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_10]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_11]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_12]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_13]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_16]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_18]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_19]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_20]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_21]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_22]: LIST_FEE_ID.FeeID_44,
  [LIST_FEE_ID.FeeID_23]: LIST_FEE_ID.FeeID_44,
}

const ListCheckIsTachDonVBBH = [
  "ModalExtendVBBH",
  "ModalRequestModifyKDCN",
  "ModalTranferContact",
  "ModalTrademarkTransferContact",
  "ModalExtendContactSHCN",
  "ModalModifyContent",
  "ModalCancelVBBH",
]

export const pushToListFee = (ListFee, FeeID, Quantity, SortOrder) => {
  if (!!FeeID) ListFee.push({ FeeID, Quantity })
}

export const switchCaseXLQQT = record => {
  console.log("switchCaseXLQQT", record.DossierCateID)
  let ListFee = []
  let FeeID = LIST_FEE_XLQQT_ID?.[record.DossierCateID]
  if (FeeID) ListFee.push({ FeeID, Quantity: 1 })
  return ListFee
}

export const checkIsTachDonVBBH = () => {
  return [{ FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 }]
}

export const getListFee = props => {
  const { ModalType, values } = props
  console.log("getListFee>>", ModalType)

  let checkVBBH = ListCheckIsTachDonVBBH?.includes(ModalType)

  if (checkVBBH && !!values?.IsTachDon) {
    return checkIsTachDonVBBH()
  }

  let ListFee = []
  switch (ModalType) {
    // Cấp số đơn xác lập quyền quốc gia
    // 1.ModalApplicationFees - Đơn đăng ký sáng chế
    // 2.ModalGPHI - Đơn đăng ký giải pháp hữu ích
    case "ModalApplicationFees":
    case "ModalGPHI":
      ListFee = ModalApplicationFeesGetListFee(props)
      break
    //3.ChargeIndustrialDesigns - Đơn đăng ký kiểu dáng công nghiệp
    case "ChargeIndustrialDesigns":
      ListFee = ChargeIndustrialDesignsGetListFee(props)
      break
    //4.ModalTrademarkFees Đơn đăng ký nhãn hiệu
    case "ModalTrademarkFees":
      ListFee = ModalTrademarkFeesGetListFee(props)
      break
    //6.GeographicalIndication - Đơn đăng ký chỉ dẫn đại lý
    case "GeographicalIndication":
      ListFee = GeographicalIndicationGetListFee(props)
      break
    //7.ModalLayoutDesign - Đơn đăng ký thiết kế bố trí
    case "ModalLayoutDesign":
      ListFee = ModalLayoutDesignGetListFee(props)
      break

    //Cấp số đơn liên quan tới đơn
    //ModalChangeFormFee - Tính phí sửa đổi đơn (SĐ)
    case "ModalChangeFormFee":
      ListFee = ModalChangeFormFeeGetListFee(props)
      break
    //ModalObjectionFormFee - Tính phí đơn phản đối (PĐ)
    case "ModalObjectionFormFee":
      ListFee = ModalObjectionFormFeeGetListFee(props)
      break
    // ModalTransferFormFee - Tính phí chuyển giao đơn (CĐ)
    case "ModalTransferFormFee":
      ListFee = ModalTransferFormFeeGetListFee(props)
      break

    //Cấp số đơn dơn liên quan đến văn bằng
    // ModalCancelVBBH - Tính phí huỷ bỏ hiệu lực VBBH (ĐN)
    case "ModalCancelVBBH":
      ListFee = ModalCancelVBBHGetListFee(props)
      break
    //ModalExtendContactSHCN
    //Tính phí gia hạn hợp đồng chuyển quyền sử dụng SHCN (GHLX)
    //Tính phí hợp đồng chuyển quyền sử dụng SHCN (LX)
    //Tính phí chấm dứt hiệu lực VBBH (ĐC)
    //Tính phí chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng SHCN (ĐCLX)
    case "ModalExtendContactSHCN":
      ListFee = ModalExtendContactSHCNGetListFee(props)
      break
    //ModalExtendVBBH - Tính phí gia hạn văn bằng bảo hộ (GH)
    case "ModalExtendVBBH":
      ListFee = ModalExtendVBBHGetListFee(props)
      break
    // ModalMaintenanceRequirement - Tính phí đơn duy trì hiệu lực (DT)
    case "ModalMaintenanceRequirement":
      ListFee = ModalMaintenanceRequirementGetListFee(props)
      break
    //ModalModifyContent - Tính phí sửa đổi nội dung hợp đồng chuyển quyền sử dụng SHCN (SĐLX)
    case "ModalModifyContent":
      ListFee = ModalModifyContentGetListFee(props)
      break
    // ModalRequestModifyKDCN - Tính phí sửa đổi văn bằng bảo hộ (SB)
    case "ModalRequestModifyKDCN":
      ListFee = ModalRequestModifyKDCNGetListFee(props)
      break
    // ModalRequestReissue - Tính phí yêu cầu cấp lại VBBH (RB4)
    case "ModalRequestReissue":
      ListFee = ModalRequestReissueGetListFee(props)
      break
    //ModalTrademarkTransferContact - Tính phí hợp đồng chuyển nhượng (CB4)
    case "ModalTrademarkTransferContact":
      ListFee = ModalTrademarkTransferContactGetListFee(props)
      break
    //ModalTranferContact - Tính phí hợp đồng chuyển nhượng (CB1, CB2, CB3, CB7)
    case "ModalTranferContact":
      ListFee = ModalTranferContactGetListFee(props)
      break
    //Đơn PB, RB trond đơn liên quan đến văn bằng
    case "PB_RB":
      ListFee = PB_RBGetListFee(props)
      break
    //Đơn PB, RB trond đơn liên quan đến văn bằng
    case "RBPB":
      ListFee = RBPBGetListFee(props)
      break

    //Cấp số đơn các đơn khác
    // ModalGNĐB - Tính phí đơn yêu cầu tra cứu (TC)
    case "ModalTC":
      ListFee = ModalTCGetListFee(props)
      break
    // ModalGNĐB - Tính phí ghi nhận đăng bạ (GNĐB)
    case "ModalGNĐB":
      ListFee = ModalGNĐBGetListFee(props)
      break
    // ModalGNGH - Tính phí ghi nhận gia hạn (GNGH)
    case "ModalGNGH":
      ListFee = ModalGNGHGetListFee(props)
      break
    // ModalKTGĐ - Tính phí kiểm tra nghiệp vụ giám định (KTGĐ)
    case "ModalKTGĐ":
      ListFee = ModalKTGĐGetListFee(props)
      break
    // ModalKTNV - Tính phí kiểm tra nghiệp vụ đại diện (KTNV)
    case "ModalKTNV":
      ListFee = ModalKTNVGetListFee(props)
      break
    // ModalPTĐD - Tính phí phúc tra nghiệp vụ đại diện (PTĐD)
    case "ModalPTĐD":
      ListFee = ModalPTĐDGetListFee(props)
      break

    //Phí lệ thí bổ sung
    //FeeBSType = 1
    // 1-xxxx-xxxxx , 2-xxxx-xxxxx (cần truyền SortOrder)
    case "ModalApplicationFeesPBS":
      ListFee = ModalApplicationFeesPBSGetListFee(props)
      break
    // 3-xxxx-xxxxx
    case "ModalIndustrialDesignApplication":
      ListFee = ModalIndustrialDesignApplicationGetListFee(props)
      break
    //ModalTrademarkFees
    // 4-xxxx-xxxxx
    case "ModalTrademarkFees_FeeBSType_1":
      ListFee = ModalTrademarkFees_FeeBSType_1_GetListFee(props)
      break
    // 6-xxxx-xxxxx
    case "ModalGeographicalIndication":
      ListFee = ModalGeographicalIndication_GetListFee(props)
      break
    // 7-xxxx-xxxxx
    case "ModalLayoutDesignFees":
      ListFee = ModalLayoutDesignFees_GetListFee(props)
      break
    //FeeBSType = 2
    //ModalProtectionCertificate : 1-xxxx-xxxxxCVB, 2-xxxx-xxxxxCVB
    case "ModalProtectionCertificate":
      ListFee = ModalProtectionCertificateGetListFee(props)
      break
    //AddFeeChargeIndustrialDesigns: 3-xxxx-xxxxxCVB
    case "AddFeeChargeIndustrialDesigns":
      ListFee = AddFeeChargeIndustrialDesignsGetListFee(props)
      break
    //AddFeeChargeIndustrialDesigns: 4-xxxx-xxxxxCVB
    case "ModalProtectionTrademark":
      ListFee = ModalProtectionTrademarkGetListFee(props)
      break
    // 6-xxxx-xxxxxCVB,7-xxxx-xxxxxCVB
    case "Single_6_7":
      ListFee = Single_6_7GetListFee(props)
      break
    default:
  }
  return ListFee
}

const ModalApplicationFeesGetListFee = props => {
  const { checkbox, values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
  ]
  let checkNumberEdit = !!values?.NumberEdit ? values?.NumberEdit : 1

  if (checkbox?.IsChuyenDoiDon && checkbox?.IsBefoQDHopLe) {
    ListFee = [{ FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 }]
  } else if (checkbox?.IsChuyenDoiDon && !checkbox?.IsBefoQDHopLe) {
  } else if (checkbox?.IsTachDon) {
    if (!!values.NumberItem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values.NumberItem)
    if (!!values.SoTrangTangThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_13, values.SoTrangTangThem)
    if (!!values.SoHinhAnhThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values.SoHinhAnhThem)
    if (values?.SoTrangCongBoTangThem)
      pushToListFee(
        ListFee,
        LIST_FEE_ID?.FeeID_31,
        values?.SoTrangCongBoTangThem,
      )
    if (values?.PhiKhac?.includes(1))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, 1)
    if (checkbox?.IsTĐND) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.NumberItem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_48, values?.NumberItem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_49, values?.SoTrangTangThem)
    }
    if (values?.PhiKhac?.includes(3))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, checkNumberEdit)
    if (values?.PhiKhac?.includes(2))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_2, 1)
  } else {
    if (values?.NumberItem) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values.NumberItem)
    }
    if (values?.SoTrangTangThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_13, values?.SoTrangTangThem)
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
    if (values?.SoHinhAnhThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
    if (values?.SoTrangCongBoTangThem)
      pushToListFee(
        ListFee,
        LIST_FEE_ID?.FeeID_31,
        values?.SoTrangCongBoTangThem,
      )
    if (values?.PhiKhac?.includes(1))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, 1)
    if (checkbox?.IsTĐND) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.NumberItem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_48, values?.NumberItem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_49, values?.SoTrangTangThem)
    }
    if (values?.PhiKhac?.includes(3))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, checkNumberEdit)
    if (values?.PhiKhac?.includes(2))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_2, 1)
  }
  return ListFee
}
const ChargeIndustrialDesignsGetListFee = props => {
  const { checkbox, values } = props
  // Fix cứng phí truyền đi
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
  ]
  if (checkbox?.IsTachDon && values?.KQ_TĐND === 1) {
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
  } else if (checkbox?.IsTachDon && values?.KQ_TĐND === 2) {
    if (!!values?.SoHinhAnhThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
  } else {
    if (values?.NumberItem) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values?.NumberItem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.NumberItem)
    }
    if (values?.SoHinhAnhThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
    if (checkbox?.IsPhanLoai) pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, 1)
  }
  return ListFee
}
const ModalTrademarkFeesGetListFee = props => {
  const { checkbox, values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
  ]
  if (checkbox?.IsTachDon && checkbox?.KQ_TĐND_NH && checkbox?.TachMau) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values?.NumberItem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.NumberItem)
    if (values?.PhiKhac?.includes(1))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, 1)
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
    if (values?.SoTrangTangThem) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_13, +values?.SoTrangTangThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_15, +values?.SoTrangTangThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_28, +values?.SoTrangTangThem)
    }
    if (values?.PhiKhac?.includes(2))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_15, 1)
  } else if (!checkbox?.IsTachDon) {
    if (values?.NumberItem) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values?.NumberItem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.NumberItem)
    }
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
    if (values?.SoTrangTangThem) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_13, +values?.SoTrangTangThem)
      // pushToListFee(ListFee, LIST_FEE_ID?.FeeID_15, values?.SoTrangTangThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_28, +values?.SoTrangTangThem)
    }
    if (values?.PhiKhac?.includes(1))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, 1)
    if (values?.PhiKhac?.includes(2))
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_15, 1)
  } else {
  }
  return ListFee
}
const GeographicalIndicationGetListFee = props => {
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_12, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_27, Quantity: 1 },
  ]
  return ListFee
}
const ModalLayoutDesignGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_12, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_30, Quantity: 1 },
  ]
  if (values?.SoHinhAnhThem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem - 1)
  return ListFee
}
const ModalChangeFormFeeGetListFee = props => {
  const { values } = props
  let ListFee = []
  // if (+values?.SoDonTrcHopLe)
  //   pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, +values?.SoDonTrcHopLe)
  if (+values?.SoDonSauHopLe) {
    // pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, +values?.SoDonSauHopLe)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, +values?.SoDonSauHopLe)
  }
  if (+values?.NumberEdit) {
    pushToListFee(
      ListFee,
      LIST_FEE_ID?.FeeID_17,
      +values?.NumberEdit *
        ((!!values?.SoDonTrcHopLe ? +values?.SoDonTrcHopLe : 0) +
          (!!values?.SoDonSauHopLe ? +values?.SoDonSauHopLe : 0)),
    )
  }
  if (+values?.SoPhuongAnBoSung) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, +values?.SoPhuongAnBoSung)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, +values?.SoPhuongAnBoSung)
  }
  if (+values?.SoHinhAnhThem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, +values?.SoHinhAnhThem)
  if (+values?.SoTrangTangThem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_31, +values?.SoTrangTangThem)
  if (+values?.SoDiem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, +values?.SoDiem)
  return ListFee
}
const ModalObjectionFormFeeGetListFee = props => {
  const { values } = props
  let ListFee = []
  if (+values?.NumberItem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_26, +values?.NumberItem)
  return ListFee
}
const ModalTransferFormFeeGetListFee = props => {
  const { values } = props
  let ListFee = []
  if (+values?.SoDonTrcHopLe)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_18, +values?.SoDonTrcHopLe)
  if (+values?.SoDonSauHopLe) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_18, +values?.SoDonSauHopLe)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, +values?.SoDonSauHopLe)
  }
  if (+values?.NumberItem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, +values?.NumberItem)
  return ListFee
}
const ModalCancelVBBHGetListFee = props => {
  const { values } = props
  //fix cung fee
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_8, Quantity: 1 },
    {
      FeeID: !!values?.HinhThuc ? LIST_FEE_ID?.FeeID_21 : LIST_FEE_ID?.FeeID_62,
      Quantity: 1,
    },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (!!values?.HinhThuc) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_21, values?.SoVBBH - 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, values?.SoVBBH - 1)
  } else {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_62, values?.SoVBBH - 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, values?.SoVBBH - 1)
  }
  return ListFee
}
const ModalExtendContactSHCNGetListFee = props => {
  const { open, values } = props
  let checkDossierCateIDType1 =
    (open.DossierCateID >= 10 && open.DossierCateID <= 14) ||
    open?.isEditFee === 1
  let checkDossierCateIDType2 =
    (open.DossierCateID >= 53 && open.DossierCateID <= 58) ||
    open?.isEditFee === 2
  //fix cung fee
  let ListFee = []
  if (open?.Type === 1) {
    if (checkDossierCateIDType1) {
      ListFee = [
        { FeeID: LIST_FEE_ID?.FeeID_20, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
      ]
    } else {
      ListFee = [
        { FeeID: LIST_FEE_ID?.FeeID_5, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_19, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
      ]
    }
  } else {
    if (checkDossierCateIDType2) {
      ListFee = [
        { FeeID: LIST_FEE_ID?.FeeID_8, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_21, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
      ]
    } else {
      ListFee = [
        { FeeID: LIST_FEE_ID?.FeeID_20, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
      ]
    }
  }
  if (open?.Type === 1 && +values?.SoVBBH) {
    pushToListFee(
      ListFee,
      checkDossierCateIDType1 ? LIST_FEE_ID?.FeeID_20 : LIST_FEE_ID?.FeeID_19,
      +values?.SoVBBH - 1,
    )
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, +values?.SoVBBH - 1)
  }
  if (open?.Type === 2 && +values?.SoVBBH) {
    pushToListFee(
      ListFee,
      checkDossierCateIDType2 ? LIST_FEE_ID?.FeeID_21 : LIST_FEE_ID?.FeeID_20,
      +values?.SoVBBH - 1,
    )
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, +values?.SoVBBH - 1)
  }

  return ListFee
}
const ModalExtendVBBHGetListFee = props => {
  const { values } = props
  let ListFee = [{ FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 }]
  if (values?.SoVBBH) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, +values?.SoVBBH)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, +values?.SoVBBH)
  }
  if (values?.SoNhom) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_6, values?.SoNhom)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_33, values?.SoNhom)
  }
  if (values?.SoThang) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_7, values?.SoThang)
  }

  return ListFee
}
const ModalMaintenanceRequirementGetListFee = props => {
  const { open, values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_20, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (values?.SoYCBH)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_6, values?.SoYCBH)
  if (values?.SoThang)
    pushToListFee(
      ListFee,
      LIST_FEE_ID?.FeeID_7,
      values?.SoThang * values?.SoYCBH,
    )
  if (values?.SoNam) {
    let checkSoThangVBBH = !!values?.SoThangVBBH ? 0 : values?.SoYCBH
    switch (+values?.SoNam) {
      case 1:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_34, checkSoThangVBBH)
        break
      case 2:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_50, checkSoThangVBBH)
        break
      case 3:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_35, checkSoThangVBBH)
        break
      case 4:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_51, checkSoThangVBBH)
        break
      case 5:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_36, checkSoThangVBBH)
        break
      case 6:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_52, checkSoThangVBBH)
        break
      case 7:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_37, checkSoThangVBBH)
        break
      case 8:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_53, checkSoThangVBBH)
        break
      case 9:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_38, checkSoThangVBBH)
        break
      case 10:
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_54, checkSoThangVBBH)
        break
      case 11:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_39, checkSoThangVBBH)
        break
      case 12:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_55, checkSoThangVBBH)
        break
      case 13:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_56, checkSoThangVBBH)
        break
      case 14:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_40, checkSoThangVBBH)
        break
      case 15:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_57, checkSoThangVBBH)
        break
      case 16:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_58, checkSoThangVBBH)
        break
      case 17:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_41, checkSoThangVBBH)
        break
      case 18:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_59, checkSoThangVBBH)
        break
      case 19:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_60, checkSoThangVBBH)
        break
      case 20:
        if (open?.ProfileType === "DT1")
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_61, checkSoThangVBBH)
        break
      default:
    }
    if (values?.SoThangVBBH)
      pushToListFee(
        ListFee,
        LIST_FEE_ID?.FeeID_63,
        values?.SoThangVBBH * values?.SoYCBH,
      )
  }
  return ListFee
}
const ModalModifyContentGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_20, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (!!values?.HinhThuc && values?.SoVBBH) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, values?.SoVBBH - 1)
  } else {
    if (values?.SoVBBH) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, values?.SoVBBH - 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, values?.SoVBBH - 1)
    }
  }
  return ListFee
}
const ModalRequestModifyKDCNGetListFee = props => {
  const { values, open } = props
  let ListFee = []
  if (!!values?.HinhThuc) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)
    if (values?.SoVBBH) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, values?.SoVBBH - 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, values?.SoVBBH - 1)
    }
  } else {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)
    if (open?.DossierCateID === 46 || open?.DossierCateID === 47) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_48, 1)
      if (values?.SoVBBH) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, values?.SoVBBH - 1)
      }
      if (values?.SoHinhAnhThem) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
      }
      if (values?.SoYCBH) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_48, values?.SoYCBH)
      }
    } else {
      if (values?.SoVBBH) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, values?.SoVBBH - 1)
      }
      if (values?.SoHinhAnhThem) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
      }
      if (values?.SoYCBH) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_48, values?.SoYCBH)
      }
    }
  }

  return ListFee
}
const ModalRequestReissueGetListFee = props => {
  const { values } = props
  let ListFee = []
  if (values?.HinhThuc) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)
  }
  return ListFee
}
const ModalTrademarkTransferContactGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_19, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_27, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (values?.SoVBBH) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_19, values?.SoVBBH - 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.SoVBBH - 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, values?.SoVBBH - 1)
  }
  if (values?.SoNhom) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values?.SoNhom)
  }

  return ListFee
}
const ModalTranferContactGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_19, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (+values?.SoVBBH) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_19, +values?.SoVBBH - 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, +values?.SoVBBH - 1)
  }

  return ListFee
}
const ModalTCGetListFee = props => {
  const { values } = props
  let ListFee = []
  if (values?.SoYCBH) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.SoYCBH)
  }
  if (values?.SoTrangTangThem) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_28, values?.SoTrangTangThem)
  }

  return ListFee
}
const ModalGNĐBGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_20, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (values?.SoVanBang_Mon) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, values?.SoVanBang_Mon - 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, values?.SoVanBang_Mon - 1)
  }

  return ListFee
}
const ModalGNGHGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_20, Quantity: values?.SoVanBang_Mon },
  ]
  return ListFee
}
const ModalKTGĐGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_24, Quantity: values?.SoVanBang_Mon },
  ]
  return ListFee
}
const ModalKTNVGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_24, Quantity: values?.SoVanBang_Mon },
  ]
  return ListFee
}
const ModalPTĐDGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_24, Quantity: values?.SoVanBang_Mon },
  ]
  return ListFee
}
const PB_RBGetListFee = props => {
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  return ListFee
}
const RBPBGetListFee = props => {
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  return ListFee
}

const ModalApplicationFeesPBSGetListFee = props => {
  const { values, checkbox } = props
  let ListFee = []
  if (checkbox?.IsSuaDoi)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, values?.NDYCSuaDoi)
  if (checkbox?.IsGiaHan) pushToListFee(ListFee, LIST_FEE_ID?.FeeID_2, 1)
  if (values?.PhanLoai)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, values?.PhanLoai)
  if (checkbox?.IsTĐND) {
    if (values?.DiemBaoHo_TĐND) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.DiemBaoHo_TĐND)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_48, values?.DiemBaoHo_TĐND)
    }
    if (values?.SoTrangTangThem_TĐND)
      pushToListFee(
        ListFee,
        LIST_FEE_ID?.FeeID_49,
        values?.SoTrangTangThem_TĐND,
      )
  }
  //Bổ sung
  if (checkbox?.IsBoSung) {
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
    if (values?.SoHinhAnhThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
    if (values?.IsYcTĐHT) {
      if (values?.DiemBaoHo)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values?.DiemBaoHo)
      if (values?.SoTrangTangThem)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_13, values?.SoTrangTangThem)
    }
    if (values?.IsYcTĐND) {
      if (values?.DiemBaoHo) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.DiemBaoHo)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_48, values?.DiemBaoHo)
      }
      if (values?.SoTrangTangThem)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_49, values?.SoTrangTangThem)
    }
  }
  return ListFee
}
const ModalIndustrialDesignApplicationGetListFee = props => {
  const { values, checkbox } = props
  let ListFee = []
  if (checkbox?.IsSuaDoi) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, values?.NDYCSuaDoi)
  }
  if (checkbox?.IsGiaHan) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_2, 1)
  }
  if (values?.PhanLoai) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, values?.PhanLoai)
  }
  if (checkbox?.IsBoSung) {
    if (values?.DiemBaoHo) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.DiemBaoHo)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values?.DiemBaoHo)
    }
    if (values?.SoHinhAnhThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
  }
  return ListFee
}

const ModalTrademarkFees_FeeBSType_1_GetListFee = props => {
  const { values, checkbox } = props
  let ListFee = []

  if (checkbox?.IsSuaDoi)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, values?.NDYCSuaDoi)
  if (checkbox?.IsGiaHan) pushToListFee(ListFee, LIST_FEE_ID?.FeeID_2, 1)
  if (values?.PhanLoai)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, values?.PhanLoai)
  if (checkbox?.IsBoSung) {
    if (values?.DiemBaoHo) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, values?.DiemBaoHo)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, values?.DiemBaoHo)
    }
    if (values?.SoUuTien)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, values?.SoUuTien)
    if (values?.SoTrangTangThem) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_28, values?.SoTrangTangThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_13, values?.SoTrangTangThem)
    }
    if (values?.PhanLoaiQT) {
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_15, values?.PhanLoaiQT)
    }
    //thiếu Số phân loại quốc tế về hàng hoá/dịch
    // vụ đối với mỗi nhóm có trên
    // 6 sản phẩm/dịch vụ
  }
  return ListFee
}
const ModalGeographicalIndication_GetListFee = props => {
  const { checkbox, values } = props
  let ListFee = []

  if (checkbox?.IsSuaDoi)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, values?.NDYCSuaDoi)
  if (checkbox?.IsGiaHan) pushToListFee(ListFee, LIST_FEE_ID?.FeeID_2, 1)
  return ListFee
}
const ModalLayoutDesignFees_GetListFee = props => {
  const { values, checkbox } = props
  let ListFee = []
  if (checkbox?.IsSuaDoi)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, values?.NDYCSuaDoi)
  if (checkbox?.IsGiaHan) pushToListFee(ListFee, LIST_FEE_ID?.FeeID_2, 1)
  if (checkbox?.IsBoSung)
    if (values?.SoHinhAnhThem)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)

  return ListFee
}

const ModalProtectionCertificateGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_3, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (values?.SoYCBH) {
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_4, values?.SoYCBH - 1)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_6, values?.SoYCBH)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_34, values?.SoYCBH)
  }
  if (values?.SoHinhAnhThem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
  if (values?.SoTrangTangThem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_31, values?.SoTrangTangThem)
  return ListFee
}
const AddFeeChargeIndustrialDesignsGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_3, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (values?.SoNhomSPDV)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_4, values?.SoNhomSPDV - 1)
  if (values?.SoHinhAnhThem)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, values?.SoHinhAnhThem)
  return ListFee
}
const ModalProtectionTrademarkGetListFee = props => {
  const { values } = props
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_3, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  if (values?.SoNhomSPDV)
    pushToListFee(ListFee, LIST_FEE_ID?.FeeID_4, values?.SoNhomSPDV - 1)
  return ListFee
}
const Single_6_7GetListFee = props => {
  let ListFee = [
    { FeeID: LIST_FEE_ID?.FeeID_3, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
    { FeeID: LIST_FEE_ID?.FeeID_32, Quantity: 1 },
  ]
  return ListFee
}
