import { Form, InputNumber } from "antd"

const { default: styled } = require("styled-components")

export const PatentRegistrationChildBorder = styled.div`
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`
export const StylesTabPattern = styled.div`
  .go-code {
    position: absolute;
    top: 15px;
    right: 10px;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
    position: sticky;
    top: ${props => (!!props.isEdit ? "-12px" : 0)};
    background-color: #fff;
    z-index: 100;
  }
  .ant-tabs-nav {
    :before {
      border-bottom: 1px solid #ccc;
    }
    ::after {
      content: "";
      width: 100%;
      height: 12px;
      position: absolute;
      top: -12px;
      background-color: #fff;
    }
  }
  .ant-tabs-tab {
    border: 1px solid #ccc !important;
  }
  .ant-tabs-tab-active {
    border-bottom: none !important;
  }
`

export const ModalDeclarationAuthorOrtherStyle = styled.div`
  .declaration-shadow-box {
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); */
    border-radius: 8px;
    border: 1px solid #ccc;
  }
`
export const ModalDeclarationOwnerOrtherStyle = styled.div`
  .declaration-shadow-box {
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); */
    border-radius: 8px;
    border: 1px solid #ccc;
  }
`
export const DeclareStyle = styled.div`
  background: #fff;
  .ant-input-affix-wrapper-status-error,
  .ant-picker-status-error {
    background: #ffc10754 !important;
    .ant-input {
      background: transparent !important;
    }
  }
  .required-input-color {
    .ant-input {
      background: #ffc10754 !important;
    }
    .ant-select {
      .ant-select-selector {
        background: #ffc10754 !important;
      }
    }
    .ant-input:not(.ant-input-borderless).ant-input {
      background: #ffc10754 !important;
    }
    .ant-picker {
      background: #ffc10754 !important;
    }
    .fl-label {
      background: transparent !important;
    }
  }
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(
      .ant-input-number-borderless
    ).ant-input-number {
    background: #ffc10754 !important;
  }
  .ant-select-status-error {
    .ant-select-selector {
      background: #ffc10754 !important;
    }
  }
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    background: #ffc10754 !important;
  }
  .fl-label {
    background: transparent !important;
  }
  .doc-box {
    .ant-upload-wrapper
      .ant-upload-list
      .ant-upload-list-item
      .ant-upload-list-item-name {
      width: 140px;
      white-space: none;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .ant-table-cell-with-append {
    }
  }
  .input-border-bottom-only {
    .ant-select-selector {
      padding-left: 0px;
    }
  }
  .declaration-shadow-box {
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); */
    /* border-radius: 8px; */
    border: 1px solid #ccc;
  }
  .btn-form-list {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-pb-0 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 2px 0px;
    input {
      padding: 0px 0px;
    }
  }
  .number-box {
    border: 2px solid #000;
    border-radius: 100%;
    padding: 0px 8px;
  }
  .number-box2 {
    border: 2px solid #000;
    border-radius: 100%;
    padding: 0px 5px;
  }
  .subtitle {
    position: absolute;
    top: -4px;
    right: -14px;
  }
  .title-box {
    font-size: 18px;
  }
  .w-100-percent {
    width: 100%;
  }

  .input-bottom {
    margin: 0px 0px 2px 0px;
    input {
      padding: 0px 0px;
    }
  }

  .list-btn-fix_top {
    position: fixed;
    right: 20px;
    top: 58px;
    z-index: 100;
  }
  .list-btn-fix_top2 {
    position: fixed;
    right: 7vw;
    top: 9vh;
    z-index: 100;
  }
`

export const FormItemErrorStyled = styled(Form.Item)` 
.ant-form-item {
  margin: 0 !important;
}
.ant-form-item-control-input {
  min-height: 0;
  margin-top: 5px;
}
`

export const StyledInputNumber = styled(InputNumber)`
  width: 30%;
  border: none;
  border-bottom: 1px solid #000; /* Màu và kích thước viền dưới */
  border-radius: 0 !important; /* Nếu bạn muốn loại bỏ đường viền cong */
`
